import SensorApi from '@/api/SensorApi';
import {
  FieldValueGraphAttributeValue,
  HumidityBatteryGraphValues,
  SiteData, SiteRawDataMesure, SiteUpperGraphValues, VoltageAxisGraphValues
} from '@/api/SensorModels';
import { Dayjs } from 'dayjs';
import { sum } from 'lodash';
import {
  Action, Module, Mutation, VuexModule
} from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

@Module
export default class SensorDataModule extends VuexModule {
  /**
   * Data of sensors.
   *
   * @private
   * @type {SiteData}
   * @memberof SensorDataModule
   */
  private sensorDatas: SiteData|null = null;

  /**
   * Set sensors datas.
   *
   * @private
   * @param {SiteData|null} sensorDatas
   * @memberof SensorDataModule
   */
  @Mutation
  private setSensorDatas(sensorDatas: SiteData|null) {
    this.sensorDatas = sensorDatas;
  }

  /**
   * Set sensor data for a specific sensor.
   *
   * @private
   * @param {{sensorId: string; siteDataMesure: SiteRawDataMesure}} params
   * @memberof SensorDataModule
   */
  @Mutation
  private setSensorDatasById(params: {sensorId: string; siteDataMesure: SiteRawDataMesure}) {
    if (this.sensorDatas !== null) {
      const index = this.sensorDatas.raw_datas.findIndex((data) => data.id === params.sensorId);

      if (index !== undefined && index >= 0) {
        this.sensorDatas.raw_datas[index] = params.siteDataMesure;
        this.sensorDatas.raw_datas[index].is_full = true;
      }
    }
  }

  /**
   * Reteive sensor datas from the API (light version).
   *
   * @param {SessionModule} session
   * @returns {(Promise<SiteData|null>)}
   * @memberof SensorDataModule
   */
  @Action
  public async getSensorDataLight(session: SessionModule): Promise<SiteData|null> {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const sensorDatas = await SensorApi.getInstance().getSensorsDataLight(token);

    if (sensorDatas !== null) {
      this.setSensorDatas(sensorDatas);
    }

    return sensorDatas;
  }

  /**
   * Get all sensor datas for a specific sensor.
   *
   * @param {SessionModule} session
   * @param {string} sensorId
   * @returns
   * @memberof SensorDataModule
   */
  public async getSensorData(session: SessionModule, sensorId: string) {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const sensorDatas = await SensorApi.getInstance().getSensorDataFull(token, sensorId);

    if (sensorDatas !== null && sensorDatas.raw_datas !== null && sensorDatas.raw_datas.length > 0) {
      this.setSensorDatasById({
        sensorId,
        siteDataMesure: sensorDatas.raw_datas[0],
      });

      return sensorDatas.raw_datas[0];
    }

    return sensorDatas;
  }

  /**
   * Clear sensor datas.
   *
   * @memberof SensorDataModule
   */
  @Action
  public async clearSensorData() {
    this.setSensorDatas(null);
  }

  /**
   * Get sensor data of a sensor.
   *
   * @readonly
   * @memberof SensorDataModule
   */
  public get sensorDataById(): (id: string) => SiteRawDataMesure|null {
    return (id: string) => {
      if (this.sensorDatas === null) {
        return null;
      }

      const filtered = this.sensorDatas.raw_datas.filter((value) => value.id === id);

      if (filtered.length <= 0) {
        return null;
      }

      return filtered[0];
    };
  }

  /**
   * Get sensor data of upper graph between to dates.
   *
   * @readonly
   * @memberof SensorDataModule
   */
  public get sensorDataUpperGraphOfDateRange(): (id: string, d1: Dayjs, d2: Dayjs) => SiteUpperGraphValues[]|null {
    return (id: string, d1: Dayjs, d2: Dayjs) => {
      const sensorData = this.sensorDataById(id);

      if (sensorData === null) {
        return null;
      }

      return sensorData.upper_graph.values.filter((data: SiteUpperGraphValues) => data.date.isAfter(d1) && data.date.isBefore(d2));
    };
  }

  /**
   * Get sensor data of field_value_graph_attribute graph between dates.
   *
   * @readonly
   * @memberof SensorDataModule
   */
  public get sensorDataFieldValueAttributeOfDateRange(): (id: string, d1: Dayjs, d2: Dayjs) => FieldValueGraphAttributeValue[]|null {
    return (id: string, d1: Dayjs, d2: Dayjs) => {
      const sensorData = this.sensorDataById(id);

      if (sensorData === null || !sensorData.field_value_graph_attribute) {
        return null;
      }

      return sensorData.field_value_graph_attribute.values.filter(
        (data: FieldValueGraphAttributeValue) => data.date.isAfter(d1) && data.date.isBefore(d2)
      );
    };
  }

  /**
   * Get sensor data of temp_humidity_battery_graph graph between dates.
   *
   * @readonly
   * @memberof SensorDataModule
   */
  public get sensorDataTempHumidityOfDateRange(): (id: string, d1: Dayjs, d2: Dayjs) => HumidityBatteryGraphValues[]|null {
    return (id: string, d1: Dayjs, d2: Dayjs) => {
      const sensorData = this.sensorDataById(id);

      if (sensorData === null || !sensorData.temp_humidity_battery_graph) {
        return null;
      }

      return sensorData.temp_humidity_battery_graph.values.filter(
        (data: HumidityBatteryGraphValues) => data.date.isAfter(d1) && data.date.isBefore(d2)
      );
    };
  }

  /**
   * Get sensor data of voltage_axis graph between dates.
   *
   * @readonly
   * @memberof SensorDataModule
   */
  public get sensorDataVoltageAxisOfDateRange(): (id: string, d1: Dayjs, d2: Dayjs) => VoltageAxisGraphValues[]|null {
    return (id: string, d1: Dayjs, d2: Dayjs) => {
      const sensorData = this.sensorDataById(id);

      if (sensorData === null || !sensorData.voltage_axis_graph) {
        return null;
      }

      return sensorData.voltage_axis_graph.values.filter(
        (data: VoltageAxisGraphValues) => data.date.isAfter(d1) && data.date.isBefore(d2)
      );
    };
  }

  /**
   * Compute extremums of a sensor data.
   *
   * @readonly
   * @memberof SensorDataModule
   */
  public get computeExtremumsOfDateRange(): (id: string, d1: Dayjs, d2: Dayjs) => {max: number; min: number; avg: number}|null {
    return (id: string, d1: Dayjs, d2: Dayjs) => {
      const datas = this.sensorDataUpperGraphOfDateRange(id, d1, d2);

      if (datas !== null && datas.length > 0) {
        const values = datas.map((data) => data.value).filter((value) => value !== null);

        return {
          max: Math.max(...values),
          min: Math.min(...values),
          avg: sum(values) / values.length,
        };
      }

      return null;
    };
  }
}
