export default class Request {
  constructor() {
    this.baseUrl = window.location.origin + window.location.pathname;
    this.state = this.parseGet(window.location.search);
    history.defaultTitle = document.title;
  }

  /**
   * Parse le GET d'une url
   *
   * @param {String} search String du GET
   *
   * @return {Object} Paramètres parsé
   */
  parseGet(search) {
    let Query = {};
    // Supprime le ? de l'url
    search = search.replace('?', '');
    // Si la chaine n'est pas vide
    if (search !== '') {
      // Regex de parsing sur le modèle "$instance[$params]=$value" ou "$params=$value"
      let regex = new RegExp(/(?:^|&)([^&=[\]]*)(?:\[([^&=]+)])*=?([^&[\]]*)/, 'g');

      let find;
      // Pour chaque occurence correspondant au Regex
      while ((find = regex.exec(search)) !== null) {
        // Déstructure le find
        let [, instance, params, value] = find;

        if (!isNaN(value)) {
          value = Number(value);
        }

        // Si params n'existe pas c'est qu'il n'y à pas d'id d'instance on stock donc comme un simple paramètre
        if (typeof params === 'undefined') {
          Query[instance] = value;
        } else {
          Query[instance] = Object.assign(Query[instance] || {}, { [params]: value });
        }
      }
    }
    // Retourne les paramètres parsé
    return Query;
  }

  /**
   * Construit une URL à partir d'un objet
   *
   * @param {Object} query
   *
   * @return {string}
   */
  buildUrl(query) {
    let request = [];
    // Pour chaque instance
    for (let [instance, params] of Object.entries(query)) {
      if (typeof params === 'object') {
        params = Object.entries(params)
          .filter((key) => {
            // @TODO LEH FILTER
            return ['u'].indexOf(key[0]) === -1;
          })
          .map(([key, value]) => {
            value = encodeURIComponent(value);
            return `${instance}[${key}]=${value}`;
          });
        request.push(...params);
      } else {
        request.push(`${instance}=${params}`);
      }
    }

    delete request.geo;
    delete request.viewMod;

    if (request.length) {
      return '?' + request.join('&');
    }
    return '';
  }

  /**
   * Fusionne l'état le nouvel état à celui actuel
   * Supprime les paramètres de l'url si ils n'existe plus.
   *
   * @private
   * @param {Object} newState
   * @return {Object}
   */
  _mergeState(newState) {
    // Fusionne l'état courant et le nouveau
    let state = Object.assign({}, this.state, newState);

    Object.keys(state).forEach((params) => {
      if (typeof state[params] === 'object' && !newState.hasOwnProperty(params)) {
        delete state[params];
      }
    });

    return state;
  }

  pushState(historyState, title = '', url = null) {
    // Fusionne le nouvel état à celui de l'url
    // this.state = this._mergeState(historyState.request);
    // if (url === null) {
    //   url = this.baseUrl + this.buildUrl(this.state);
    // }
    // document.title = title;
    // // Met à jour l'URL
    // history.pushState(historyState, title, url);
  }

  replaceState(historyState, title = '', url = null) {
    // // Fusionne le nouvel état à celui de l'url
    // this.state = this._mergeState(historyState.request);
    // if (url === null) {
    //   url = this.baseUrl + this.buildUrl(this.state);
    // }
    // document.title = title;
    // // Met à jour l'URL
    // history.replaceState(historyState, title, url);
  }

  static install(Vue) {
    let Request = new this();
    Vue.prototype.$Request = Request;

    // Définit l'url de base de l'instance
    Vue.prototype.$baseUrl = Request.baseUrl;
  }
}
