import SensorApi from '@/api/SensorApi';
import { Building, Geolocation } from '@/api/SensorModels';
import {
  Action, Module, Mutation, VuexModule
} from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

@Module
export default class BuildingsModule extends VuexModule {
  /**
   * List of all buildings.
   */
  private buildings: Building[] = [];

  /**
   * Set buildings.
   */
  @Mutation
  private setBuildings(buildings: Building[]) {
    this.buildings = buildings;
  }

  /**
   * Get buildings.
   * @param params
   * @returns
   */
  @Action
  public async getBuildings({ geo, radius, session }: {session: SessionModule; geo: Geolocation; radius: number}) {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const buildings = await SensorApi.getInstance().getBuildings(token, geo, radius);

    if (buildings !== null) {
      this.setBuildings(buildings);
    }

    return buildings;
  }

  /**
   * Clear list of buildings.
   */
  @Action
  public clearBuildings() {
    this.setBuildings([]);
  }

  /**
   * Get all buildings.
   */
  public get allBuildings() {
    return this.buildings;
  }
}
