import { TooltipFormatterCallbackFunction } from 'highcharts';

export default function (title: string, ordinate: string, unit: string) {
  return {
    title: {
      text: title,
      style: {
        fontFamily: 'Open Sans, sans-serif',
        color: '#A0A0A0',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    },
    subtitle: false,
    exporting: {
      enabled: false,
    },
    chart: {
      type: 'column',
      borderWidth: 0,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        turboThreshold: 0,
      },
    },
    colors: ['#8baab1'],
    xAxis: {
      title: {
        text: false,
      },
      labels: {
        y: 30,
        style: {
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
    yAxis: {
      title: {
        text: ordinate,
        style: {
          fontFamily: 'Open Sans, sans-serif',
          color: '#A0A0A0',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textTransform: 'uppercase',
        },
      },
      x: -10,
      labels: {
        style: {
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
    credits: {
      enabled: false,
    },
    lang: {},
    tooltip: {
      backgroundColor: '#ffffff',
      borderWidth: 0,
      borderRadius: 10,
      useHTML: true,
      headerFormat: '',
      // eslint-disable-next-line func-names
      formatter: function () {
        return `
        <div class="flex flex-col justify-center items-center">
          <p class="text-gray-lighter-text">
            <strong>${this.key}</strong>
          </p>
          <p class="font-light text-lg">${this.y} ${unit}</p>
        </div>
      `;
      } as TooltipFormatterCallbackFunction,
      style: {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
    legend: {
      enabled: false,
      borderColor: '#004d40',
      backgroundColor: '#ffffff',
    },
  };
}
