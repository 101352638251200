export default function getClusterPinAntenna(nbElements: number) {
  return `
    <div style="width: 20px; height: 20px;" class="relative">
      <svg style="filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, .25))" class="w-full h-full" width="80" height="100" viewBox="0 0 80 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H75C77.7614 0 80 2.23858 80 5V75C80 77.7614 77.7614 80 75 80H5C2.23858 80 0 77.7614 0 75V5Z" fill="#595A5B"/>
      </svg>
      <p class="absolute font-bold inset-0 text-white text-center mt-px" style="font-size: .8em; font-family: Open Sans, sans-serif;">${nbElements}</p>
    </div>
  `;
}
