/**
 * Test si la variable passé est vide
 *
 * @param {*} variable Variable à tester
 * @param {Boolean} preserveValue Si true les valeurs "false" et "NaN" ne sont pas considéré comme vide
 * @return {Boolean}
 */
export default function isEmpty (variable, preserveValue = false) {
  // Si c'est un tableau ou un chaine de caractère
  if (variable instanceof Array || (typeof variable === 'string')) {
    // Si le nombre d'entrée ou de caractères est 0 renvoie true
    return !variable.length;
  }

  // Si undefined ou null
  if (typeof variable === 'undefined' || variable === null) {
    return true;
  }

  // Si c'est un objet
  if (typeof variable === 'object') {
    // Si le nombre d'entrée est 0 renvoie true
    return !Object.entries(variable).length;
  }

  if (typeof variable === 'boolean') {
    return preserveValue ? false : !variable;
  }

  if (typeof variable === 'number') {
    return preserveValue ? false : Number.isNaN(variable) ? true : !variable;
  }

  if (typeof variable === 'function') {
    return true;
  }

  console.error('Le type ' + typeof variable + ' n\'est pas connu');
  return true;
}
