<template>
  <div class="datepicker-trigger">
    <div class="title-wrapper lgrid-noGutter-noWrap-spaceBetween">
      <div>
        <button class="title" @click="close">
          <span v-html="placeholder" />
        </button>
      </div>
      <slot name="header" />
    </div>
    <v-date-picker
      ref="datePicker"
      v-model="date"
      :locale="lang"
      is-inline
      v-bind="options"
      :disablePageSwipe="true"
      :is-expanded="true"
      :mode="mode"
      :columns="$screens({ default: 1, md: 2 })"
      :rows="$screens({ default: 12, md: 1 })"
      @dayclick="dayClick"
    />
    <slot />
    <div class="action-buttons">
      <button class="btn" @click="resetDisplay">{{ t.calendar.cancel || 'Réinitialiser' }}</button>
      <button class="btn colored" @click="apply">{{ t.calendar.apply || 'Appliquer' }}</button>
    </div>
    <date-picker />
  </div>
</template>

<script>
  import { addDays, differenceInDays } from 'date-fns';
  import isset from '@Diffusio/js/utils/isset';
  // Fichier de theme custom de v-calendar

  export default {
    name: 'DatePicker',
    props: {
      value: {
        type: [Date, Object],
        default: null,
      },
      placeholder: {
        type: String,
        default: '',
      },
      mode: {
        type: String,
        default: 'range',
      },
      forceRange: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        date: null,
        countDayClick: 0,
      };
    },
    watch: {
      value(val) {
        this.date = val;
      },
      date() {
        this.$emit('input', this.date);
      },
    },
    created() {
      this.date = this.value;
    },
    methods: {
      /**
       * Réinitialise le filtre
       */
      resetDisplay() {
        this.date = null;
        this.$emit('input', this.date);
      },
      /**
       * Envoie l'indication de fermer le filtre
       */
      close() {
        this.$emit('close');
      },
      /**
       * Applique la sélection
       */
      apply() {
        // Si date n'est pas vide, qu'on est en mode range et que date.end n'existe pas
        if (
          this.date !== null &&
          this.mode === 'range' &&
          !isset(this.date.end)
        ) {
          // Si forceRange est activé
          if (this.forceRange) {
            // Force la date de fin le lendemain du jour choisi
            this.date = this.handleForceRange({ ...this.date });
          } else {
            // Sinon la date de fin est égale à la date de début
            this.date = { ...this.date, end: this.date.start };
          }
          // reset le compteur de DayClick, car on a forcé le end
          this.countDayClick = 0;
        }
        // Attend un tick pour le forceRange
        this.$nextTick(() => {
          this.$emit('apply');
        });
      },
      /**
       * Force à avoir au moins un jour d'écart entre la date de début et de fin
       *
       * @param {Object} Range de date
       * @return {Object}
       */
      handleForceRange(date) {
        // Si end n'existe pas ou est égale à start
        if (!isset(date.end) || differenceInDays(date.end, date.start) === 0) {
          date.end = addDays(date.start, 1);
        }
        return date;
      },
      /**
       * Activé au click sur un jour du calendrier
       * @param day
       */
      dayClick(day) {
        // S'applique uniquement au mode range
        if (this.mode === 'range') {
          ++this.countDayClick;
          if (this.countDayClick < 2) {
            this.date = { start: day.date };
          } else {
            // Si forceRange est activé
            if (this.forceRange) {
              // Force l'ordre de début et fin
              const orderedDate = Object.values({
                ...this.date,
                end: day.date,
              }).sort((a, b) => a - b);
              // Force l'écart d'au moins un jour entre les dates
              this.date = this.handleForceRange({
                start: orderedDate[0],
                end: orderedDate[1],
              });
            }
            this.countDayClick = 0;
          }
        }
      },
    },
  };
</script>
