<template>
  <div class="col-span-2">
    <div class="flex h-full">
      <div class="relative flex-grow-0">
        <a
          href="#"
          @click.prevent="toggleDropdown"
          :data-active="popupOpen.toString()"
          class="flex h-full items-center justify-center relative flex-grow-0"
        >
          <span class="mr-3">{{ name }}</span>
          <Icon :width="8" :height="8" name="chevron-down" />
        </a>
        <div
          v-show="popupOpen"
          class="bg-white z-1005 min-w-max shadow rounded-lg absolute left-0 transform origin-top-left top-full animate-zoom max-h-64 overflow-y-auto"
        >
          <ul>
            <li class="facetFilterCheckbox">
              <label
                for="allSelected"
                class="mx-5 my-3 text-sm text-gray-light-text flex items-center container-checkbox">
                <input type="checkbox" id="allSelected" v-model="allSelected" />
                <span class="square-checkbox mr-4">
                  <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
                </span>
                <span class="text-left font-bold">{{ selectAllLabel }}</span>
              </label>
            </li>
            <li
              v-for="({ name: optionName, classes }, key) in facets"
              :key="key"
              class="facetFilterCheckbox"
              :class="classes"
            >
              <label
                :for="instanceId+'_'+key"
                class="mx-5 my-3 text-sm text-gray-light-text flex items-center container-checkbox"
              >
                <input :id="instanceId+'_'+key" v-model="selected" type="checkbox" :value="key" />
                <span class="square-checkbox mr-4">
                  <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
                </span>
                <span class="text-left">{{ optionName }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Helpers/Icon';
import baseEngines from '@Diffusio/js/components/engines/baseEngines';

  export default {
    name: 'FacetFilterCheckbox',
    components: { Icon },
    mixins: [baseEngines],
    props: {
      conf: {
        type: Object,
        default: () => {},
      },
      facets: {
        type: Object,
        default: () => {},
      },
      name: {
        type: String,
        default: '',
      },
      requestValue: {
        type: Array,
        default: () => [],
      },
      moreFilter: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      allSelected(value) {
        if (value) {
          this.selectAll();
        } else {
          this.unselectAll();
        }
      }
    },
    data() {
      return {
        popupOpen: false,
        allSelected: false
      };
    },
    computed: {
      nbChecked() {
        return this.selected.length;
      },
      selected: {
        get() {
          const value = this.filterData || '';
          return value.length ? value.split('~') : [];
        },
        set(filterIds) {
          this.setData(filterIds);
          this.setContext(filterIds);
        },
      },
      selectAllLabel() {
        return this.allSelected
          ? this.$t('diffusio.components.FacetFilterCheckbox.unselect_all')
          : this.$t('diffusio.components.FacetFilterCheckbox.select_all');
      }
    },
    mounted() {
      document.addEventListener('click', this.close);
    },
    created() {
      // If requestValue is not empty
      if (this.requestValue !== null) {
        // Restore selection
        this.selected = this.requestValue;
      }
    },
    beforeDestroy() {
      document.removeEventListener('click', this.close);
    },
    methods: {
      setData(filterIds) {
        this.filterData = filterIds.join('~');
      },
      setContext(filterIds) {
        const contextEntries = Object.values(this.facets).filter(
          (facet) => filterIds.indexOf(facet.id) !== -1
        );
        this.updateContext(contextEntries);
      },
      toggleDropdown() {
        this.popupOpen = !this.popupOpen;
      },
      close(e) {
        if (!this.$el.contains(e.target)) {
          this.popupOpen = false;
        }
      },
      selectAll() {
        this.selected = Object.keys(this.facets).map(key => {
          return this.facets[key].id;
        });
      },
      unselectAll() {
        this.selected = [];
      }
    },
  };
</script>
