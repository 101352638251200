import Template from '@Templates/components/Helpers/Dropdown.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from './Icon';

@Template
@Component({
  components: {
    Icon,
  },
})
export default class Dropdown extends Vue {
  @Prop(String)
  private readonly icon: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  private readonly chevron: boolean;

  @Prop({
    type: String,
    default: '',
  })
  private readonly title: string;

  /**
   * If dropdown is visible.
   *
   * @private
   * @memberof Dropdown
   */
  private visible = false;

  /**
   * Show or hide the dropdown.
   *
   * @memberof Dropdown
   */
  private toggleDropdown() {
    this.visible = !this.visible;
  }

  /**
   * Force close dropdown.
   *
   * @param {MouseEvent} e
   * @memberof Dropdown
   */
  private close(e: MouseEvent) {
    if (!this.$el.contains(e.target as Node)) {
      this.visible = false;
    }
  }

  /**
   * Event mounted.
   *
   * @memberof Dropdown
   */
  private mounted() {
    document.addEventListener('click', this.close);
  }

  /**
   * Event before destroy.
   *
   * @memberof Dropdown
   */
  private beforeDestroy() {
    document.removeEventListener('click', this.close);
  }
}
