import { Building } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import { tailwind } from '@/helpers/TailwindConfig';
import { buildingsModule, sessionModule } from '@/store';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/PinFilterBuildings.vhtml';
import PinBuilding from '@Templates/components/Pin/PinBuilding.html';
import PinBuildingMedical from '@Templates/components/Pin/PinBuildingMedical.html';
import PinBuildingSchool from '@Templates/components/Pin/PinBuildingSchool.html';
import L from 'leaflet';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { LMap } from 'vue2-leaflet';

@Template
@Component({
  components: { Icon },
})
export default class PinFilterBuildings extends Vue {
  /**
   * The map to add pins.
   */
  @Prop({ type: Object, required: true })
  private map: LMap;

  /**
   * List of all buildings markers.
   */
  private markersBuildings: L.Marker[] = [];

  /**
   * All buildings geojson.
   */
  private geojsonBuildings: L.GeoJSON[] = [];

  /**
   * If show buildings on the map.
   */
  private showBuildings = false;

  /**
   * If a request buildings is started.
   */
  private requestBuildingsStarted = false;

  /**
   * If loading buildings.
   *
   * @private
   * @memberof DsioPinFilter
   */
  private loadingBuildings = false;

  /**
   * If display buildings.
   */
  private displayBuildings = false;

  /**
   * Current bounds of the map.
   */
  private currentBounds: L.LatLngBounds;

  /**
   * On checkbox "showBuildings" changes.
   */
  @Watch('showBuildings')
  public onShowBuildingsChanged() {
    this.update(this.currentBounds);
  }

  /**
   * Update buildings.
   * @param bounds
   */
  public async update(bounds: L.LatLngBounds) {
    this.currentBounds = bounds;

    if (this.showBuildings) {
      this.loadingBuildings = true;

      if (this.map.mapObject.getZoom() > 15 && !this.requestBuildingsStarted) {
        const center = bounds.getCenter();
        const radius = Math.round(
          bounds.getCenter().distanceTo(bounds.getNorthEast())
        );
        this.displayBuildings = true;

        this.requestBuildingsStarted = true;
        const response = await buildingsModule.getBuildings({
          session: sessionModule,
          geo: {
            latitude: center.lat,
            longitude: center.lng,
          },
          radius,
        });

        if (response !== null) {
          await this.redrawBuildings();
        }

        this.requestBuildingsStarted = false;
      } else {
        this.displayBuildings = false;
        buildingsModule.clearBuildings();
        await this.redrawBuildings();
      }

      this.loadingBuildings = false;
    } else {
      this.markersBuildings.forEach((marker) => {
        marker.removeFrom(this.map.mapObject);
      });

      this.geojsonBuildings.forEach((geojson) => {
        geojson.removeFrom(this.map.mapObject);
      });

      this.markersBuildings = [];
      this.geojsonBuildings = [];
    }
  }

  /**
   * Redraw all buildings on map.
   * @returns
   */
  public async redrawBuildings() {
    return new Promise<void>((resolve) => {
      this.markersBuildings.forEach((marker) => {
        marker.removeFrom(this.map.mapObject);
      });

      this.geojsonBuildings.forEach((geojson) => {
        geojson.removeFrom(this.map.mapObject);
      });

      this.markersBuildings = [];
      this.geojsonBuildings = [];

      this.buildings.forEach((building) => {
        const popup = new L.Popup({
          offset: [0, building.type === 'point' ? -10 : 0],
        }).setContent(building.description);

        if (building.type === 'geojson') {
          console.log(building);
          const geojson = new L.GeoJSON(building.geojson, {
            style: {
              color: building.name === 'temp ep display' ? tailwind.theme.colors['back-gray'] : tailwind.theme.colors['light-blue'],
              weight: 1,
              opacity: 1,
              fillOpacity: 0.2,
              fillColor: building.name === 'temp ep display' ? tailwind.theme.colors.orange : tailwind.theme.colors['light-blue'],
            },
          });

          geojson.bindPopup(popup);

          geojson.on('mouseover', () => {
            geojson.openPopup();
          });

          geojson.on('mouseout', () => {
            geojson.closePopup();
          });

          this.geojsonBuildings.push(geojson);
          geojson.addTo(this.map.mapObject);
        } else if (building.type === 'point' && building.point.length === 2) {
          const marker = new L.Marker([building.point[1], building.point[0]], {
            icon: new L.DivIcon({
              html: this.getIconForBuilding(building),
              iconSize: [30, 30],
              iconAnchor: [15, 15],
            }),
          });

          marker.bindPopup(popup);

          marker.on('mouseover', () => {
            marker.openPopup();
          });

          marker.on('mouseout', () => {
            marker.closePopup();
          });

          this.markersBuildings.push(marker);
          marker.addTo(this.map.mapObject);
        }
      });

      resolve();
    });
  }

  /**
   * Get icon for "point" building.
   * @param building
   * @returns
   */
  public getIconForBuilding(building: Building): string {
    switch (building.building_type) {
      case 'school':
        return PinBuildingSchool;
      case 'medical':
        return PinBuildingMedical;
      default:
        return PinBuilding;
    }
  }

  /**
   * Get all buildings.
   */
  private get buildings() {
    return buildingsModule.allBuildings;
  }
}
