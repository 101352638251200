import pointSvg from '@Assets/img/pointsGraph/chart-point-default.png';
import dayjs from 'dayjs';
import { AxisLabelsFormatterCallbackFunction, TooltipFormatterCallbackFunction } from 'highcharts';

export default function (title: string, ordinate: string, unit: string) {
  return {
    title: {
      text: title,
      style: {
        fontFamily: 'Open Sans, sans-serif',
        color: '#A0A0A0',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    },
    subtitle: false,
    exporting: {
      enabled: false,
    },
    chart: {
      type: 'spline',
      borderWidth: 0,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        marker: {
          symbol: `url(${pointSvg})`,
          height: 10,
        },
        turboThreshold: 0,
      },
    },
    colors: ['#61d3ed'],
    xAxis: {
      title: {
        style: {
          fontFamily: 'Open Sans, sans-serif',
          color: '#A0A0A0',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textTransform: 'uppercase',
        },
        y: 10,
      },
      labels: {
        useHTML: true,
        // eslint-disable-next-line func-names
        formatter: function (): string {
          const dateObj = dayjs(this.value);
          const date = dateObj.format('DD/MM/YYYY');
          const time = dateObj.format('HH:mm');

          return `
          <div class="flex flex-col justify-center items-center">
            <p>${date}</p>
            <p class="text-gray-lighter-text">${time}</p>
          </div>
          `;
        } as AxisLabelsFormatterCallbackFunction,
        y: 30,
        style: {
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
    yAxis: {
      title: {
        text: ordinate,
        style: {
          fontFamily: 'Open Sans, sans-serif',
          color: '#A0A0A0',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textTransform: 'uppercase',
        },
        x: -10,
      },
      min: 0,
      labels: {
        style: {
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
    credits: {
      enabled: false,
    },
    lang: {},
    tooltip: {
      backgroundColor: '#ffffff',
      borderWidth: 0,
      borderRadius: 10,
      useHTML: true,
      // eslint-disable-next-line func-names
      formatter: function () {
        const dateObj = dayjs(this.x);
        const date = dateObj.format('DD/MM/YYYY');
        const time = dateObj.format('HH:mm');

        let result = `
          <div class="flex flex-col justify-center items-center">
            <p class="text-gray-lighter-text">
              <strong>${date}</strong>
              ${time}
            </p>
        `;

        if (this.points && this.points.length > 0) {
          // eslint-disable-next-line no-unused-expressions
          this.points?.forEach((point) => {
            result += `<p style="color: ${point.color};" class="font-semibold text-lg">${point.y} ${unit}</p>`;
          });
        } else {
          result += `<p class="font-light text-lg">${this.y} ${unit}</p>`;
        }

        return `${result}</div>`;
      } as TooltipFormatterCallbackFunction,
      style: {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
    legend: {
      enabled: false,
      borderColor: '#004d40',
      backgroundColor: '#ffffff',
    },
  };
}
