<template>
  <div class="relative">
    <l-map
      ref="DsioMap"
      :bounds="boundsValue"
      :options="mapOptions"
      @update:bounds="debouncedBounds"
      @ready="mapReady"
      class="rounded-md lg:rounded-none"
    >
      <l-tile-layer :url="tileLayer.url" />
      <l-geo-json
        v-for="(layer, key) in layers"
        :key="key"
        :geojson="layer.geoJson"
        :options="layer.options"
        :optionsStyle="layer.style"
      />
      <l-control-attribution
        v-if="tileLayer.attribution && tileLayer.attribution !== ''"
        position="bottomleft"
        :prefix="tileLayer.attribution"
      />
      <v-marker-cluster
        v-if="conf.useClusters"
        :options="conf.markerClusterOptions"
      >
        <l-marker
          v-for="(item, key) in mapItems"
          :key="key"
          :lat-lng="item.marker"
          :icon="
            conf.usePriceMarkers || conf.useDivMarkers
              ? getDivIcon(item)
              : getIcon(key, item)
          "
          @click="
            () => {
              showDetailSensor(key);
            }
          "
        />
      </v-marker-cluster>
      <template v-if="!conf.useClusters">
        <l-marker
          v-for="(item, key) in mapItems"
          :key="key"
          :lat-lng="item.marker"
          :icon="
            conf.usePriceMarkers || conf.useDivMarkers
              ? getDivIcon(item)
              : getIcon(key, item)
          "
        />
      </template>
      <l-marker
        v-if="itemHighlighted && markerHighlited"
        :lat-lng="markerHighlited"
        :options="{ zIndexOffset: 1000 }"
        :icon="
          conf.usePriceMarkers || conf.useDivMarkers
            ? getDivIconHover(mapItems[itemHighlighted])
            : getIconHover(itemHighlighted, mapItems[itemHighlighted])
        "
      />
      <l-marker
        v-if="userPosition"
        :lat-lng="userPosition"
        :icon="iconUser"
        :options="{ interactive: false }"
      />

      <l-control-zoom
        v-if="!isMobileScreen"
        :options="Object.assign({}, conf.zoomControlOption)"
      />
    </l-map>
    <div
      :class="{ open: isSmallScreen && mapPopupContent }"
      class="map-popup-outside-wrapper"
    >
      <div class="map-popup-outside" v-html="mapPopupContent" />
    </div>
  </div>
</template>

<script>
  import pinAddress from '@Assets/img/map/pin-check-adress.svg';
  import baseEngines from '@Diffusio/js/components/engines/baseEngines';
  import toGeoJson from '@mapbox/togeojson';
  import pinSensor from '@Templates/components/Pin/PinSensor.html';
  import pinSensorArchived from '@Templates/components/Pin/PinSensorArchived.html';
  import pinSensorArchivedHover from '@Templates/components/Pin/PinSensorArchivedHover.html';
  import pinSensorHover from '@Templates/components/Pin/PinSensorHover.html';
  import pinSensorMaintenance from '@Templates/components/Pin/PinSensorMaintenance.html';
  import pinSensorMaintenanceHover from '@Templates/components/Pin/PinSensorMaintenanceHover.html';
  import Axios from 'axios';
  import 'leaflet-responsive-popup';
  import 'leaflet.snogylop'; // inverser les layers
  import { debounce } from 'lodash';
  import {
    LControl,
    LControlAttribution,
    LControlZoom,
    LGeoJson,
    LMap,
    LMarker,
    LTileLayer,
  } from 'vue2-leaflet';
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
  // import 'leaflet.fullscreen'; // plein ecran carte
  import { getLocale } from '@/helpers/MiscHelpers';
  import { sensorModule } from '@/store';
  import Events from '@Diffusio/js/classes/Events';

  export default {
    components: {
      LMap,
      LTileLayer,
      LControlAttribution,
      LMarker,
      LGeoJson,
      LControl,
      LControlZoom,
      'v-marker-cluster': Vue2LeafletMarkerCluster,
    },
    mixins: [baseEngines],
    props: {
      mapItems: {
        type: Object,
        default: () => {},
      },
      bounds: {
        type: Array,
        default: () => [
          { lat: 0, lng: 0 },
          { lat: 0, lng: 0 },
        ],
      },
      conf: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        mounted: false,
        mapCentered: false,
        mapPopupContent: null,
        geolocPoint: null,
        icon: new L.DivIcon({
          html: pinSensor,
          iconSize: [40, 48],
          iconAnchor: [20, 48],
        }),
        iconMaintenance: new L.DivIcon({
          html: pinSensorMaintenance,
          iconSize: [40, 48],
          iconAnchor: [20, 48],
        }),
        iconArchived: new L.DivIcon({
          html: pinSensorArchived,
          iconSize: [40, 48],
          iconAnchor: [20, 48],
        }),
        iconHover: new L.DivIcon({
          html: pinSensorHover,
          iconSize: [40, 48],
          iconAnchor: [20, 48],
        }),
        iconMaintenanceHover: new L.DivIcon({
          html: pinSensorMaintenanceHover,
          iconSize: [40, 48],
          iconAnchor: [20, 48],
        }),
        iconArchivedHover: new L.DivIcon({
          html: pinSensorArchivedHover,
          iconSize: [40, 48],
          iconAnchor: [20, 48],
        }),
        iconUser: new L.DivIcon({
          iconSize: [56, 56],
          iconAnchor: [28, 50],
          className: 'userIconWrapper',
          html:
            '<div class="userIcon"><i class="dsio-street-view-light"></i></div>',
        }),
        layers: [],
        currentTileLayer: this.getDefaultTileLayer(),
        tileLayer: this.getTileLayer(this.getDefaultTileLayer()),
        mapOptions: Object.assign(
          {},
          {
            renderer: L.canvas(),
            preferCanvas: true,
            scrollWheelZoom: true,
            zoomControl: false,
          },
          this.conf.mapOptions
        ),
      };
    },
    computed: {
      isSmallScreen() {
        return ['mobile', 'tablet'].indexOf(this.$root.breakpoint) > -1;
      },
      boundsValue: {
        get() {
          if (!this.filterData) {
            return this.conf.bounds;
          }

          const latlng =
            this.filterData.length > 0
              ? this.filterData.split('~')
              : [0, 0, 0, 0];
          return [
            { lat: latlng[0], lng: latlng[1] },
            { lat: latlng[2], lng: latlng[3] },
          ];
        },
        set(latlng) {
          const ne = Object.values(latlng[0]);
          const sw = Object.values(latlng[1]);

          // envoi evt avec bounds
          this.commit('UPDATE_SAVED_MAP_BOUNDS', [ne, sw]);

          this.filterData = [ne.join('~'), sw.join('~')].join('~');
        },
      },
      userPosition() {
        return this.$store.state[this.instanceId].userPosition || null;
      },
      itemHighlighted() {
        return this.$store.state[this.instanceId].itemHighlighted || false;
      },
      markerHighlited() {
        if (!this.mapItems[this.itemHighlighted]) {
          return false;
        }
        return this.mapItems[this.itemHighlighted].marker;
      },
    },
    watch: {
      conf(value) {},
      'app.state.viewMod'() {
        this.refreshMap();
      },
      currentTileLayer(tileLayerId) {
        this.tileLayer = this.getTileLayer(tileLayerId);
      },
      mapItems(value) {},
    },
    created() {
      this.debouncedBounds = debounce(this.boundsUpdated, 500, {
        leading: true,
        trailing: false,
      });

      // hack empêcher le scroll vers le conteneur carte
      // si clic sur les boutons zoom
      if (typeof L !== 'undefined') {
        L.Control.prototype._refocusOnMap = function _refocusOnMap() {};
      }

      if (this.isSmallScreen) {
        this.mapOptions.zoomControl = false;
      }

      if (window.lae && window.lae.map.tileLayer) {
        this.conf.tileLayer = window.lae.map.tileLayer;
      }

      this.boundsValue = this.bounds;
    },
    mounted() {
      // Si des layers sont définits
      if (this.conf.layers.length > 0) {
        this.setLayers();
      }

      this.$root.$on('centerMap', (value) => {
        if (this.geolocPoint !== null && this.$refs.DsioMap !== undefined) {
          this.geolocPoint.removeFrom(this.$refs.DsioMap.mapObject);
          this.geolocPoint = null;
        }

        if (value !== null && this.$refs.DsioMap !== undefined) {
          this.geolocPoint = new L.Marker(
            {
              lat: value.value.lat,
              lng: value.value.lng,
            },
            {
              icon: new L.Icon({
                iconUrl: pinAddress,
                iconSize: [40, 48],
                iconAnchor: [20, 48],
              }),
            }
          );

          this.geolocPoint.addTo(this.$refs.DsioMap.mapObject);
          this.$refs.DsioMap.mapObject.fitBounds(value.value.bounds);
        }
      });

      Events.mapLoaded(this.$refs.DsioMap.mapObject, { ...this.$props });
    },
    methods: {
      mapReady() {
        this.$emit('map-ready');

        this.$refs.DsioMap.mapObject.fitBounds([
          ...sensorModule.sensors.map((s) => [
            s.geolocation.latitude,
            s.geolocation.longitude,
          ]),
        ]);
      },
      getTileLayer(tileLayerId) {
        // plan CDN en priorité si idTile === plan
        if (tileLayerId === 'plan' && window.lae && window.lae.map.tileLayer) {
          return window.lae.map.tileLayer;
        }

        // retourne le tile layer
        const filteredTileLayer = this.conf.tileLayers.filter(
          (tileLayer) => tileLayer.id === tileLayerId
        );
        if (filteredTileLayer.length) {
          return filteredTileLayer[0];
        }

        if (window.lae.map.tileLayer) {
          return window.lae.map.tileLayer;
        } else if (this.conf.tileLayer) {
          return this.conf.tileLayer;
        }
      },
      getDefaultTileLayer() {
        const lang = getLocale();

        return `plan_${lang}`;
      },
      /**
       * Rafraichis la carte
       **/
      refreshMap() {
        this.$refs.DsioMap.mapObject.invalidateSize();
      },
      /**
       * Retourne l'icone prix
       **/
      getDivIcon(item) {
        let className = item.className || '';
        if (this.conf.usePriceMarkers) {
          const price = item.price;
          if (!price) {
            className += ' ' + (this.conf.priceIconEmpty.className || '');
            return new L.DivIcon({
              ...this.conf.priceIconEmpty,
              className: className + '-wrapper price-icon-wrapper',
              html: '<div class="' + className + ' price-icon empty"></div>',
            });
          }

          className += ' ' + (this.conf.priceIcon.className || '');
          return new L.DivIcon({
            ...this.conf.priceIcon,
            className: className + '-wrapper price-icon-wrapper',
            html:
              '<div class="' +
              className +
              ' price-icon"><span class="price-label">' +
              price +
              '€</span><span class="price-anchor-point"></span></div>',
          });
        } else {
          const data = item.markerData;
          if (!data) {
            className += ' ' + (this.conf.divIconEmpty.className || '');
            return new L.DivIcon({
              ...this.conf.divIconEmpty,
              className: className + '-wrapper div-icon-wrapper',
              html: '<div class="' + className + ' div-icon empty"></div>',
            });
          }
          className += ' ' + (this.conf.divIcon.className || '');
          return new L.DivIcon({
            ...this.conf.divIcon,
            className: className + '-wrapper div-icon-wrapper',
            html:
              '<div class="' +
              className +
              ' div-icon"><span class="div-label">' +
              data +
              '</span><span class="div-anchor-point"></span></div>',
          });
        }
      },
      getDivIconHover(itemHighlighted) {
        let className = itemHighlighted.className || '';
        if (this.conf.usePriceMarkers) {
          const price =
            itemHighlighted && itemHighlighted.price
              ? itemHighlighted.price
              : null;

          if (!price) {
            className = ' ' + (this.conf.priceIconEmpty.className || '');
            return new L.DivIcon({
              ...this.conf.priceIconEmpty,
              className: className + '-wrapper price-icon-wrapper',
              html:
                '<div class="' + className + ' price-icon empty hover"></div>',
            });
          }

          className = ' ' + (this.conf.priceIcon.className || '');
          return new L.DivIcon({
            ...this.conf.priceIcon,
            className: className + '-wrapper price-icon-wrapper',
            html:
              '<div class="' +
              className +
              ' price-icon hover"><span class="price-label">' +
              price +
              '€</span><span class="price-anchor-point"></span></div>',
          });
        } else {
          const data = itemHighlighted.markerData;
          if (!data) {
            className = ' ' + (this.conf.divIconEmpty.className || '');
            return new L.DivIcon({
              ...this.conf.divIconEmpty,
              className: className + '-wrapper div-icon-wrapper',
              html:
                '<div class="' + className + ' div-icon empty hover"></div>',
            });
          }

          className = ' ' + (this.conf.divIcon.className || '');
          return new L.DivIcon({
            ...this.conf.divIcon,
            className: className + '-wrapper div-icon-wrapper',
            html:
              '<div class="' +
              className +
              ' div-icon hover"><span class="div-label">' +
              data +
              '</span><span class="div-anchor-point"></span></div>',
          });
        }
      },
      getIcon(key, item) {
        const sensor = sensorModule.sensorById(key);

        if (item.iconStyle) {
          return new L.Icon(this.conf['markerIcon-' + item.iconStyle]);
        }

        if (!sensor) {
          return this.icon;
        }

        if (sensor.status === 'MAINTENANCE') {
          return this.iconMaintenance;
        } else if (sensor.status === 'OFFLINE') {
          return this.iconArchived;
        }

        return this.icon;
      },
      getIconHover(key, itemHighlighted) {
        const sensor = sensorModule.sensorById(key);

        if (itemHighlighted.iconStyle) {
          return new L.Icon(
            this.conf['markerIconHover-' + itemHighlighted.iconStyle]
          );
        }

        if (!sensor) {
          return this.iconHover;
        }

        if (sensor.status === 'MAINTENANCE') {
          return this.iconMaintenanceHover;
        } else if (sensor.status === 'OFFLINE') {
          return this.iconArchivedHover;
        }

        return this.iconHover;
      },
      /**
       * Affiche les layers paramétrés dans conf
       */
      setLayers() {
        // loop layers
        for (let layer of this.conf.layers) {
          // get request
          Axios.get(layer.url, { responseType: 'text' })
            .then(({ data }) => {
              try {
                let geoJson = {};

                if (layer.type === 'geoJson') {
                  geoJson = data;
                } else {
                  const xml = new DOMParser().parseFromString(data, 'text/xml');

                  // create geoJson string
                  if (layer.type === 'kml') {
                    geoJson = toGeoJson.kml(xml);
                  } else if (layer.type === 'gpx') {
                    geoJson = toGeoJson.gpx(xml);
                  }
                }

                // Si geoJson n'est pas vide
                if (Object.entries(geoJson).length > 0) {
                  this.layers.push({
                    geoJson: Object.freeze(geoJson),
                    options: layer.options || {},
                    style: layer.style,
                  });
                }
              } catch (e) {
                console.error('Erreur layer: ' + layer.url);
                console.error(e);
              }
              return undefined;
            })
            .catch(console.error.bind(console));
        }
      },
      /**
       * ferme la popup detail
       **/
      closeDetail() {
        this.mapPopupContent = null;
        this.$emit('popupClose');
      },
      showDetailSensor(key) {
        const sensor = sensorModule.sensorById(key);

        this.$root.$emit('popup-select-sensor', sensor.id);
      },
      boundsUpdated(latlng) {
        this.boundsValue = [latlng.getNorthEast(), latlng.getSouthWest()];
        this.$emit('update-bounds', latlng);

        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      },
    },
  };
</script>
