import Diffusio from '@Diffusio/Diffusio';
import Template from '@Templates/views/Sensor/ListSensor.vhtml';
import { Component, Vue } from 'vue-property-decorator';

@Template
@Component({
  components: {
    Diffusio
  },
})
export default class ListSensor extends Vue {
}
