<div>
  <div class="rounded-t-md bg-gray-dark py-3 relative">
    <div class="w-full justify-center items-center font-light px-8">
      <div class="text-lg mb-4">
        <span class="flex-col flex lg:flex-row items-center justify-center">
          <span class="mb-4 lg:mb-0">{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.from') }}</span>
          <span class="bg-gray-darker rounded-full mx-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker">
            <span>
              <v-date-picker v-model="dateStart" :max-date="dateEnd" :min-date="minDate" :locale="$i18n.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <button type="button" class="font-bold" v-on="inputEvents">
                    {{ inputValue }}
                  </button>
                </template>
              </v-date-picker>
            </span>
            <span class="ml-2">
              <Icon name="calendar" :width="17" :height="23"/>
            </span>
          </span>
          <span class="mb-4 lg:mb-0">{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.to') }}</span>
          <span class="bg-gray-darker rounded-full ml-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker">
            <span>
              <v-date-picker v-model="dateEnd" :max-date="maxDate" :min-date="dateStart" :locale="$i18n.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <button type="button" class="font-bold" v-on="inputEvents">
                    {{ inputValue }}
                  </button>
                </template>
              </v-date-picker>
            </span>
            <span class="ml-2">
              <Icon name="calendar" :width="17" :height="23"/>
            </span>
          </span>
        </span>
      </div>

      <div class="text-xs flex-col flex lg:flex-row justify-center items-center text-center mt-6">
        <button @click="setDateToday" type="button" class="no-hover bg-gray-darker rounded-full mx-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker mb-4 lg:mb-0">
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.today') }}
        </button>
        <button @click="setDateWeek" type="button" class="no-hover bg-gray-darker rounded-full mx-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker mb-4 lg:mb-0">
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs["7_days"]') }}
        </button>
        <button @click="setDateMonth" type="button" class="no-hover bg-gray-darker rounded-full mx-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker mb-4 lg:mb-0">
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs["30_days"]') }}
        </button>
        <button @click="setDateYear" type="button" class="no-hover bg-gray-darker rounded-full mx-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker mb-4 lg:mb-0">
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.last_year') }}
        </button>
        <button @click="setDateFromStart" type="button" class="no-hover bg-gray-darker rounded-full mx-2 px-4 py-1 flex items-center justify-center hover:bg-gray-very-darker mb-4 lg:mb-0">
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.since_installation') }}
        </button>
      </div>
    </div>

    <transition name="fade">
      <div v-if="showLoader" class="absolute top-0 right-0 left-0 bottom-0 z-50 bg-white opacity-80 flex items-center justify-center gap-6">
        <div class="flex items-center justify-center">
          <span class="spinner spinner-light"><span></span><span></span><span></span><span></span></span>
        </div>
        <div class="font-bold text-center">
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.loading') }}
        </div>
      </div>
    </transition>
  </div>

  <div class="bg-white rounded-md p-5 md:p-10">
    <div v-if="maintenanceDate !== null" class="text-xl bg-orange bg-opacity-5 text-orange p-5 md:pr-14 mb-6 rounded-md">
      <p class="flex items-center">
        <span class="mr-5">
          <Icon name="bell" :width="22" :height="30"/>
        </span>
        <span>{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.site_in_maintenance') }} <strong>{{ maintenanceDate.format('DD/MM/YYYY HH:mm') }}</strong>.</span>
      </p>
    </div>

    <div class="md:pr-14 mb-6">
      <div class="flex justify-end mb-6" v-if="sensorData.upper_graph && sensorData.upper_graph.site_explanation_title && sensorData.upper_graph.site_explanation_text && sensorData.upper_graph.site_explanation_title.length > 0 && sensorData.upper_graph.site_explanation_text.length > 0">
        <Popover v-if="$i18n.locale === 'fr'" icon="interrogation" :title="sensorData.upper_graph.site_explanation_title" :text="sensorData.upper_graph.site_explanation_text"/>
      </div>

      <div class="p-5 md:p-10 md:pr-14 mb-6">
        <highcharts :options="optionsUpperGraph"></highcharts>

        <div v-if="sensor.regulated_limit !== null" class="mt-8">
          <button @click="toggleRegulatedLimit" type="button" class="flex items-center justify-center text-sm">
            <span class="mr-2 border border-border-gray py-2 px-3 rounded-full text-orange">
              <Icon name="eye" :width="14" :height="9"/>
            </span>
            <span>{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.show_limit') }} {{ sensor.regulated_limit }} {{ sensor.unit }}</span>
          </button>
        </div>
      </div>

      <div v-if="showOptionFieldValueGraph" class="p-5 md:p-10 md:pr-14 mb-6">
        <highcharts :options="optionFieldValueGraph"></highcharts>
      </div>

      <div v-if="showOptionTempHumidityGraph" class="p-5 md:p-10 md:pr-14 mb-6">
        <highcharts :options="optionTempHumidityGraph"></highcharts>
      </div>

      <div v-if="showOptionVoltageAxisGraph" class="p-5 md:p-10 md:pr-14 mb-6">
        <highcharts :options="optionVoltageAxisGraph"></highcharts>
      </div>

      <div class="p-5 md:p-10 md:pr-14 mb-6">
        <highcharts :options="optionsLowerGraph"></highcharts>
      </div>

      <div class="wysiwyg-content mb-10">
        <h3 class="mb-3">{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.measures_explications_title') }}</h3>
        <p>
          {{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.measures_explications_content') }}
        </p>
      </div>

      <div class="flex flex-col md:flex-row justify-center items-center">
        <form v-if="sensorData.report_url.length > 0 && sessionToken !== null" :action="actionDownloadData" method="post" target="_blank">
          <input type="hidden" name="token" :value="sessionToken">
          <button type="submit" class="button-light-blue no-hover mb-4 lg:mx-2 lg:mb-0">{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.download_data') }}</button>
        </form>
        <button type="button" @click="generatePDF" class="button-light-blue-full md:mx-2 no-hover">{{ $t('templates.components.components.Page.DetailSensor.MesuresTabs.generate_pdf_report') }}</button>
      </div>

      <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1600"
        :filename="pdfName"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        @startPagination="$root.$emit('showLoader')"
        @hasDownloaded="$root.$emit('hideLoader')"
        :html-to-pdf-options="htmlToPdfOptions"
        ref="html2Pdf">
          <section slot="pdf-content">
            <ReportSensorPDF :sensor="sensor" :now="now" :dates="datesDayJS" :optionsLowerGraph="optionsLowerGraph" :optionsUpperGraph="optionsUpperGraph"/>
          </section>
      </VueHtml2pdf>
    </div>
  </div>
</div>
