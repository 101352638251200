import Template from '@Templates/components/Helpers/Icon.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component
export default class Icon extends Vue {
  @Prop(String)
  private readonly name: string;

  @Prop({
    type: Number,
    default: 24,
  })
  private readonly width: number;

  @Prop({
    type: Number,
    default: 24,
  })
  private readonly height: number;

  /**
   * Paths to assets.
   *
   * @private
   * @type {string}
   * @memberof Icon
   */
  private path: string;

  /**
   * Prevent change flickering.
   *
   * @private
   * @memberof Icon
   */
  private isMounted = false;

  /**
   * Icon path.
   *
   * @readonly
   * @private
   * @memberof Icon
   */
  private get href() {
    const path = require.context('../../assets/img', true, /.svg$/);

    return `${path('./icons.svg')}#${this.name}`;
  }

  /**
   * Width of the icon in px.
   *
   * @readonly
   * @memberof Icon
   */
  private get computedWidth() {
    return `${this.width}px`;
  }

  /**
   * Height of the icon in px.
   *
   * @readonly
   * @memberof Icon
   */
  private get computedHeight() {
    return `${this.height}px`;
  }
}
