<template>
  <div
    class="filterWrapper rangeFilter flex md:items-center flex-col md:flex-row md:col-span-8"
    :class="{activeRange}"
  >
    <p class="md:mr-8 mb-3 md:mb-0">{{ name }}</p>
    <vue-slider
      ref="slider"
      v-model="rangeValue"
      v-bind="conf"
      :clickable="false"
      :height="10"
      :dotSize="20"
      :lazy="true"
      tooltip="always"
      tooltipPlacement="bottom"
      :tooltipFormatter="conf.formatter"
      :enableCross="true"
      @drag-end="setRequestValue"
      @dragging="dragg"
      class="flex-grow mx-5 md:mx-0"
    />
  </div>
</template>

<script>
  import baseEngines from '@Diffusio/js/components/engines/baseEngines';
  import vueSlider from 'vue-slider-component';

  // documentation slider
  // https://github.com/NightCatSama/vue-slider-component
  export default {
    name: 'RangeFilter',
    components: {
      vueSlider,
    },
    mixins: [baseEngines],
    props: {
      name: {
        type: String,
        default: '',
      },
      requestValue: {
        type: String,
        default: '',
      },
      conf: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        draggValue: null,
        rangeValue: null,
      };
    },
    computed: {
      activeRange() {
        if (Array.isArray(this.rangeValue)) {
          if (
            this.rangeValue[0] !== this.conf.value[0] ||
            this.rangeValue[1] !== this.conf.value[1]
          ) {
            return true;
          }
        } else if (this.rangeValue !== this.conf.value) {
          return true;
        }
        return false;
      },
    },
    watch: {
      filterData(val, oldVal) {
        if (oldVal && !val) {
          this.resetRange();
        }
      },
    },
    created() {
      // If requestValue is not empty
      if (this.requestValue !== null) {
        // Restore selection
        this.filterData = this.requestValue;
        this.rangeValue = this.extractRangeValue(this.requestValue);
        this.updateContextValue();
      } else if (this.conf.value) {
        this.rangeValue = this.conf.value;
      }
    },
    methods: {
      resetRange() {
        this.rangeValue = this.conf.value;
        this.draggValue = null;
      },
      dragg(val) {
        if (Array.isArray(val)) {
          this.draggValue =
            ' (' +
            this.formatValue(val[0]) +
            ' - ' +
            this.formatValue(val[1]) +
            ')';
        } else {
          this.draggValue = '(' + this.formatValue(val) + ')';
        }
      },
      formatValue(value) {
        return this.conf.formatter.replace('{value}', value);
      },
      extractRangeValue(string) {
        return string.indexOf('~') > -1 ? string.split('~') : string;
      },
      updateContextValue() {
        if (this.filterData) {
          let data = this.filterData.split('~');
          for (let index in data) {
            data[index] = this.formatValue(data[index]);
          }

          this.updateContext([
            {
              id: this.filterData,
              name: this.name + ' : ' + data.join(' et '),
            },
          ]);
        }
      },
      setRequestValue(el) {
        if (!this.activeRange) {
          this.filterData = '';
          this.updateContext('');
          return;
        }

        let data = this.$refs.slider.getValue();
        this.filterData = Array.isArray(data) ? data.join('~') : data;
        this.updateContextValue();
      },
    },
  };
</script>
