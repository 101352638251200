import { createInstance } from '@/diffusio/js/DsioParams';
import Request from '@Diffusio/js/classes/Request';
import AppMixin from '@Diffusio/js/mixins/App';
import theme from '@Diffusio/js/utils/vCalendarTheme';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import HighchartsVue from 'highcharts-vue';
import 'leaflet/dist/leaflet.css';
import VCalendar from 'v-calendar';
import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import 'vue-slider-component/theme/default.css';
import App from './App';
import { getLocale } from './helpers/MiscHelpers';
import router from './router';
import store from './store/store';
import './style/tailwind.css';
import { i18n } from './translations';

/*
 * Window global object.
 */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    googleMaps: any;
  }
}

Vue.config.productionTip = false;

// Current locale.
const locale = getLocale();

dayjs.locale(locale);
dayjs.extend(utc);
dayjs.extend(timezone);

// Default timezone
dayjs.tz.setDefault('Europe/Paris');

// Diffusio
Vue.use(vClickOutside);
Vue.use(Request);
Vue.mixin(AppMixin);

Vue.use(HighchartsVue);
Vue.use(VCalendar, {
  masks: {
    weekdays: 'WWW',
  },
  /**
   * Configuration du theme du calendrier
   */
  theme,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  data() {
    return {
      instance: createInstance(this), // Inject diffusio params
    };
  },
}).$mount('#app');
