import { Site, SiteRawDataMesure } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import { sensorDataModule, sessionModule } from '@/store';
import imgDefaultSensor from '@Assets/img/default_sensor.jpg';
import Template from '@Templates/components/Page/DetailSensor/ReportSensorPDF.vhtml';
import iconOnline from '@Templates/components/Pin/PinSensor.html';
import iconArchived from '@Templates/components/Pin/PinSensorArchived.html';
import iconMaintenance from '@Templates/components/Pin/PinSensorMaintenance.html';
import { Dayjs } from 'dayjs';
import { Chart } from 'highcharts-vue';
import L from 'leaflet';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  LMap, LMarker, LTileLayer
} from 'vue2-leaflet';

@Template
@Component({
  components: {
    Icon,
    LMap,
    LMarker,
    LTileLayer,
    Chart,
  },
})
export default class ReportSensorPDF extends Vue {
  @Prop({
    required: true,
  })
  private sensor: Site;

  /**
   * Split graph options.
   *
   * @private
   * @type {*}
   * @memberof ReportSensorPDF
   */
  @Prop({
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private optionsUpperGraph: any;

  /**
   * Bar graph options.
   *
   * @private
   * @type {*}
   * @memberof ReportSensorPDF
   */
  @Prop({
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private optionsLowerGraph: any;

  /**
   * Dates of chart.
   *
   * @private
   * @type {{start: Dayjs, end: Dayjs}}
   * @memberof ReportSensorPDF
   */
  @Prop({
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private dates: {start: Dayjs; end: Dayjs};

  /**
   * Date of generation.
   *
   * @private
   * @type {Dayjs}
   * @memberof ReportSensorPDF
   */
  @Prop({
    required: true,
  })
  private now: Dayjs;

  /**
   * Extremums.
   *
   * @private
   * @type {{max: number; min: number; avg: number}|null}
   * @memberof DetailSensor
   */
  private extremums: {max: number; min: number; avg: number}|null = null;

  /**
   * Mounted event
   *
   * @memberof ReportSensorPDF
   */
  public mounted() {
    // Compute extremums.
    this.$root.$on('dateMesureTabsChanged', (value: {start: Dayjs; end: Dayjs}) => {
      if (this.sensor) {
        const extremums = sensorDataModule.computeExtremumsOfDateRange(this.sensor.id, value.start, value.end);

        if (extremums !== null) {
          this.extremums = extremums;
        } else if (this.sensorData && this.sensorData.max_min_avg) {
          this.extremums = {
            max: this.sensorData.max_min_avg.max,
            min: this.sensorData.max_min_avg.min,
            avg: this.sensorData.max_min_avg.avg,
          };
        } else {
          this.extremums = null;
        }
      } else {
        this.extremums = null;
      }
    });
  }

  /**
   * Format sensor address to not take too much lines.
   *
   * @private
   * @param {string} address
   * @returns
   * @memberof GlobalReportPDF
   */
  private formatSensorAddress(address: string) {
    const length = 30;

    if (address.length > length) {
      return `${address.substring(0, length)} ...`;
    }

    return address;
  }

  /**
   * Compute the image of an sensor.
   *
   * @private
   * @param {string[]} [images]
   * @returns
   * @memberof DetailSensor
   */
  private getImage(images?: string[]) {
    if (images && images.length > 0 && images[0].length > 0) {
      return images[0];
    }

    return imgDefaultSensor;
  }

  /**
   * When error loading the sensor image.
   *
   * @private
   * @param {Event} e
   * @memberof DetailSensor
   */
  private onImgError(e: Event) {
    if (e.target) {
      const image = e.target as HTMLImageElement;
      image.src = imgDefaultSensor;
    }
  }

  /**
   * Get leaflet map options.
   */
  public get leafletMapOptions() {
    return {
      zoomControl: false,
      dragging: false,
      touchZoom: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      tap: false,
    };
  }

  /**
   * Get current sensor geolocation as an array.
   *
   * @readonly
   * @memberof DetailSensor
   */
  public get sensorGeolocation() {
    return [
       this.sensor?.geolocation.latitude || 0,
       this.sensor?.geolocation.longitude || 0,
    ];
  }

  /**
   * account of the user.
   *
   * @readonly
   * @memberof ReportSensorPDF
   */
  public get account() {
    return sessionModule.userAccount;
  }

  /**
   * Sensor datas.
   *
   * @readonly
   * @private
   * @type {(SiteDataMesure|null)}
   * @memberof ReportSensorPDF
   */
  private get sensorData(): SiteRawDataMesure|null {
    return sensorDataModule.sensorDataById(this.sensor.id);
  }

  /**
   * Options for upper graph.
   *
   * @readonly
   * @private
   * @memberof ReportSensorPDF
   */
  private get optUpperGraph() {
    return {
      ...this.optionsUpperGraph,
      title: false,
    };
  }

  /**
   * Options for lower graph.
   *
   * @readonly
   * @private
   * @memberof ReportSensorPDF
   */
  private get optLowerGraph() {
    return {
      ...this.optionsLowerGraph,
      title: false,
    };
  }

  /**
   * Get the map icon.
   *
   * @readonly
   * @private
   * @memberof DetailSensor
   */
  private get mapIcon() {
    let icon = iconOnline;

    if (this.sensor?.status === 'OFFLINE') {
      icon = iconArchived;
    } else if (this.sensor?.status === 'MAINTENANCE') {
      icon = iconMaintenance;
    }

    return new L.DivIcon({
      html: icon,
      iconSize: [40, 47],
      iconAnchor: [20, 47],
    });
  }
}
