import {
  globalReportModule, sensorDataModule, sensorModule, sessionModule
} from '@/store';
import imgDefaultSensor from '@Assets/img/default_sensor.jpg';
import Template from '@Templates/components/Page/GlobalReport/GlobalReportPDF.vhtml';
import { Dayjs } from 'dayjs';
import { Chart } from 'highcharts-vue';
import { chunk } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component({
  components: { Chart },
})
export default class GlobalReportPDF extends Vue {
  /**
   * Dates of chart.
   *
   * @private
   * @type {{start: Dayjs, end: Dayjs}}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private dates: {start: Dayjs; end: Dayjs};

  /**
   * Date of generation.
   *
   * @private
   * @type {Dayjs}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  private now: Dayjs;

  /**
   * Chart 1 options.
   *
   * @private
   * @type {*}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private optionsChart1: any;

  /**
   * Chart 2 options.
   *
   * @private
   * @type {*}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private optionsChart2: any;

  /**
   * Chart 1 title.
   *
   * @private
   * @type {string}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  private titleChart1: string;

  /**
   * Chart 2 title.
   *
   * @private
   * @type {string}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  private titleChart2: string;

  /**
   * Tags selected.
   *
   * @private
   * @type {string[]}
   * @memberof GlobalReportPDF
   */
  @Prop({
    required: true,
  })
  private tags: string[];

  /**
   * Get sensor datas by id.
   *
   * @private
   * @param {string} id
   * @returns
   * @memberof GlobalReportPDF
   */
  private sensorDataById(id: string) {
    return sensorDataModule.sensorDataById(id);
  }

  /**
   * Compute extremums of a sensor.
   *
   * @private
   * @param {string} id
   * @returns
   * @memberof GlobalReportPDF
   */
  private sensorExtremumsById(id: string) {
    return globalReportModule.sensorExtremumsById(id);
  }

  /**
   * Format sensor address to not take too much lines.
   *
   * @private
   * @param {string} address
   * @returns
   * @memberof GlobalReportPDF
   */
  private formatSensorAddress(address: string) {
    const length = 30;

    if (address.length > length) {
      return `${address.substring(0, length)} ...`;
    }

    return address;
  }

  /**
   * Compute the image of an sensor.
   *
   * @private
   * @param {string[]} [images]
   * @returns
   * @memberof DetailSensor
   */
  private getImage(images?: string[]) {
    if (images && images.length > 0 && images[0].length > 0) {
      return images[0];
    }

    return imgDefaultSensor;
  }

  /**
   * When error loading the sensor image.
   *
   * @private
   * @param {Event} e
   * @memberof DetailSensor
   */
  private onImgError(e: Event) {
    if (e.target) {
      const image = e.target as HTMLImageElement;
      image.src = imgDefaultSensor;
    }
  }

  /**
   * Current user account.
   *
   * @readonly
   * @private
   * @memberof GlobalReportPDF
   */
  private get account() {
    return sessionModule.userAccount;
  }

  /**
   * All sensors.
   *
   * @readonly
   * @private
   * @memberof GlobalReportPDF
   */
  private get sensors() {
    return sensorModule.filteredSensorsByTags(this.tags);
  }

  /**
   * Data for charts.
   *
   * @readonly
   * @private
   * @memberof GlobalReport
   */
  private get globalReportData() {
    return globalReportModule.globalReportData;
  }

  /**
   * Get sensors as chunks.
   *
   * @private
   * @returns
   * @memberof GlobalReportPDF
   */
  private get sensorChunks() {
    const nbByPages = 18;
    const nbByColumns = 9;

    if (this.sensors.length < nbByPages) {
      return [chunk(this.sensors, nbByColumns)];
    }

    return chunk(this.sensors, nbByPages).map((c) => chunk(c, nbByColumns));
  }

  /**
   * Options for chart 1.
   *
   * @readonly
   * @memberof GlobalReportPDF
   */
  public get optChart1() {
    return {
      ...this.optionsChart1,
      title: false,
    };
  }

  /**
   * Options for chart 2.
   *
   * @readonly
   * @memberof GlobalReportPDF
   */
  public get optChart2() {
    return {
      ...this.optionsChart2,
      title: false,
    };
  }
}
