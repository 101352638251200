import { getLocale } from '@/helpers/MiscHelpers';
import { i18n } from '@/translations';
import axios, { AxiosInstance } from 'axios';
import { Dayjs } from 'dayjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as geofire from 'geofire-common';
import {
  Account,
  Building,
  Coverage,
  CoverageOperator,
  Exposition,
  Geolocation,
  GlobalReportData,
  RawAccount,
  RawSite,
  RawSiteAntenna,
  RawSiteData,
  Site,
  SiteAntenna,
  transformRawAccountToAccount,
  transformRawSiteAntennaToSiteAntenna,
  transformRawSiteDataToSiteData,
  transformRawSiteToSite,
} from './SensorModels';

import firestore = firebase.firestore;
import QuerySnapshot = firestore.QuerySnapshot;
import DocumentData = firestore.DocumentData;
import QueryDocumentSnapshot = firestore.QueryDocumentSnapshot;

export default class SensorApi {
  /**
   * Singleton.
   *
   * @private
   * @static
   * @type {SensorApi}
   * @memberof SensorApi
   */
  private static instance: SensorApi;

  /**
   * Axios handle.
   *
   * @private
   * @type {AxiosInstance}
   * @memberof SensorApi
   */
  private axios: AxiosInstance;

  private firestore = firestore();

  /**
   * Constructor.
   * @memberof SensorApi
   */
  private constructor() {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_API_SITE_BASE_URL,
      headers: {
        'Accept-Language': getLocale(),
      },
      transformResponse: [
        (data) =>
          process.env.VUE_APP_IS_PROD === 'true'
            ? SensorApi.decodeDataAsB64(data)
            : data,
        (data) => JSON.parse(data),
      ],
    });
  }

  /**
   * Get informations of the current user.
   *
   * @param {string} sessionToken
   * @returns
   * @memberof SensorApi
   */
  public async getUserAccount(sessionToken: string): Promise<Account | null> {
    try {
      const response = await this.axios.get<RawAccount>('/', {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      return transformRawAccountToAccount(
        response.data,
        i18n.t('api.SensorApi.undefined').toString()
      );
    } catch (e) {
      return null;
    }
  }

  /**
   * Get the list of sensors.
   *
   * @param {string} sessionToken
   * @returns
   * @memberof SensorApi
   */
  public async getSensors(sessionToken: string): Promise<Site[] | null> {
    try {
      const response = await this.axios.get<RawSite[]>('/sites/', {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      return response.data.map((item) =>
        transformRawSiteToSite(
          item,
          i18n.t('api.SensorApi.undefined').toString()
        )
      );
    } catch (e) {
      return null;
    }
  }

  /**
   * Get sensor datas from the API (light version, for the list).
   *
   * @param {string} sessionToken
   * @returns
   * @memberof SensorApi
   */
  public async getSensorsDataLight(sessionToken: string) {
    try {
      const response = await this.axios.get<RawSiteData>('/datas/light/', {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      return transformRawSiteDataToSiteData(response.data);
    } catch (e) {
      return null;
    }
  }

  /**
   * Get sensor datas from the API for a single sensor.
   *
   * @param {string} sessionToken
   * @param {string} sensorId
   * @returns
   * @memberof SensorApi
   */
  public async getSensorDataFull(sessionToken: string, sensorId: string) {
    try {
      const response = await this.axios.get<RawSiteData>(
        `/datas/${sensorId}/`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      return transformRawSiteDataToSiteData(response.data);
    } catch (e) {
      return null;
    }
  }

  /**
   * Get the list of antennas.
   *
   * @param {string} sessionToken
   * @param {Geolocation} geo
   * @param {number} radius
   * @returns
   * @memberof SensorApi
   */
  public async getAntennas(
    sessionToken: string,
    geo: Geolocation,
    radius: number
  ): Promise<SiteAntenna[] | null> {
    try {
      const center = [geo.latitude, geo.longitude];
      const bounds = geofire.geohashQueryBounds(center, radius);
      const promises: Promise<QuerySnapshot<DocumentData>>[] = [];
      bounds.forEach((value) => {
        const q = this.firestore
          .collection('supports')
          .orderBy('geoHash')
          .startAt(value[0])
          .endAt(value[1]);
        promises.push(q.get());
      });

      // Collect all the query results together into a single list
      return Promise.all(promises)
        .then((snapshots) => {
          const matchingDocs: RawSiteAntenna[] = [];
          snapshots.forEach((snap) => {
            snap.docs.forEach((doc: QueryDocumentSnapshot) => {
              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const split = doc.get('location').split(',');
              const latitude = parseFloat(split[0]);
              const longitude = parseFloat(split[1]);
              const distanceInKm = geofire.distanceBetween(
                [latitude, longitude],
                center
              );
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radius) {
                matchingDocs.push(doc.data() as RawSiteAntenna);
              }
            });
          });
          return matchingDocs;
        })
        .then((data) => data.map(transformRawSiteAntennaToSiteAntenna));
    } catch (e) {
      return null;
    }
  }

  /**
   * Get buildings from the API.
   * @param sessionToken
   * @param geo
   * @param radius
   * @returns
   */
  public async getBuildings(
    sessionToken: string,
    geo: Geolocation,
    radius: number
  ): Promise<Building[] | null> {
    try {
      const response = await axios.get(process.env.VUE_APP_POI_URL, {
        params: {
          radius,
          lat: geo.latitude,
          lng: geo.longitude,
        },
        headers: {
          'Accept-Language': getLocale(),
          Authorization: `Bearer ${sessionToken}`,
        },
        transformResponse: [
          (data) =>
            process.env.VUE_APP_IS_PROD === 'true'
              ? SensorApi.decodeDataAsB64(data)
              : data,
          (data) => JSON.parse(data),
        ],
      });

      return response.data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Get exposition from API.
   * @param sessionToken
   * @param nw
   * @param se
   * @param zoom
   * @returns
   */
  public async getExposition(
    sessionToken: string,
    nw: Geolocation,
    se: Geolocation,
    zoom: number,
    min: number
  ): Promise<Exposition | null> {
    try {
      const response = await axios.get(process.env.VUE_APP_EXPOSITION_URL, {
        params: {
          x1: nw.latitude,
          y1: nw.longitude,
          x2: se.latitude,
          y2: se.longitude,
          zoom,
          min,
        },
        headers: {
          'Accept-Language': getLocale(),
          Authorization: `Bearer ${sessionToken}`,
        },
        transformResponse: [
          (data) =>
            process.env.VUE_APP_IS_PROD === 'true'
              ? SensorApi.decodeDataAsB64(data)
              : data,
          (data) => JSON.parse(data),
        ],
      });

      return response.data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Get coverage API.
   * @param sessionToken
   * @param nw
   * @param se
   * @param operator
   * @param zoom
   * @returns
   */
  public async getCoverage(
    sessionToken: string,
    nw: Geolocation,
    se: Geolocation,
    operator: CoverageOperator,
    zoom: number
  ): Promise<Coverage | null> {
    try {
      const response = await axios.get(process.env.VUE_APP_COUVERTURE_URL, {
        params: {
          x1: nw.latitude,
          y1: nw.longitude,
          x2: se.latitude,
          y2: se.longitude,
          operator,
          zoom,
        },
        headers: {
          'Accept-Language': getLocale(),
          Authorization: `Bearer ${sessionToken}`,
        },
        transformResponse: [
          (data) =>
            process.env.VUE_APP_IS_PROD === 'true'
              ? SensorApi.decodeDataAsB64(data)
              : data,
          (data) => JSON.parse(data),
        ],
      });

      return response.data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Get datas for global report.
   *
   * @param {string} sessionToken
   * @param {Dayjs} start
   * @param {Dayjs} end
   * @param {string[]} tags
   * @returns
   * @memberof SensorApi
   */
  public async getDataGlobalReport(
    sessionToken: string,
    start: Dayjs,
    end: Dayjs,
    tags: string[]
  ) {
    try {
      const response = await this.axios.get<GlobalReportData>(
        '/custom_datas/',
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
          params: {
            start: start.unix(),
            stop: end.unix(),
            tags: tags.join(','),
          },
        }
      );

      return response.data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Decode data as base64.
   *
   * @private
   * @static
   * @param {string} data
   * @returns {any}
   * @memberof SensorApi
   */
  private static decodeDataAsB64(data: string): string {
    return window.decodeURIComponent(window.escape(window.atob(data)));
  }

  /**
   * Get SensorApi instance.
   *
   * @returns {SensorApi}
   * @memberof SensorApi
   */
  public static getInstance(): SensorApi {
    if (!SensorApi.instance) {
      SensorApi.instance = new SensorApi();
    }

    return SensorApi.instance;
  }
}
