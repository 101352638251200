<template>
  <div class="dsio-close-filters lcol lcol-12 lgrid-center">
    <button class="button full colored lcol" @click="closeFilters">
      <i v-if="!isMobileScreen" class="dsio-close" />
      <span v-html="closeButtonLabel" />
    </button>
  </div>
</template>

<script>
  export default {
    name: 'DsioCloseFilters',
    props: {},
    computed: {
      closeButtonLabel() {
        if (this.t.apply) {
          return this.t.apply;
        }
        return this.isMobileScreen
          ? this.t.apply || 'Appliquer'
          : this.t.close || 'Fermer';
      },
    },
    methods: {
      closeFilters() {
        this.$store.commit(this.instanceId + '/UPDATE_MORE_FILTERS', false);
      },
    },
  };
</script>
