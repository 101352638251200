import FirebaseAuth from '@/api/FirebaseAuth';
import SensorApi from '@/api/SensorApi';
import { Account } from '@/api/SensorModels';
import firebase from 'firebase';
import {
  Action, Module, Mutation, VuexModule
} from 'vuex-class-modules';
import { LoginPayloadInterface, UserInterface } from './SessionInterface';

/**
 * Convert a Firebase object user to store user.
 *
 * @param {firebase.User} user
 * @returns {UserInterface}
 */
const convertFirebaseUserToStoreUser = (user: firebase.User): UserInterface => ({
  uid: user.uid,
  name: user.displayName || '',
  email: user.email || '',
});

@Module
export default class SessionModule extends VuexModule {
  /**
   * Current logged user.
   *
   * @private
   * @type {(UserInterface | null)}
   * @memberof SessionModule
   */
  private user: UserInterface | null = null;

  /**
   * Current user account.
   *
   * @private
   * @type {(Account|null)}
   * @memberof SessionModule
   */
  private account: Account|null = null;

  /**
   * Set current user in state.
   *
   * @private
   * @param {firebase.User|null} user
   * @memberof SessionModule
   */
  @Mutation
  private setUser(user: firebase.User|null) {
    this.user = user !== null ? convertFirebaseUserToStoreUser(user) : null;
  }

  /**
   * Set account.
   *
   * @private
   * @param {Account|null} account
   * @memberof SessionModule
   */
  @Mutation
  private setAccount(account: Account|null) {
    this.account = account;
  }

  /**
   * Try to load the current user.
   *
   * @returns {Promise<void>}
   * @memberof SessionModule
   */
  @Action
  public async tryLoadUser(callback: (user: UserInterface|null) => void): Promise<void> {
    FirebaseAuth.getInstance().onAuthStateChange((user) => {
      this.setUser(user);
      callback(this.user);
    });
  }

  /**
   * Try to log a user in with its credentials.
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise<UserInterface>}
   * @memberof SessionModule
   */
  @Action
  public async login(credentials: LoginPayloadInterface): Promise<UserInterface|null> {
    // Login
    const payload = await FirebaseAuth.getInstance().login(credentials.email, credentials.password);

    if (payload.user !== null) {
      this.setUser(payload.user);
    }

    return this.currentUser;
  }

  /**
   * Logout the current user.
   *
   * @returns {Promise<void>}
   * @memberof SessionModule
   */
  @Action
  public async logout(): Promise<void> {
    await FirebaseAuth.getInstance().logout();
    this.setAccount(null);
    this.setUser(null);
  }

  /**
   * Get the session token from Firebase.
   *
   * @returns {(Promise<string|null>)}
   * @memberof SessionModule
   */
  @Action
  public async getToken(): Promise<string|null> {
    if (!this.isUserAlreadyLogged) {
      return null;
    }

    return FirebaseAuth.getInstance().getToken();
  }

  /**
   * Get current user account
   *
   * @returns {(Promise<Account|null>)}
   * @memberof SessionModule
   */
  @Action
  public async getUserAccount(): Promise<Account|null> {
    if (!this.isUserAlreadyLogged) {
      return null;
    }

    const token = await this.getToken();

    if (token === null) {
      return null;
    }

    const account = await SensorApi.getInstance().getUserAccount(token);

    if (account !== null) {
      this.setAccount(account);
    }

    return account;
  }

  /**
   * Check if a user is alredy logged in.
   *
   * @readonly
   * @type {boolean}
   * @memberof SessionModule
   */
  public get isUserAlreadyLogged(): boolean {
    return this.user !== null;
  }

  /**
   * Get the current user.
   *
   * @readonly
   * @memberof SessionModule
   */
  public get currentUser(): UserInterface | null {
    return this.user;
  }

  /**
   * Get the current user account.
   *
   * @readonly
   * @type {(Account|null)}
   * @memberof SessionModule
   */
  public get userAccount(): Account|null {
    return this.account;
  }
}
