import isEmpty from '@Diffusio/js/utils/isEmpty';

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state() {
    return {};
  },
  getters: {
    params(state) {
      let request = {};
      for (let query in state) {
        if (state.hasOwnProperty(query)) {
          let params = state[query].requestValue;
          if (!isEmpty(params)) {
            request[query] = params;
          }
        }
      }
      return request;
    },
    context(state) {
      let request = {};
      for (let query in state) {
        if (state.hasOwnProperty(query)) {
          let params = state[query].contextValues;
          if (!isEmpty(params)) {
            request[query] = params;
          }
        }
      }
      return request;
    },
  },
  mutations: {},
  actions: {},
};
