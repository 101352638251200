<div class="bg-white">
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 p-4 border rounded-t">
    <div>
      <p class="text-sm font-semibold">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.description') }}</p>
      <p>{{ antenna.nature }} / {{ antenna.hauteur }}m / {{ antenna.proprietaire }}</p>
    </div>
    <div>
      <p class="text-sm font-semibold">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.address') }}</p>
      <p>
        <span v-if="antenna.adresse1">{{ antenna.adresse1 }}</span>
        <span v-if="antenna.adresse1">{{ antenna.adresse2 }}</span>
        <span v-if="antenna.adresse1">{{ antenna.adresse3 }}</span>
      </p>
    </div>
    <div>
      <p class="text-sm font-semibold">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.city') }}</p>
      <p>{{ antenna.codePostal }} {{ antenna.commune }}</p>
    </div>
  </div>
  <vsa-list :tags="accordionTags">
    <vsa-item v-for="(a, i) in antenna.antennes" :key="i" class="border-l border-r border-b">
      <vsa-heading class="cursor-pointer">
        <div class="p-4 flex items-center hover-green">
          <span class="mr-4">
            <Icon class="chevron-animate" name="chevron-right" :width="6" :height="12" />
          </span>
          <template v-if="a.name.toLowerCase().includes('orange')">
            <img src="@Assets/img/operators/logo-orange.png" alt="Orange" class="w-6 h-6 mr-4">
          </template>
          <template v-else-if="a.name.toLowerCase().includes('bouygues')">
            <img src="@Assets/img/operators/logo-bouygues.png" alt="Bouygues" class="w-6 h-6 mr-4">
          </template>
          <template v-else-if="a.name.toLowerCase().includes('free')">
            <img src="@Assets/img/operators/logo-free.png" alt="Free" class="w-12 h-6 mr-4">
          </template>
          <template v-else-if="a.name.toLowerCase().includes('sfr')">
            <img src="@Assets/img/operators/logo-sfr.png" alt="SFR" class="w-6 h-6 mr-4">
          </template>
          <span class="font-semibold mr-4">{{ a.name }}</span>
          <span>{{ getListTechnosAntenna(a) }}</span>
        </div>
      </vsa-heading>

      <vsa-content>
        <div class="overflow-x-auto">
          <table class="w-full table-striped">
            <thead class="border-b">
              <tr>
                <th class="font-semibold text-left px-4 py-1">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.antenna') }}</th>
                <th class="font-semibold text-right px-4 py-1">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.height') }}</th>
                <th class="font-semibold text-right px-4 py-1">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.anfr_agreement') }}</th>
                <th class="font-semibold text-right px-4 py-1">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.last_modification') }}</th>
                <th class="font-semibold text-right px-4 py-1">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.orientation') }}</th>
                <th class="font-semibold text-left px-4 py-1">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabsAccordion.frequency_band') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(emetteur, i) in a.emetteurs" :key="i">
                <td class="text-left p-4">{{ emetteur.techno }}</td>
                <td class="text-right p-4">{{ emetteur.hauteur }} m</td>
                <td class="text-right p-4">{{ emetteur.dateImplantation ? emetteur.dateImplantation.format('DD/MM/YYYY') : '' }}</td>
                <td class="text-right p-4">{{ emetteur.dateModification ? emetteur.dateModification.format('DD/MM/YYYY') : '' }}</td>
                <td class="text-right p-4">
                  {{ emetteur.orientations.map(o => `${o}°`).join(', ') }}
                </td>
                <td class="text-left p-4">{{ emetteur.system }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </vsa-content>
    </vsa-item>
  </vsa-list>
</div>
