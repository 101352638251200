<div class="relative">
  <a href="#" @click.prevent="toggleDropdown" :data-active="visible.toString()" class="flex flex-col w-full h-full items-center justify-center pl-6 relative">
    <Icon :name="icon" v-if="icon" />
    <span v-if="title">{{ title }}</span>
    <Icon v-if="chevron" :width="8" :height="8" name="chevron-down" class="absolute -bottom-3" />
  </a>
  <div v-show="visible" class="bg-white z-1005 min-w-max shadow rounded-lg absolute right-0 transform origin-top-right top-full mt-5 animate-zoom">
    <slot></slot>
  </div>
</div>
