import Tracking from './Tracking';

// Rend le custom event compatible IE
(function () {
  // If customEvent exist
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  function CustomEvent (event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

/**
 * Class Event
 * se charge d'envoyer les événements vers le window
 * ainsi que de lancer le tracking GTM, GA ou GAG
 */
class Events {
  send (name, data) {
    let event = new CustomEvent(name, { detail: { ...data } });
    window.dispatchEvent(event);
  }

  detailOpen (data, trackingObject) {
    this.send('dsio-detail-open', { ...data, ...trackingObject });
    Tracking.sendPage(trackingObject);
  }

  detailClose (data) {
    this.send('dsio-detail-close', data);
  }

  searchEnd (trackingObject) {
    this.send('dsio-search-end', trackingObject);
  }

  mapLoaded (map, data) {
    this.send('dsio-map-loaded', { map, ...data });
  }

  mapPopupOpen (data) {
    this.send('dsio-map-popup-open', data);
  }

  loaded () {
    this.send('dsio-loaded');
  }
};

export default new Events();
