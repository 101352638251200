import SensorApi from '@/api/SensorApi';
import { Exposition, Geolocation } from '@/api/SensorModels';
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

@Module
export default class ExpositionModule extends VuexModule {
  /**
   * The exposition.
   */
  private m_exposition: Exposition | null = null;

  /**
   * Is exposition active.
   */
  private isExpositionActive = false;

  /**
   * Set exposition.
   * @param exposition
   */
  @Mutation
  private setExposition(exposition: Exposition) {
    this.m_exposition = exposition;
  }

  /**
   * Set exposition active.
   * @param active
   */
  @Mutation
  public setExpositionActive(active: boolean) {
    this.isExpositionActive = active;
  }

  /**
   * Get exposition.
   * @param param
   * @returns
   */
  @Action
  public async getExposition({
    nw,
    se,
    zoom,
    session,
    min,
  }: {
    nw: Geolocation;
    se: Geolocation;
    zoom: number;
    session: SessionModule;
    min: number;
  }): Promise<Exposition | null> {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const exposition = await SensorApi.getInstance().getExposition(
      token,
      nw,
      se,
      zoom,
      min
    );

    if (exposition !== null) {
      this.setExposition(exposition);
    }

    return exposition;
  }

  /**
   * Clear exposition.
   */
  @Mutation
  public clearExposition() {
    this.m_exposition = null;
  }

  /**
   * Get exposition.
   */
  public get exposition() {
    return this.m_exposition ? this.m_exposition[1] : null;
  }

  /**
   * Get exposition legend.
   */
  public get legend() {
    return this.m_exposition ? this.m_exposition[0] : null;
  }

  /**
   * Get exposition active.
   */
  public get expositionActive() {
    return this.isExpositionActive;
  }
}
