import { SiteRawDataMesure } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import Tab from '@/components/Helpers/Tab';
import Tabs from '@/components/Helpers/Tabs';
import EnvironnementTabs from '@/components/Page/DetailSensor/EnvironnementTabs';
import MesuresLaboTabs from '@/components/Page/DetailSensor/MesuresLaboTabs';
import MesuresTabs from '@/components/Page/DetailSensor/MesuresTabs';
import News from '@/components/Page/DetailSensor/News';
import { getLocale } from '@/helpers/MiscHelpers';
import {
  antennasModule, sensorDataModule, sensorModule, sessionModule
} from '@/store';
import imgDefaultSensor from '@Assets/img/default_sensor.jpg';
import iconOnline from '@Templates/components/Pin/PinSensor.html';
import iconArchived from '@Templates/components/Pin/PinSensorArchived.html';
import iconMaintenance from '@Templates/components/Pin/PinSensorMaintenance.html';
import Template from '@Templates/views/Sensor/DetailSensor.vhtml';
import { Dayjs } from 'dayjs';
import L, { LatLngTuple } from 'leaflet';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Prop } from 'vue-slider-component/node_modules/vue-property-decorator';
import {
  LMap, LMarker, LTileLayer
} from 'vue2-leaflet';

@Template
@Component({
  components: {
    Icon,
    Tabs,
    Tab,
    News,
    MesuresTabs,
    LMap,
    LTileLayer,
    LMarker,
    EnvironnementTabs,
    MesuresLaboTabs,
  },
})
export default class DetailSensor extends Vue {
  /**
   * Sensor ID passed by props.
   *
   * @private
   * @type {(string | null)}
   * @memberof DetailSensor
   */
  @Prop({ required: false })
  private sensorId!: string | null;

  /**
   * If the detail is loaded in popup.
   *
   * @private
   * @memberof DetailSensor
   */
  @Prop({ required: false, default: false })
  private renderInPopup!: boolean;

  /**
   * Checking if this sensor has antennas to display the tab.
   *
   * @private
   * @memberof DetailSensor
   */
  private hasAntennas = false;

  /**
   * If has sensor datas.
   *
   * @private
   * @memberof DetailSensor
   */
  private sensorDataLoaded = false;

  /**
   * If the current sensor is fully loaded (all datas).
   *
   * @private
   * @memberof DetailSensor
   */
  private sensorFullLoaded = false;

  /**
   * Extremums.
   *
   * @private
   * @type {{max: number; min: number; avg: number}|null}
   * @memberof DetailSensor
   */
  private extremums: {max: number; min: number; avg: number}|null = null;

  /**
   * Created event.
   *
   * @private
   * @memberof DetailSensor
   */
  private created() {
    if (this.sensorData !== null) {
      this.sensorDataLoaded = true;
    }
  }

  /**
   * Mounted event.
   *
   * @memberof DetailSensor
   */
  async mounted() {
    if (this.sensorDataLoaded) {
      await this.init();
    } else {
      this.$root.$emit('showLoader');
    }

    this.loadSensorFullDatas();
  }

  /**
   * Init component.
   *
   * @private
   * @memberof DetailSensor
   */
  private async init() {
    if (this.sensor !== null) {
      (this.$refs.map as LMap).fitBounds([
        this.sensorGeolocation as LatLngTuple,
      ]);
    }

    // Compute extremums.
    this.$root.$on('dateMesureTabsChanged', (value: {start: Dayjs; end: Dayjs}) => {
      if (this.sensor) {
        const extremums = sensorDataModule.computeExtremumsOfDateRange(this.sensor.id, value.start, value.end);

        if (extremums !== null) {
          this.extremums = extremums;
        } else if (this.sensorData && this.sensorData.max_min_avg) {
          this.extremums = {
            max: this.sensorData.max_min_avg.max,
            min: this.sensorData.max_min_avg.min,
            avg: this.sensorData.max_min_avg.avg,
          };
        } else {
          this.extremums = null;
        }
      } else {
        this.extremums = null;
      }
    });

    // Get antennas.
    if (this.antennas === null && this.sensor !== null) {
      await antennasModule.getAntennasForCurrentSensor({
        session: sessionModule,
        sensor: this.sensor,
      });
    }

    // From cache or API call.
    this.hasAntennas = true;
  }

  /**
   * Load all datas for the sensor.
   *
   * @private
   * @memberof DetailSensor
   */
  private loadSensorFullDatas() {
    if (this.sensor !== null && this.sensorData !== null && !this.sensorData.is_full) {
      sensorDataModule.getSensorData(sessionModule, this.sensor?.id)
        .then(() => {
          this.sensorFullLoaded = true;
        });
    } else {
      this.sensorFullLoaded = true;
    }
  }

  @Watch('sensorData')
  private onSensorDataChanged(value: SiteRawDataMesure|null) {
    if (value !== null) {
      this.$root.$emit('hideLoader');
      this.sensorDataLoaded = true;
      setTimeout(() => {
        this.init();
      }, 500);
    }
  }

  /**
   * Got to the list of sensors.
   *
   * @private
   * @memberof DetailSensor
   */
  private goToListSensor() {
    if (this.renderInPopup) {
      this.$root.$emit('popup-select-sensor', null);
    } else {
      this.$router.push({ name: 'list-sensor' });
    }
  }

  /**
   * When error loading the sensor image.
   *
   * @private
   * @param {Event} e
   * @memberof DetailSensor
   */
  private onImgError(e: Event) {
    if (e.target) {
      const image = e.target as HTMLImageElement;
      image.src = imgDefaultSensor;
    }
  }

  /**
   * Compute the image of an sensor.
   *
   * @private
   * @param {string[]} [images]
   * @returns
   * @memberof DetailSensor
   */
  private getImage(images?: string[]) {
    if (images && images.length > 0 && images[0].length > 0) {
      return images[0];
    }

    return imgDefaultSensor;
  }

  /**
   * Get the sensor for the route.
   *
   * @readonly
   * @memberof DetailSensor
   */
  public get sensor() {
    return sensorModule.sensorById(this.currentSensorId);
  }

  /**
   * Sensor data.
   *
   * @returns
   * @memberof DetailSensor
   */
  public get sensorData() {
    return sensorDataModule.sensorDataById(this.currentSensorId);
  }

  /**
   * Get current sensor geolocation as an array.
   *
   * @readonly
   * @memberof DetailSensor
   */
  public get sensorGeolocation() {
    return [
       this.sensor?.geolocation.latitude || 0,
       this.sensor?.geolocation.longitude || 0,
    ];
  }

  /**
   * Get leaflet map options.
   */
  public get leafletMapOptions() {
    return {
      zoomControl: false,
      dragging: false,
      touchZoom: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      tap: false,
    };
  }

  /**
   * Antennas for current sensor.
   *
   * @readonly
   * @private
   * @memberof EnvironnementTabs
   */
  private get antennas() {
    if (this.sensor !== null) {
      return antennasModule.antennasSensor(this.sensor.id);
    }

    return null;
  }

  /**
   * Compute sensor ID from props or from route.
   *
   * @readonly
   * @private
   * @memberof DetailSensor
   */
  private get currentSensorId() {
    if (this.sensorId) {
      return this.sensorId;
    }

    return this.$route.params.id;
  }

  /**
   * Url to the list sensor.
   *
   * @readonly
   * @private
   * @memberof DetailSensor
   */
  private get listSensorUrl() {
    return this.$router.resolve({ name: 'list-sensor' }).href;
  }

  /**
   * Get the map icon.
   *
   * @readonly
   * @private
   * @memberof DetailSensor
   */
  private get mapIcon() {
    let icon = iconOnline;

    if (this.sensor?.status === 'OFFLINE') {
      icon = iconArchived;
    } else if (this.sensor?.status === 'MAINTENANCE') {
      icon = iconMaintenance;
    }

    return new L.DivIcon({
      html: icon,
      iconSize: [40, 47],
      iconAnchor: [20, 47],
    });
  }

  /**
   * Get the map TileLayer.
   *
   * @readonly
   * @private
   * @memberof DetailSensor
   */
  private get mapTileLayer() {
    const locale = getLocale();
    const plan = `plan_${locale}`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const tileLayers = this.$root.instance.filters.geo.conf.tileLayers
      .filter(({ id }: {id: string}) => id === plan);

    if (tileLayers.length > 0) {
      return tileLayers[0].url;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return this.$root.instance.filters.geo.conf.tileLayers[0].url;
  }
}
