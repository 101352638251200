<div class="border mb-6 p-6 mx-6 rounded-md grid grid-flow-row gap-6">
  <label v-if="!loadingExposition" for="showExposition" class="rounded-md text-sm flex items-center container-checkbox green">
    <input id="showExposition" v-model="showExposition" type="checkbox" />
    <span class="square-checkbox mr-4 ">
      <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
    </span>
    <span class="text-left">
      <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.view_exposure') }}</span>
    </span>
  </label>
  <div v-else class="rounded-md text-sm flex items-center">
    <span class="spinner spinner-light lg:mr-3 relative"><span></span><span></span><span></span><span></span></span>
    <span class="hidden lg:block">{{ $t('templates.components.components.Helpers.DsioPinFilter.loading_exposition') }}</span>
  </div>

  <div class="border-t" v-if="coverageActive || (showExposition && !displayExposition)"></div>

  <div v-if="coverageActive">
    <p class="text-sm text-gray-light-text flex items-center font-bold">
      <span class="text-light-blue mr-4">
        <Icon name="exclamation" :width="20" :height="20" />
      </span>
      {{ $t('templates.components.components.Helpers.DsioPinFilter.exposition_coverage_selected') }}
    </p>
  </div>
  <div v-else-if="showExposition && !displayExposition">
    <p class="text-sm text-gray-light-text flex items-center font-bold">
      <span class="text-light-blue mr-4">
        <Icon name="exclamation" :width="20" :height="20" />
      </span>
      {{ $t('templates.components.components.Helpers.DsioPinFilter.ask_zoom_exposition') }}
    </p>
  </div>

  <div class="border-t" v-if="showExposition && displayExposition && !loadingExposition"></div>

  <div class="rangeFilter" v-if="showExposition && displayExposition && !loadingExposition">
    <p class="text-sm">
      {{ $t('templates.components.components.Helpers.DsioPinFilter.exposition_label_range') }}
    </p>
    <vue-slider v-model="minValue" :clickable="false" :height="10" :dotSize="20" :lazy="true" tooltip="always" tooltipPlacement="bottom" :enableCross="true" :min="0" :max="6" :interval="0.1" @change="onMinValueChange" />
  </div>

  <div class="border-t" v-if="showExposition && displayExposition && !loadingExposition"></div>

  <ExpositionLegend type="exposition" :legend="$t('templates.components.components.Helpers.ExpositionLegend.label_exposition')" v-if="showExposition && displayExposition && !loadingExposition" />
</div>
