<template>
  <div class="md:items-center flex-col md:flex-row flex">
    <div class="placeholder mr-3">
      {{ $t('diffusio.components.DsioCity.localisation') }}
    </div>
    <div class="border-b w-full flex items-center justify-between">
      <v-select
        @search="fetchOptions"
        v-model="value"
        label="label"
        :options="options"
        class="style-chooser"
        ref="select"
        :placeholder="$t('diffusio.components.DsioCity.type_address')"
        :filterable="false"
        @search:focus="clear"
      >
        <template slot="no-options">{{
          $t('diffusio.components.DsioCity.type_address')
        }}</template>
      </v-select>
      <button>
        <Icon name="search" :width="20" :height="20" />
      </button>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Helpers/Icon';
import { geolocateAddress } from '@/helpers/MiscHelpers';
import { debounce } from 'lodash';
import vSelect from 'vue-select';

  export default {
    name: 'DsioCity',
    components: { Icon, vSelect },
    data() {
      return {
        options: [],
        value: null,
      };
    },
    watch: {
      value: function(value) {
        this.$root.$emit('centerMap', value);
        this.options = [];
      },
    },
    methods: {
      clear() {
        this.$root.$emit('centerMap', null);
      },
      fetchOptions(search, loading) {
        if (search.length > 0) {
          loading(true);
          this.doSearch(loading, search, this);
        }
      },
      doSearch: debounce((loading, search, vm) => {
        geolocateAddress(search, (ok, results) => {
          if (ok) {
            vm.options = results.map((option) => {
              return {
                label: option.formatted_address,
                value: {
                  lat: option.geometry.location.lat(),
                  lng: option.geometry.location.lng(),
                  bounds: [
                    [
                      option.geometry.viewport.getSouthWest().lat(),
                      option.geometry.viewport.getSouthWest().lng(),
                    ],
                    [
                      option.geometry.viewport.getNorthEast().lat(),
                      option.geometry.viewport.getNorthEast().lng(),
                    ],
                  ],
                },
              };
            });
          }
        });
        loading(false);
      }, 300),
    },
  };
</script>
