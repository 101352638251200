import DefaultBarsChartOptions from '@/helpers/DefaultBarsChartOptions';
import DefaultBarsLeftChartOptions from '@/helpers/DefaultBarsLeftChartOptions';
import DefaultSplineChartOptions from '@/helpers/DefaultSplineChartOptions';

export default class ChartHelper {
  /**
   * Get default Highcharts configuration for spline chart.
   *
   * @static
   * @param {string} title
   * @param {string} ordinate
   * @param {string} unit
   * @returns
   * @memberof ChartHelper
   */
  public static getDefaultSplineChartOptions(title: string, ordinate: string, unit: string) {
    return DefaultSplineChartOptions(title, ordinate, unit);
  }

  /**
   * Get default Highcharts configuration for bars chart.
   *
   * @static
   * @param {string} title
   * @param {string} ordinate
   * @param {string} unit
   * @returns
   * @memberof ChartHelper
   */
  public static getDefaultBarsChartOptions(title: string, ordinate: string, unit: string) {
    return DefaultBarsChartOptions(title, ordinate, unit);
  }

  /**
   * Get default Highcharts configuration for left bars chart.
   *
   * @static
   * @returns
   * @memberof ChartHelper
   */
  public static getDefaultBarsLeftChartOptions() {
    return DefaultBarsLeftChartOptions;
  }
}
