export default function getClusterPinSensor(count: number) {
  return `
    <div class="pin-sensor-hover relative">
      <svg style="filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, .25))" width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1 7.4C13.1 7.4 7.4 13.1 7.4 20.1C7.4 27.1 13.1 32.8 20.1 32.8C27.1 32.8 32.7 27.1 32.7 20.1C32.7 13.1 27 7.4 20.1 7.4ZM20.1 30C14.6 30 10.1 25.5 10.1 20C10.1 14.5 14.6 10 20.1 10C25.6 10 30.1 14.5 30.1 20C30 25.6 25.5 30 20.1 30Z" fill="white" />
        <path d="M20.1 13C16.2 13 13 16.2 13 20.1C13 24 16.2 27.2 20.1 27.2C24 27.2 27.2 24 27.2 20.1C27.2 16.2 23.9 13 20.1 13ZM20.1 23.7C18.1 23.7 16.5 22.1 16.5 20.1C16.5 18.1 18.1 16.5 20.1 16.5C22.1 16.5 23.7 18.1 23.7 20.1C23.7 22.1 22.1 23.7 20.1 23.7Z" fill="white" />
        <path d="M33.9 5.5C26 -2 13.7 -1.8 6 5.8C-1.9 13.6 -2 26.4 5.9 34.2C8.9 37.2 15.6 43.9 18.6 46.9C19.5 47.7 20.8 47.7 21.6 46.9C24.6 43.9 31.3 37.2 34.3 34.2C34.4 34.1 34.5 34 34.6 33.9C42.3 25.9 41.9 13.2 33.9 5.5ZM20.1 35.6C11.5 35.6 4.5 28.7 4.5 20.1C4.5 11.5 11.5 4.5 20.1 4.5C28.7 4.5 35.6 11.5 35.6 20.1C35.6 28.7 28.6 35.6 20.1 35.6Z" fill="white" />
        <path d="M20.1 16.5C18.1 16.5 16.5 18.1 16.5 20.1C16.5 22.1 18.1 23.7 20.1 23.7C22.1 23.7 23.7 22.1 23.7 20.1C23.7 18.1 22.1 16.5 20.1 16.5Z" fill="#76DEA0" />
        <path d="M20.1 10C14.6 10 10.1 14.5 10.1 20C10.1 25.5 14.6 30 20.1 30C25.5 30 30 25.6 30.1 20C30.1 14.5 25.6 10 20.1 10ZM20.1 27.2C16.2 27.2 13 24 13 20.1C13 16.2 16.2 13 20.1 13C23.9 13 27.2 16.2 27.2 20.1C27.2 24 24 27.2 20.1 27.2Z" fill="#76DEA0" />
        <path d="M20.1 4.5C11.5 4.5 4.5 11.5 4.5 20.1C4.5 28.7 11.5 35.6 20.1 35.6C28.6 35.6 35.6 28.7 35.6 20.1C35.6 11.5 28.7 4.5 20.1 4.5ZM20.1 32.8C13.1 32.8 7.4 27.1 7.4 20.1C7.4 13.1 13.1 7.4 20.1 7.4C27 7.4 32.7 13.1 32.7 20.1C32.7 27.1 27.1 32.8 20.1 32.8Z" fill="#76DEA0" />
      </svg>
      <div class="absolute right-0 top-0 bg-light-green w-5 h-5 rounded-full border-2 border-white text-center text-white font-bold" style="font-size: .9em;">
        <p>${count}</p>
      </div>
    </div>
  `;
}
