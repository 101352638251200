import firebase from 'firebase/app';
import 'firebase/auth';

export default class FirebaseAuth {
  /**
   * Credentials for Firebase.
   *
   * @static
   * @memberof FirebaseAuth
   */
  public static readonly FIREBASE_CREDENTIALS = {
    apiKey: process.env.VUE_APP_FIREBASE_APP_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  };

  /**
   * Singleton.
   *
   * @private
   * @static
   * @type {FirebaseAuth}
   * @memberof FirebaseAuth
   */
  private static instance: FirebaseAuth;

  /**
   * Constructor.
   *
   * @memberof FirebaseAuth
   */
  private constructor() {
    firebase.initializeApp(FirebaseAuth.FIREBASE_CREDENTIALS);
  }

  /**
   * Try to login with credentials.
   *
   * @param {string} email
   * @param {string} password
   * @returns
   * @memberof FirebaseAuth
   */
  public async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    // Set session persistence.
    await firebase.auth().setPersistence(
      firebase.auth.Auth.Persistence.LOCAL
    );

    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  /**
   * Logout the user.
   *
   * @returns {Promise<void>}
   * @memberof FirebaseAuth
   */
  public async logout(): Promise<void> {
    return firebase.auth().signOut();
  }

  /**
   * Callback on user change.
   *
   * @param {((user: firebase.User | null) => void)} cb
   * @memberof FirebaseAuth
   */
  public onAuthStateChange(cb: (user: firebase.User | null) => void) {
    firebase.auth().onAuthStateChanged(cb);
  }

  /**
   * Get the JWT token for the user.
   *
   * @returns {(Promise<string|null>)}
   * @memberof FirebaseAuth
   */
  public async getToken(): Promise<string|null> {
    const user = firebase.auth().currentUser;

    if (user !== null) {
      return user.getIdToken();
    }

    return null;
  }

  /**
   * Get the current logged user.
   *
   * @readonly
   * @memberof FirebaseAuth
   */
  public get currentUser(): firebase.User | null {
    return firebase.auth().currentUser;
  }

  /**
   * Get FirebaseAuth instance.
   *
   * @returns {FirebaseAuth}
   * @memberof FirebaseAuth
   */
  public static getInstance(): FirebaseAuth {
    if (!FirebaseAuth.instance) {
      FirebaseAuth.instance = new FirebaseAuth();
    }

    return FirebaseAuth.instance;
  }
}
