<div>
  <template v-if="$route.meta.includeLayout">
    <Header/>

    <main class="pt-top-bar">
      <div v-if="appLoaded">
        <router-view></router-view>
      </div>
    </main>

    <Footer v-if="$route.meta.includeFooter" :background="true" :light="false"/>
  </template>

  <template v-else>
    <main v-if="appLoaded">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive && isLogged"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </main>
  </template>

  <transition name="fade">
    <Loader v-if="showLoader"/>
  </transition>

  <div id="googlemaps-list" class="hidden"></div>
</div>
