<div>
  <div>
    <ul class="flex overflow-x-auto">
      <li v-for="(tab, index) in tabs" :key="tab.id" class="flex">
        <button type="button" class="uppercase p-3 mx-5 font-semibold tab relative" @click="selectTab(index)" :class="{'active': index === selectedIndex}">
          {{ tab.title }}
        </button>
      </li>
    </ul>
  </div>
  <div class="mt-6">
    <slot></slot>
  </div>
</div>
