<template>
  <div class="filterWrapper geoFilter">
    <v-select
      ref="select"
      v-model="currentPlace"
      :filterBy="filterBy"
      :options="places"
      :placeholder="name"
    >
      <template slot="no-options">{{ notFoundLabel }}</template>

      <template slot="option" slot-scope="option">
        <span :class="option.class">{{ option.label }}</span>
      </template>

      <template slot="selected-option" slot-scope="option">{{ option.label }}</template>
    </v-select>

    <v-select
      v-if="distanceExtend && currentPlace"
      v-model="distance"
      class="distanceExtend"
      :options="distanceExtend"
      :searchable="false"
    >
      <template slot="selected-option" slot-scope="option">Rayon : {{ option.label }}km</template>
      <template slot="option" slot-scope="option">{{ option.label }}km</template>
    </v-select>

    <span v-if="currentPlace" class="nbChecked absolute" />
  </div>
</template>

<script>
  import baseEngines from '@Diffusio/js/components/engines/baseEngines';
  import vSelect from 'vue-select';
  import Axios from 'axios';
  import slugify from 'slugify';

  export default {
    name: 'GeoFilter',
    components: {
      vSelect,
    },
    mixins: [baseEngines],
    props: {
      name: {
        type: String,
        default: '',
      },
      requestValue: {
        type: Object,
        default: () => {},
      },
      conf: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        places: [],
        distance: this.requestValue
          ? this.requestValue.distance
          : this.conf.distance || 0,
      };
    },
    computed: {
      distanceExtend() {
        if (!this.conf.distanceExtend) {
          return false;
        }
        return this.conf.distanceExtend;
        // return this.conf.distanceExtend.map(dist => { return {label: dist+'km', value: dist }} );
      },
      notFoundLabel() {
        switch (this.lang) {
          case 'fr':
            return 'Pas de résultats';
          default:
            return 'Not found';
        }
      },
      currentPlace: {
        get() {
          return this.toObject(this.filterData);
        },
        set(value) {
          this.distance = value.distance ? value.distance : this.conf.distance;

          this.zoomOnMap(value);
          this.sortByDistance();

          this.filterData = !value ? '' : this.toString(value);
          this.updateContext(
            value ? [{ id: this.filterData, name: value.label }] : ''
          );
        },
      },
    },
    watch: {
      // reset zoom carte si reset filtre context
      currentPlace(val, oldVal) {
        if (!val) {
          this.zoomOnMap();
        }
      },
      distance() {
        this.zoomOnMap(this.currentPlace);
        this.filterData = this.toString(this.currentPlace);
      },
    },
    created() {
      // If requestValue is not empty
      if (this.requestValue !== null) {
        // Restore selection
        // this.distance = this.requestValue.distance;
        this.filterData =
          this.requestValue.lat +
          '~' +
          this.requestValue.lng +
          '~' +
          this.distance +
          '~' +
          this.requestValue.name;
        if (this.requestValue.data) {
          this.filterData += '~' + this.requestValue.data;
        }

        this.updateContext(
          this.requestValue.name
            ? [{ id: this.filterData, name: this.requestValue.name }]
            : ''
        );
      }

      this.loadPlaces();
    },
    mounted() {
      // override, pas de scroll au hover sourie
      // console.log(this.$refs.select);
      this.$refs.select.maybeAdjustScroll = function () {};
    },
    methods: {
      sortByDistance() {
        // si le tri par distance existe
        if (this.$root.$children[0].sorts['proximite']) {
          this.commit('UPDATE_SORT', 'proximite');
        }
      },
      zoomOnMap(value) {
        // que si carte visible et mode zoom activé
        if (
          !this.filterExists('geo') ||
          !this.mapActive ||
          !this.conf.zoomOnMap
        ) {
          return;
        }

        // Reset des bounds de la carte
        if (!value || !value.id) {
          this.commit('UPDATE_SAVED_MAP_BOUNDS', null);
          this.commit('Request/geo/UPDATE', '');
          return;
        }

        // Nouvelles bounds de la carte
        let latLng = L.latLng(value.id.split('~'));
        let bounds = latLng.toBounds(this.distance * 1300);
        const stringBounds = [
          Object.values(bounds.getSouthWest()).join('~'),
          Object.values(bounds.getNorthEast()).join('~'),
        ].join('~');

        this.commit('Request/geo/UPDATE', stringBounds);
      },
      filterBy(option, label, search) {
        const prms = {
          lower: true,
        };

        label = slugify(label, prms);
        search = slugify(search, prms);

        if (search.indexOf('sainte') === 0) {
          label = label.replace('sainte', 'ste');
          search = search.replace('sainte', 'ste');
        } else if (search.indexOf('saint') === 0) {
          label = label.replace('saint', 'st');
          search = search.replace('saint', 'st');
        }

        return label.indexOf(search) > -1;
      },
      toString(value) {
        let submitValue = '';
        if (value && value.id) {
          submitValue = value.id + '~' + this.distance + '~' + value.label;
        }
        if (value.data) {
          submitValue += '~' + value.data;
        }
        // console.log(value.data, submitValue);
        // console.log('SET : '+this.instanceId +' - '+submitValue);
        return submitValue;
      },
      toObject(string) {
        let value = '';
        if (string) {
          const data = string.split('~');
          if (data && data[3]) {
            value = {
              id: data[0] + '~' + data[1],
              label: data[3],
              value: data[3],
              distance: data[2],
              data: data[4],
            };
          }
        }
        // console.log('GET : '+this.instanceId +' - '+value);
        return value;
      },
      loadPlaces() {
        if (this.conf.url) {
          Axios.get(this.conf.url)
            .then(({ data }) => {
              this.places = data;
              return undefined;
            })
            .catch(console.error.bind(console));
        } else if (this.conf.options) {
          this.places = this.conf.options;
        }
      },
    },
  };
</script>
