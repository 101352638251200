import Errors from '@/components/Helpers/Errors';
import Icon from '@/components/Helpers/Icon';
import Footer from '@/components/Layout/Footer';
import { sessionModule } from '@/store';
import Template from '@Templates/views/User/LoginView.vhtml';
import { Component, Vue } from 'vue-property-decorator';

@Template
@Component({
  components: {
    Errors,
    Footer,
    Icon,
  },
})
export default class LoginView extends Vue {
  /**
   * Email fields.
   *
   * @private
   * @memberof LoginView
   */
  private email = '';

  /**
   * Password field.
   *
   * @private
   * @memberof LoginView
   */
  private password = '';

  /**
   * Remember me.
   *
   * @private
   * @memberof LoginView
   */
  private remember = false;

  /**
   * Errors for login.
   *
   * @private
   * @memberof LoginView
   */
  private errors: string[] = [];

  /**
   * Try to log the user in.
   *
   * @private
   * @memberof LoginView
   */
  private async tryLogin() {
    this.errors = [];
    this.$root.$emit('showLoader');

    try {
      await sessionModule.login({
        email: this.email,
        password: this.password,
        rememberMe: this.remember,
      });
    } catch (e) {
      this.errors.push(
        this.$t('templates.views.User.LoginView.login_invalid').toString()
      );

      this.$root.$emit('hideLoader');
    }
  }

  /**
   * Get the logo URL.
   *
   * @readonly
   * @private
   * @type {string}
   * @memberof Header
   */
  private get logo(): string {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@Assets/img/logo/logo_${this.$i18n.locale}.png`);
  }
}
