import Template from '@Templates/components/Helpers/Tabs.vhtml';
import { Component, Vue } from 'vue-property-decorator';
import Tab from './Tab';

@Template
@Component
export default class Tabs extends Vue {
  /**
   * All tabs.
   *
   * @private
   * @type {Vue[]}
   * @memberof Tabs
   */
  private tabs: Tab[] = [];

  /**
   * Current active tab.
   *
   * @private
   * @type {number}
   * @memberof Tabs
   */
  private selectedIndex = 0;

  /**
   * Select a tab.
   *
   * @param {number} i tab index.
   * @memberof Tabs
   */
  public selectTab(i: number) {
    this.selectedIndex = i;

    // loop over all the tabs
    this.tabs.forEach((tab: Tab, index: number) => {
      // eslint-disable-next-line no-param-reassign
      tab.active = (index === i);
    });
  }

  /**
   * Created event.
   *
   * @private
   * @memberof Tabs
   */
  private created() {
    this.tabs = this.$children as Tab[];
  }

  /**
   * Mounted event.
   *
   * @private
   * @memberof Tabs
   */
  private mounted() {
    this.selectTab(0);
  }
}
