<div v-if="globalReportData">
  <section class="pdf-item text-black w-w-print-page h-h-print-page border relative">
    <div class="bg-export-pdf absolute bottom-0 right-0 left-0 h-1/2 z-10 "></div>
    <div class="absolute bottom-0 right-0 left-0 top-0 z-20 py-6 px-8">
      <div class="flex items-center justify-between">
        <div>
          <img src="@Assets/img/pdf/logo.png" class="h-full object-contain" width="159px" height="39px" :alt="$t('templates.components.components.Page.GlobalReport.GlobalReportPDF.exem')">
        </div>
        <div>
          <img :src="account.picture_url" alt="Exem" width="133px" height="40px" onerror="this.style.display = 'none'">
        </div>
      </div>

      <div class="flex flex-col items-center justify-center mt-40">
        <img src="@Assets/img/pdf/sensor.png" width="211px" height="102px" alt="">
        <h1 class="text-6xl font-bold text-center mt-6">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.analysis') }}</h1>
        <h2 class="font-light text-2xl mt-6">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.period_from') }} {{ dates.start.format('DD/MM/YYYY') }} {{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.period_to') }} {{ dates.end.format('DD/MM/YYYY') }}</h2>
      </div>
    </div>
  </section>

  <section class="pdf-item text-black w-w-print-page h-h-print-page border py-6 px-8 relative">
    <div class="flex items-center justify-between">
      <div>
        <h1 class="font-semibold">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.summary_of_sites') }}</h1>
      </div>
      <div>
        <img src="@Assets/img/pdf/logo.png" class="h-full object-contain" width="159px" height="39px" :alt="$t('templates.components.components.Page.GlobalReport.GlobalReportPDF.exem')">
        <img :src="account.picture_url" alt="Exem" width="133px" height="40px" onerror="this.style.display = 'none'">
      </div>
    </div>

    <div class="grid grid-cols-6 mt-6 gap-3">
      <div class="col-span-4">
        <div class="flex flex-col">
          <div class="bg-light-green bg-opacity-5 rounded p-6 h-20 mb-4 flex items-center">
            <div class="mr-8">
              <p class="text-2xl text-light-green font-semibold">{{ sensors.length }} {{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.sensors') }}</p>
            </div>
            <div>
              <p class="flex flex-wrap gap-2">
                <span class="text-xs border rounded px-1 white whitespace-nowrap" v-for="(tag, i) in tags" :key="i">{{ tag }}</span>
              </p>
            </div>
          </div>

          <div class="rounded border p-6 mb-4 grid grid-cols-6">
            <p class="col-span-2 flex items-center font-light">{{ titleChart1 }}</p>
            <highcharts :options="optChart1" class="col-span-4 h-54"></highcharts>
          </div>

          <div class="rounded border p-6 mb-4 grid grid-cols-6">
            <p class="col-span-2 flex items-center font-light">{{ titleChart2 }}</p>
            <highcharts :options="optChart2" class="col-span-4 h-54"></highcharts>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <div class="flex flex-col">
          <div class="bg-light-blue bg-opacity-5 rounded p-6 h-38 mb-4 flex flex-col items-center justify-center">
            <div class="mb-2">
              <img src="@Assets/img/pdf/max.png" width="30px" height="42px">
            </div>
            <div class="text-center">
              <p class="font-semibold text-2xl text-light-blue">{{ globalReportData.max.max }} V/m</p>
              <p class="text-sm">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.max') }}</p>
            </div>
          </div>

          <div class="bg-light-blue bg-opacity-5 rounded p-6 h-38 mb-4 flex flex-col items-center justify-center">
            <div class="mb-2">
              <img src="@Assets/img/pdf/avg.png" width="30px" height="42px">
            </div>
            <div class="text-center">
              <p class="font-semibold text-2xl text-light-blue">{{ globalReportData.avg_max }} V/m</p>
              <p class="text-sm">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.avg_max') }}</p>
            </div>
          </div>

          <div class="bg-light-blue bg-opacity-5 rounded p-6 h-38 mb-4 flex flex-col items-center justify-center">
            <div class="mb-2">
              <img src="@Assets/img/pdf/bell.png" width="30px" height="42px">
            </div>
            <div class="text-center">
              <p class="font-semibold text-2xl text-light-blue">{{ globalReportData.alarms }}</p>
              <p class="text-sm">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.events') }}</p>
            </div>
          </div>

          <div class="bg-light-blue bg-opacity-5 rounded p-6 h-38 mb-4 flex flex-col items-center justify-center">
            <div class="mb-2">
              <img src="@Assets/img/pdf/pin-blue.png" width="30px" height="42px">
            </div>
            <div class="text-center">
              <p class="font-semibold text-2xl text-light-blue">{{ globalReportData.atypical_sites_count }}</p>
              <p class="text-sm">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.atypical_sites') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="now !== null" class="absolute bottom-3 right-8">
      <p class="text-xs text-right italic text-gray-lighter-text">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.report_generated') }} {{ now.format('DD/MM/YYYY HH:mm') }}</p>
    </div>
  </section>

  <section class="pdf-item text-black w-w-print-page h-h-print-page border py-6 px-8 relative" v-for="(page, i) in sensorChunks">
    <div class="flex items-center justify-between">
      <div>
        <h1 class="font-semibold">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.summary_of_sites') }}</h1>
      </div>
      <div>
        <img src="@Assets/img/pdf/logo.png" class="h-full object-contain" width="159px" height="39px" alt="Supervision - Un service Exem">
        <img :src="account.picture_url" alt="Exem" width="133px" height="40px" onerror="this.style.display = 'none'">
      </div>
    </div>

    <div class="grid grid-cols-2 mt-6 gap-6 text-xs">
      <div v-for="(column, i) in page" :key="i">
        <table class="border-collapse" cellspacing="0" cellpadding="0">
          <thead class="border-b">
            <tr>
              <td></td>
              <td class="p-2">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.site') }}</td>
              <td class="p-2">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.avg') }}</td>
              <td class="p-2">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.min') }}</td>
              <td class="p-2">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.max') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sensor in column" :key="sensor.id" class="border-b">
              <td class="w-16 h-14 relative">
                <img :src="getImage(sensor.picture_url)" @error="onImgError" class="absolute top-0 left-0 right-0 bottom-0 object-cover h-full w-full" :alt="sensor.name">
              </td>
              <td class="p-2">
                <p>#{{ sensor.name }}</p>
                <p class="text-gray-lighter-text">
                  {{ formatSensorAddress(sensor.address.location) }}<br>
                  {{ sensor.address.zip }} {{ sensor.address.city }} ({{ sensor.address.country }})
                </p>
              </td>
              <td class="p-2">
                <template v-if="sensorDataById(sensor.id) !== null && sensorExtremumsById(sensor.id) !== null">
                  {{ sensorExtremumsById(sensor.id).avg.toFixed(2) }} V/m
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td class="p-2">
                <template v-if="sensorDataById(sensor.id) !== null && sensorExtremumsById(sensor.id) !== null">
                  {{ sensorExtremumsById(sensor.id).min }} V/m
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td class="p-2">
                <template v-if="sensorDataById(sensor.id) !== null && sensorExtremumsById(sensor.id) !== null">
                  {{ sensorExtremumsById(sensor.id).max }} V/m
                </template>
                <template v-else>
                  -
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="now !== null" class="absolute bottom-3 right-8">
      <p class="text-xs text-right italic text-gray-lighter-text">{{ $t('templates.components.components.Page.GlobalReport.GlobalReportPDF.report_generated') }} {{ now.format('DD/MM/YYYY HH:mm') }}</p>
    </div>
  </section>
</div>
