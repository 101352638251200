export default function getArrow(angle: number) {
  return `
    <div class="relative no-pointer">
      <svg class="absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 100" style="transform-origin: left center; transform: rotate(${angle}deg)">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7"
          refX="0" refY="3.5" orient="auto">
            <polygon points="0 0, 10 3.5, 0 7" stroke="#808080" />
          </marker>
        </defs>
        <line x1="0" y1="50" x2="250" y2="50" stroke="#808080"
          stroke-width="4" marker-end="url(#arrowhead)" />
      </svg>
      <svg class="absolute operator orange" viewBox="0 0 350 100" xmlns="http://www.w3.org/2000/svg" style="z-index: 201!important; transform-origin: left center; transform: rotate(${angle}deg)">
        <rect class="orange" x="15" y="35" width="25" height="25" fill="#EC671B"/>
      </svg>
      <svg class="absolute operator sfr" viewBox="0 0 350 100" xmlns="http://www.w3.org/2000/svg" style="z-index: 201!important; transform-origin: left center; transform: rotate(${angle}deg)">
        <rect class="sfr" x="75" y="35" width="25" height="25" fill="#9B1915"/>
      </svg>
      <svg class="absolute operator bouygues" viewBox="0 0 350 100" xmlns="http://www.w3.org/2000/svg" style="z-index: 201!important; transform-origin: left center; transform: rotate(${angle}deg)">
        <rect class="bouygues" x="130" y="35" width="25" height="25" fill="#019CCB"/>
      </svg>
      <svg class="absolute operator free" viewBox="0 0 350 100" xmlns="http://www.w3.org/2000/svg" style="z-index: 201!important; transform-origin: left center; transform: rotate(${angle}deg)">
        <rect class="free" x="190" y="35" width="25" height="25" fill="#CE1F25"/>
      </svg>
    </div>
  `;
}
