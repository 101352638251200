<div>
  <div class="popover">
    <button class="w-8 h-8 border border-gray-400 text-gray-400 flex items-center justify-center rounded-full hover:border-gray-darker hover:bg-gray-darker hover:text-white">
      <Icon :name="icon" :width="9" :height="15"/>
    </button>
    <div class="popover-item text-sm rounded-md">
      <p class="font-semibold mb-2">{{ title }}</p>
      <p>{{ text }}</p>
    </div>
  </div>
</div>
