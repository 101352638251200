import { Site, SiteRawDataMesure } from '@/api/SensorModels';
import ChartHelper from '@/helpers/ChartHelper';
import { sensorDataModule } from '@/store';
import Template from '@Templates/components/Page/DetailSensor/MesuresLaboTabs.vhtml';
import dayjs from 'dayjs';
import { TooltipFormatterCallbackFunction } from 'highcharts';
import { Chart } from 'highcharts-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component({
  components: {
    Chart,
  },
})
export default class MesuresLaboTabs extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  private sensor: Site;

  private get measureDate() {
    return dayjs.unix(this.sensor.mesure_labo.measure_date);
  }

  /**
   * Options for the chart.
   *
   * @readonly
   * @private
   * @memberof MesuresLaboTabs
   */
  private get optionsChart() {
    const tooltips = new Map();
    const results = this.sensor.mesure_labo.resuts.filter((o) => o.mesure);

    results.forEach(({ service, subname }) => {
      tooltips.set(service, subname.trim().length > 0 ? subname : service);
    });

    return {
      ...ChartHelper.getDefaultBarsLeftChartOptions(),
      xAxis: {
        categories: results.map((o) => o.service),
      },
      series: [
        {
          name: 'Values',
          data: results.map((o) => (o.mesure ? o.mesure : 0)),
        },
      ],
      tooltip: {
        ...ChartHelper.getDefaultBarsLeftChartOptions().tooltip,
        // eslint-disable-next-line func-names
        formatter: function () {
          const label = tooltips.get(this.key) ?? this.key;

          return `
            <div class="flex flex-col justify-center items-center">
              <p class="text-gray-lighter-text">
                <strong>${label}</strong>
              </p>
              <p class="font-light text-lg">${this.y} V/m</p>
            </div>
          `;
        } as TooltipFormatterCallbackFunction,
      }
    };
  }

  /**
   * Sensor data of this sensor.
   *
   * @readonly
   * @private
   * @memberof MesuresLaboTabs
   */
  private get sensorData(): SiteRawDataMesure|null {
    return sensorDataModule.sensorDataById(this.sensor.id);
  }
}
