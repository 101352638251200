import Icon from '@/components/Helpers/Icon';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/DsioPinFilterItem.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component({ components: { Icon } })
export default class DsioPinFilterItem extends Vue {
  @Prop({ type: Boolean, required: true })
  private open: boolean;

  @Prop({ type: String, required: true })
  private title: string;

  @Prop({ type: String, required: true })
  private icon: string;

  @Prop({ type: Boolean, default: false })
  private disabled: boolean;

  public onClick(): void {
    this.$emit('open-accordion');
  }
}
