<template>
  <div class="filterWrapper dsio-reset-button-wrapper">
    <button :disabled="!hasFilters" class="no-button title dsio-reset-button" @click="reset">
      <i class="dsio-undo-light" />
    </button>
  </div>
</template>
<script>
  export default {
    name: 'DsioReset',
    props: {},
    computed: {
      hasFilters() {
        let filters = this.storeState.Request;
        for (let filterId in filters) {
          const context = filters[filterId].contextValues;
          if (context && context.length) {
            return true;
          }
        }

        // cas particulier carte
        if (filters['geo'] && filters['geo'].requestValue) {
          return true;
        }

        return false;
      },
    },
    methods: {
      reset() {
        // this.$root.$emit('reset', true);
        this.dispatch('resetFilters');
      },
    },
  };
</script>
