import Template from '@Templates/components/Page/ListSensor/ModeSwitcher/Mode.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component
export default class Mode extends Vue {
  @Prop({
    required: true,
    type: String
  })
  private title: string;

  /**
   * If the mode is active.
   */
  public active = false;
}
