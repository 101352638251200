import { convertArrayToObject } from '@/helpers/MiscHelpers';
import { sensorModule, sessionModule } from '@/store';
import Events from '@Diffusio/js/classes/Events';
import DsioContextFilters from '@Diffusio/js/components/DsioContextFilters';
import DsioDetail from '@Diffusio/js/components/DsioDetail';
import DsioEngine from '@Diffusio/js/components/DsioEngine';
import DsioList from '@Diffusio/js/components/DsioList';
import DsioMap from '@Diffusio/js/components/DsioMap';
import DsioMods from '@Diffusio/js/components/DsioMods';
import DsioPagination from '@Diffusio/js/components/DsioPagination';
import DsioSort from '@Diffusio/js/components/DsioSort';

export default {
  components: {
    DsioEngine,
    DsioList,
    DsioMap,
    DsioDetail,
    DsioSort,
    DsioMods,
    DsioContextFilters,
    DsioPagination,
  },
  data() {
    return {
      firstInstanceMap: false,
      scrollIntoView: false,
      mapPopupOpen: false,
      nbVisibleFilters: 5,
      center: null,
      filters: {},
      counter: '',
      sorts: [],
      list: {},
      pagination: null,
      // map
      mapConf: {},
      bounds: [],
      mapItems: {},
      total: {},
      loading: false,
      loadingMore: false,
      sortedIds: [],
      first: true,
      staticConf: {
        trads: {},
        detail: {},
      },
    };
  },
  computed: {
    defaultViewMod() {
      return this.app.params.defaultViewMod;
    },
    breakpoint() {
      return this.$root.breakpoint;
    },
    inApp() {
      return this.$root.scrollInApp;
    },
    scrollUp() {
      return this.$root.scrollUp;
    },
    oiId() {
      return this.app.state.detail.oiId;
    },
    requestParams() {
      return this.$store.getters[this.instanceId + '/requestParams'];
    },
    moreFilters() {
      return this.storeState.moreFilters;
    },
    ready() {
      return this.getter('ready');
    },
  },
  watch: {
    oiId(id) {
      // LEH ajout classe global pour print detail au format popup
      id === null
        ? this.$refs.appContainer.classList.remove('dsio-app-detail-open')
        : this.$refs.appContainer.classList.add('dsio-app-detail-open');
    },
    ready(isReady, wasReady) {
      if (isReady && !wasReady) {
        // dsio on load
        this.$nextTick(() => {
          Events.loaded();
        });
      }
    },
    mapPopupOpen(value) {
      value
        ? this.$refs.appContainer.classList.add('map-popup-open')
        : this.$refs.appContainer.classList.remove('map-popup-open');
    },
    scrollUp(value) {
      this.$refs.appContainer.classList.toggle('scrollUp');
    },
    inApp(value) {
      this.$refs.appContainer.classList.toggle('inApp');
    },
    loading(isLoading) {
      isLoading
        ? this.$refs.appContainer.classList.add('loading')
        : this.$refs.appContainer.classList.remove('loading');
      // this.$refs.appContainer.classList.toggle('loading');
    },
    breakpoint(value) {
      this.updateBreakpoints(value);
      this.watchMapUpdate();
    },
    moreFilters(val) {
      this.$refs.appContainer.classList.toggle('moreFilters');

      // ajout classe html
      // plus de filtres mobile
      if (this.isMobileScreen && this.app.state.viewMod === 'list') {
        this.setWindowOverflow(val);
      }
    },
    viewMod(mod, lastMod) {
      this.$refs.appContainer.classList.remove(lastMod);
      this.$refs.appContainer.classList.add(mod);
      this.mapPopupOpen = false;
      // ajout classe html
      // carte mobile
      this.setWindowOverflow(mod === 'mixte' && this.isMobileScreen);
    },
    requestParams(params) {
      // Si l'application est prête
      if (this.ready) {
        /**
         * on active le loader que si pagination
         * car elle remplace la liste au lieu d'ajouter
         * ou si aucun changement de page à eu lieu
         * pour les autres modes de pagination auto ou more
         */
        if (
          this.app.params.paginationType === 'numbers' ||
          (this.app.params.paginationType === 'more' &&
            this.app.state.currentPage === 1)
        ) {
          this.loading = true;

          // mettre à jour de scroll pour haut de liste
          // si dans la liste
          // si recherche hors pagination more

          if (
            typeof jQuery !== 'undefined' &&
            window.scrollY > jQuery(this.$refs.appContainer).offset().top + 100
          ) {
            this.scrollIntoView = true;
          }
        } else {
          this.loadingMore = true;
        }

        this.$Request.replaceState(this.historyState);
        this.debouncedRequest(params);
      }
    },
  },
  /**
   * Quand le composant est créer on hydrate les données (récupère les données déjà rendu)
   */
  created() {
    this.updateBreakpoints(this.breakpoint);

    // Créer le debounce pour requestData
    this.debouncedRequest = this.requestData;

    const instance = this.$root.instance;

    // Initialise les données
    this.setData(instance);

    // Initialise la carte
    if (typeof instance.filters.geo !== 'undefined') {
      const map = instance.filters.geo;
      let bounds = map.conf.bounds || null;
      if (
        typeof map.requestValue !== 'undefined' &&
        map.requestValue.length === 2
      ) {
        bounds = map.requestValue;
      }

      this.bounds = bounds;
      this.mapConf = map.conf;
    }
  },
  mounted() {
    this.watchMapUpdate();
    this.requestData({});
    this.refreshBasket();
  },
  methods: {
    watchMapUpdate() {
      // affichage liste pour les petits écrans
      // au 1er chargement + resize
      const fallbackViewMod = 'list';
      if (
        this.app.params.viewMods.indexOf(fallbackViewMod) > -1 &&
        this.defaultViewMod === 'mixte' &&
        this.isMobileScreen
      ) {
        this.dispatch('updateMod', fallbackViewMod);
      }
    },
    updateBreakpoints(breakpoint) {
      if (breakpoint) {
        switch (breakpoint) {
          case 'mobile':
            this.nbVisibleFilters = 1;
            break;
          case 'tablet':
            this.nbVisibleFilters = 1;
            break;
          case 'small_desktop':
            this.nbVisibleFilters = 2;
            break;
          case 'large_desktop':
            this.nbVisibleFilters = 4;
            break;
          case 'very_large_desktop':
            this.nbVisibleFilters = 5;
            break;
        }
      }
    },
    async requestData(params) {
      const parsedParams = this.paramsToStoreFilter(params);
      const mapItems = {};
      const lisItems = {};
      let data = [];
      let rayonnement = {};

      if (parsedParams !== null) {
        data = sensorModule.filteredSensor(parsedParams);
      } else {
        data = sensorModule.sensors;
      }

      // Filter from north to south.
      // data = sortSensors(data);

      data.forEach((item) => {
        mapItems[item.id] = {
          marker: [item.geolocation.latitude, item.geolocation.longitude],
        };

        lisItems[item.id] = item;
      });

      if (this.first) {
        const max = Math.ceil(sensorModule.maxIntensitySensors);
        rayonnement = {
          ...this.filters.rayonnement,
          conf: {
            ...this.filters.rayonnement.conf,
            max,
            value: [0, max],
          },
        };
      } else {
        rayonnement = this.filters.rayonnement;
      }

      const instance = {
        filters: {
          ...this.filters,
          geo: {
            mapItems,
            conf: {
              ...this.filters.geo.conf,
              center: this.center,
              localisation: params.localisation,
            },
          },
          etiquette: {
            ...this.filters.etiquette,
            facets: this.cumputeEtiquetteFacets(),
          },
          rayonnement,
        },
        sortedIds: [],
        eventObject: [],
        views: {
          list: lisItems,
          pagination: '<!---->',
          counter: `<strong class="text-light-blue text-2xl font-bold mr-1">${
            data.length
          }</strong> ${this.$t('diffusio.mixins.sensors')}`,
        },
      };

      const EventObject = instance.eventObject
        ? JSON.parse(JSON.stringify(instance.eventObject))
        : {};

      this.setData(instance);

      this.$nextTick(() => {
        Events.searchEnd(EventObject);

        this.firstInstanceMap = true;
      });

      this.loading = this.loadingMore = false;

      // on remonte en haut de liste
      if (this.scrollIntoView) {
        this.$refs.appBody.scrollIntoView();
        this.scrollIntoView = false;
      }

      // panier
      this.refreshBasket();
      this.first = false;
    },
    cumputeEtiquetteFacets() {
      if (sessionModule.userAccount === null) {
        return {};
      }

      const facets = sessionModule.userAccount.tags.map((e) => {
        return {
          slug: e.name,
          id: e.name,
          name: e.name,
          count: 0,
        };
      });

      if (facets.length <= 0) {
        return {};
      }

      return convertArrayToObject(facets, 'slug');
    },
    setData(instance) {
      // Set toutes les données
      for (let property in instance) {
        if (
          instance.hasOwnProperty(property) &&
          this.hasOwnProperty(property)
        ) {
          switch (typeof instance[property]) {
            case 'object':
              if (Array.isArray(this[property])) {
                this[property] = instance[property];
              } else {
                this[property] = Object.freeze(
                  Object.assign({}, instance[property])
                );
              }
              break;
            default:
              this[property] = instance[property];
          }
        }
      }

      this.total = this.sortedIds.length;

      // Set les mapItems de la carte
      if (
        typeof instance.filters !== 'undefined' &&
        typeof instance.filters.geo !== 'undefined'
      ) {
        this.mapItems = Object.freeze(
          Object.assign({}, instance.filters.geo.mapItems)
        );

        // LEH evo 2020-09 full js
        const map = instance.filters.geo;
        let bounds = map.conf.bounds || null;
        if (
          typeof map.requestValue !== 'undefined' &&
          map.requestValue.length === 2
        ) {
          bounds = map.requestValue;
        }
        this.bounds = bounds;
        this.mapConf = map.conf;
      }

      // Définit les éléments de la liste et freeze l'objet pour une meilleure réactivité
      if (typeof instance.views !== 'undefined') {
        this.pagination = instance.views.pagination;

        // liste avec append ou replace selon mode
        if (
          this.app.params.paginationType !== 'numbers' &&
          this.app.state.currentPage > 1
        ) {
          this.list = Object.freeze(
            Object.assign({}, this.list, instance.views.list)
          );
        } else {
          this.list = Object.freeze(Object.assign({}, instance.views.list));
        }

        this.counter = instance.views.counter;
      }

      this.sendChangeEvent();
    },
    sendChangeEvent() {
      this.$emit('change', {
        total: this.total,
      });
    },
    closeMoreFilters() {
      if (this.moreFilters) {
        this.commit('UPDATE_MORE_FILTERS', false);
      }
    },
    paramsToStoreFilter(params) {
      let tags = [];
      let intensity = this.filters.rayonnement.conf;
      let geo = {
        ne: {
          latitude: 51.268318,
          longitude: 9.8678344,
        },
        sw: {
          latitude: 41.2632185,
          longitude: -5.4534286,
        },
      };

      if (params.rayonnement) {
        const strIntensity = params.rayonnement.split('~');

        if (strIntensity.length === 2) {
          intensity = {
            min: parseFloat(strIntensity[0]),
            max: parseFloat(strIntensity[1]),
          };
        }
      } else {
        const max = Math.ceil(sensorModule.maxIntensitySensors);

        intensity = {
          min: 0,
          max,
        };
      }

      if (params.geo !== undefined) {
        const strGeo = params.geo.split('~');
        geo = {
          ne: {
            latitude: parseFloat(strGeo[0]),
            longitude: parseFloat(strGeo[1]),
          },
          sw: {
            latitude: parseFloat(strGeo[2]),
            longitude: parseFloat(strGeo[3]),
          },
        };
      }

      if (params.etiquette !== undefined) {
        tags = params.etiquette.split('~');
      }

      return {
        intensity,
        geo,
        tags,
      };
    },
  },
};
