import Template from '@Templates/components/Helpers/Tab.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component
export default class Tab extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  private title: string;

  /**
   * If the tab is active.
   *
   * @private
   * @type {boolean}
   * @memberof Tab
   */
  public active = false;
}
