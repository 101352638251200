import Template from '@Templates/components/Helpers/Popover.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from './Icon';

@Template
@Component({
  components: {
    Icon,
  },
})
export default class Popover extends Vue {
  @Prop({
    required: true,
  })
  private icon: string;

  @Prop({
    required: true,
  })
  private title: string;

  @Prop({
    required: true,
  })
  private text: string;
}
