import Icon from '@/components/Helpers/Icon';
import Template from '@Templates/components/Page/DetailSensor/News.vhtml';
import { Dayjs } from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component({
  components: {
    Icon,
  },
})
export default class News extends Vue {
  @Prop({
    required: true,
    type: Dayjs,
  })
  private date: Dayjs;
}
