import Template from '@Templates/components/Page/ListSensor/ModeSwitcher/ModeSwitcher.vhtml';
import { Component, Vue } from 'vue-property-decorator';
import Mode from './Mode';

@Template
@Component
export default class ModeSwitcher extends Vue {
  /**
   * All modes.
   */
  private modes: Mode[] = [];

  /**
   * Current selected mode.
   */
  private selectedIndex = 0;

  /**
   * Select a mode.
   * @param i
   */
  public selectMode(i: number) {
    this.selectedIndex = i;

    this.modes.forEach((mode: Mode, index: number) => {
      // eslint-disable-next-line no-param-reassign
      mode.active = index === i;
    });
  }

  /**
   * Created event.
   */
  private created() {
    this.modes = this.$children as Mode[];
  }

  /**
   * Mounted event.
   */
  private mounted() {
    this.selectMode(0);
  }
}
