<div>
  <div v-if="sensorDataLoaded" class="lg:pt-8 lg:pr-8 lg:pb-8">
    <div class="grid grid-cols-1 lg:grid-cols-9 gap-4">
      <div class="lg:col-span-6 bg-back-gray rounded-r-md py-6">
        <div class="flex flex-col items-start lg:flex-row-reverse lg:justify-between lg:items-center px-8">
          <a :href="listSensorUrl" @click.prevent="goToListSensor" class="flex items-center justify-center bg-btn-gray hover:bg-btn-gray-hover transition-colors duration-300 rounded-full px-5 py-2 font-semibold no-hover">
            <span class="mr-2">
              <Icon name="chevron-left" :width="8" :height="8" />
            </span>
            <span>
              {{ $t('templates.views.Sensor.DetailSensor.back_map') }}
            </span>
          </a>
          <h1 class="mt-6 lg:mt-0 font-light text-2xl">{{ $t('templates.views.Sensor.DetailSensor.site') }} #{{ sensor.name }}</h1>
        </div>

        <hr class="h-px w-full bg-border-gray my-6">

        <div class="mt-8 grid grid-cols-1 xl:grid-cols-2 gap-4 px-8">
          <div class="rounded-md border px-8 py-6 flex items-center bg-white">
            <div class="w-full grid grid-cols-1 2xl:grid-cols-2 gap-4">
              <div class="mb-6 lg:mb-0">
                <p class="mb-2">{{ $t('templates.views.Sensor.DetailSensor.localisation') }}</p>
                <p class="text-sm">{{ sensor.address.location }}<br>{{ sensor.address.zip }} {{ sensor.address.city }} ({{ sensor.address.country }})</p>
              </div>
              <div>
                <p class="mb-2">{{ $t('templates.views.Sensor.DetailSensor.installation_date') }}</p>
                <p class="text-sm">{{ sensor.installation_date.format('DD/MM/YYYY') }}</p>
              </div>
            </div>
          </div>
          <div class="rounded-md border px-8 py-6 flex items-center bg-white">
            <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 text-center gap-2">
              <div class="border-2 border-dotted border-border-gray p-1 rounded-md">
                <p class="mb-2">{{ $t('templates.views.Sensor.DetailSensor.current') }}</p>
                <p class="text-xl font-semibold">
                  <span v-if="sensor.current_value">{{ sensor.current_value.toFixed(2) }} {{ sensor.unit }}</span>
                  <span v-else>-</span>
                </p>
              </div>
              <div class="bg-back-gray p-1 rounded-md">
                <p class="mb-2">{{ $t('templates.views.Sensor.DetailSensor.max_value') }}</p>
                <p class="text-xl font-semibold">
                  <span v-if="extremums">{{ extremums.max.toFixed(2) }} {{ sensor.unit }}</span>
                  <span v-else>-</span>
                </p>
              </div>
              <div class="bg-back-gray p-1 rounded-md">
                <p class="mb-2">{{ $t('templates.views.Sensor.DetailSensor.avg_value') }}</p>
                <p class="text-xl font-semibold">
                  <span v-if="extremums">{{ extremums.avg.toFixed(2) }} {{ sensor.unit }}</span>
                  <span v-else>-</span>
                </p>
              </div>
              <div class="bg-back-gray p-1 rounded-md">
                <p class="mb-2">{{ $t('templates.views.Sensor.DetailSensor.min_value') }}</p>
                <p class="text-xl font-semibold">
                  <span v-if="extremums">{{ extremums.min.toFixed(2) }} {{ sensor.unit }}</span>
                  <span v-else>-</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 px-8">
          <Tabs>
            <Tab :title="$t('templates.views.Sensor.DetailSensor.measurements')">
              <MesuresTabs :sensorDataFullLoaded="sensorFullLoaded" :sensor="sensor"/>
            </Tab>
            <Tab :title="$t('templates.views.Sensor.DetailSensor.environment_radio')" v-show="hasAntennas">
              <EnvironnementTabs :sensor="sensor"/>
            </Tab>
            <Tab :title="$t('templates.views.Sensor.DetailSensor.detailed_measurements')" v-if="Object.keys(sensor.mesure_labo).length > 0">
              <MesuresLaboTabs :sensor="sensor"/>
            </Tab>
          </Tabs>
        </div>
      </div>

      <div class="lg:col-span-3 px-8 pb-6 lg:px-0">
        <div class="grid grid-flow-row gap-4">
          <div class="h-96">
            <LMap ref="map" class="w-full h-full object-cover rounded-md" :options="leafletMapOptions">
              <LTileLayer :url="mapTileLayer" />
              <LMarker :lat-lng="sensorGeolocation" :icon="mapIcon"></LMarker>
            </LMap>
          </div>
          <div class="max-h-72">
            <img :src="getImage(sensor.picture_url)" @error="onImgError" class="w-full h-full object-cover rounded-md" :alt="sensor.name">
          </div>
          <div v-if="sensor.public_news.length > 0">
            <h2 class="mt-3">{{ $t('templates.views.Sensor.DetailSensor.sensor_news') }}</h2>
            <div class="mt-3 grid grid-cols-1 gap-2">
              <News v-for="(news, i) in sensor.public_news" :key="i" :date="news.creation_date">
                {{ news.description }}
              </News>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
