import { Site } from '@/api/SensorModels';
import { antennasModule } from '@/store';
import Template from '@Templates/components/Page/DetailSensor/EnvironnementTabs.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EnvironnementTabsAccordion from './EnvironnementTabsAccordion';

@Template
@Component({
  components: {
    EnvironnementTabsAccordion,
  },
})
export default class EnvironnementTabs extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  private sensor: Site;

  /**
   * Antennas for current sensor.
   *
   * @readonly
   * @private
   * @memberof EnvironnementTabs
   */
  private get antennas() {
    return antennasModule.antennasSensor(this.sensor.id);
  }
}
