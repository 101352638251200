import { isEmpty } from '@Diffusio/js/utils';

export default {
  props: {
    moreFilter: Boolean,
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupOpen: false,
    };
  },
  created() {
    if (isEmpty(this.storeState.Request[this.id])) {
      this.dispatch('addFilter', this.id);
    }
  },
  mounted() {
    // nextTick attend le prochain tick pour déclarer le filtre prêt
    // Utile pour les filtres qui ont un temps de chargement à l'apparitiongi
    this.$nextTick(() => {
      this.dispatch('filterReady', this.id);
    });
  },
  destroyed() {
    // @TODO LEH SEB ??? ERREUR RESPONSIVE DES FILTRES + erreurs js
    // this.dispatch('removeFilter', this.id);
  },
  watch: {
    /* '$store.state.multiApp.currentInstanceId' (currentInstanceId) {
      if (isEmpty(this.storeState.Request[this.id])) {
        this.dispatch('addFilter', this.id);
        console.log('yyy');
      }
      console.log(currentInstanceId,this.id, this.getter('ready'), this.storeState);
    }, */
    popupOpen(val) {
      if (val === true && this.$root.breakpoint === 'mobile') {
        this.setWindowOverflow(val);
      } else {
        this.setWindowOverflow(false);
      }
    },
  },
  computed: {
    // Wrapper data of vuex store
    filterData: {
      get() {
        if (!this.storeState.Request[this.id]) {
          return null;
        }
        return this.storeState.Request[this.id].requestValue;
      },
      set(value) {
        value = String(value);
        value = value.length > 0 ? value : '';

        // Multiapp
        // if (this.isMultiApp && this.id === 'q') {
        //   return this.dispatchAll('updateFilter', { filterId: this.id, value });
        // }

        this.dispatch('updateFilter', { filterId: this.id, value });
      },
    },
  },
  methods: {
    /**
     * Ouverture de la popup
     */
    openPopup() {
      this.popupOpen = true;
    },
    /**
     * Fermeture de la popup
     */
    closePopup() {
      this.popupOpen = false;
    },
    updateContext(value) {
      this.commit('Request/' + this.id + '/UPDATE_CONTEXT', value);
    },
  },
};
