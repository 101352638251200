<div>
  <div class="my-8">
    <p>{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabs.description') }}</p>
  </div>
  <div v-for="antenna in antennas" :key="'antenna_' + antenna.id" class="mb-10">
    <h5 class="flex items-center mb-3">
      <span class="font-semibold mr-4">{{ $t('templates.components.components.Page.DetailSensor.EnvironnementTabs.support') }} {{ antenna.id }}</span>
      <span class="flex-grow h-px bg-border-gray"></span>
    </h5>
    <div>
      <EnvironnementTabsAccordion :antenna="antenna" />
    </div>
  </div>
</div>
