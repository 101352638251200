import AntennasModule from './AntennasModule';
import BuildingsModule from './BuildingsModule';
import CoverageModule from './CoverageModule';
import ExpositionModule from './ExpositionModule';
import GlobalReportModule from './GlobalReportModule';
import SensorDataModule from './SensorDataModule';
import SensorModule from './SensorModule';
import SessionModule from './Session/SessionModule';
import store from './store';

export const sessionModule = new SessionModule({
  name: 'session',
  store,
});

export const sensorModule = new SensorModule({
  name: 'sensor',
  store,
});

export const sensorDataModule = new SensorDataModule({
  name: 'sensorData',
  store,
});

export const antennasModule = new AntennasModule({
  name: 'antennas',
  store,
});

export const globalReportModule = new GlobalReportModule({
  name: 'globalReport',
  store,
});

export const buildingsModule = new BuildingsModule({
  name: 'buildings',
  store,
});

export const expositionModule = new ExpositionModule({
  name: 'exposition',
  store,
});

export const coverageModule = new CoverageModule({
  name: 'coverage',
  store,
});
