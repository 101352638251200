<div class="border mb-6 p-6 mx-6 rounded-md">
  <label v-if="!loadingBuildings" for="showBuildings" class="rounded-md text-sm flex items-center container-checkbox green">
    <input id="showBuildings" v-model="showBuildings" type="checkbox" />
    <span class="square-checkbox mr-4 ">
      <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
    </span>
    <span class="text-left">
      <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.view_buildings') }}</span>
    </span>
  </label>
  <div v-else class="rounded-md text-sm flex items-center">
    <span class="spinner spinner-light lg:mr-3 relative"><span></span><span></span><span></span><span></span></span>
    <span class="hidden lg:block">{{ $t('templates.components.components.Helpers.DsioPinFilter.loading_buildings') }}</span>
  </div>
</div>
