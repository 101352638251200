import { TooltipFormatterCallbackFunction } from 'highcharts';

export default {
  title: false,
  subtitle: false,
  exporting: {
    enabled: false,
  },
  chart: {
    type: 'column',
    borderWidth: 0,
    inverted: true,
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
      turboThreshold: 0,
    },
  },
  colors: ['#61d3ed'],
  xAxis: {
    title: {
      text: false,
    },
    labels: {
      y: 30,
      style: {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
  yAxis: {
    title: {
      text: false,
    },
    labels: {
      style: {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
  credits: {
    enabled: false,
  },
  lang: {},
  tooltip: {
    backgroundColor: '#ffffff',
    borderWidth: 0,
    borderRadius: 10,
    useHTML: true,
    headerFormat: '',
    // eslint-disable-next-line func-names
    formatter: function () {
      return `
        <div class="flex flex-col justify-center items-center">
          <p class="text-gray-lighter-text">
            <strong>${this.key}</strong>
          </p>
          <p class="font-light text-lg">${this.y} V/m</p>
        </div>
      `;
    } as TooltipFormatterCallbackFunction,
    style: {
      fontFamily: 'Open Sans, sans-serif',
    },
  },
  legend: {
    enabled: false,
    borderColor: '#004d40',
    backgroundColor: '#ffffff',
  },
};
