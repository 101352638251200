import Icon from '@/components/Helpers/Icon';
import { coverageModule, expositionModule, sessionModule } from '@/store';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/PinFilterExposition.vhtml';
import L from 'leaflet';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import { LMap } from 'vue2-leaflet';
import ExpositionLegend from './ExpositionLegend';

@Template
@Component({
  components: { Icon, ExpositionLegend, VueSlider },
})
export default class PinFilterExposition extends Vue {
  /**
   * The map to add pins.
   */
  @Prop({ type: Object, required: true })
  private map: LMap;

  /**
   * If loading exposition.
   */
  private loadingExposition = false;

  /**
   * If show exposition.
   */
  private showExposition = false;

  /**
   * If a request exposition is started.
   */
  private requestExpositionStarted = false;

  /**
   * If display exposition.
   */
  private displayExposition = false;

  /**
   * Current bounds of the map.
   */
  private currentBounds: L.LatLngBounds;

  /**
   * Exposition geojson drawn on the map.
   */
  private expositionGeoJson: L.GeoJSON | null = null;

  /**
   * Max value of the slider.
   */
  private minValue = 0;

  /**
   * On checkbox exposition change.
   */
  @Watch('showExposition')
  public onShowExpositionChange(value: boolean, oldValue: boolean) {
    if (value !== oldValue) {
      this.minValue = 0;
    }

    expositionModule.setExpositionActive(value);

    this.update(this.currentBounds);
  }

  /**
   * On coverage active change.
   * @param value
   */
  @Watch('coverageActive')
  public onCoverageActiveChange(value: boolean) {
    if (value) {
      this.showExposition = false;
    }
  }

  /**
   * Update exposition.
   * @param bounds
   */
  public async update(bounds: L.LatLngBounds) {
    this.currentBounds = bounds;

    if (this.showExposition) {
      this.loadingExposition = true;
      this.displayExposition = this.map.mapObject.getZoom() > 12;

      if (this.map.mapObject.getZoom() > 12 && !this.requestExpositionStarted) {
        const zoom = this.map.mapObject.getZoom();
        const nw = bounds.getNorthWest();
        const se = bounds.getSouthEast();

        this.requestExpositionStarted = true;

        const response = await expositionModule.getExposition({
          session: sessionModule,
          nw: {
            latitude: nw.lat,
            longitude: nw.lng,
          },
          se: {
            latitude: se.lat,
            longitude: se.lng,
          },
          zoom,
          min: this.minValue,
        });

        if (response !== null) {
          await this.redrawExposition();
        }

        this.requestExpositionStarted = false;
      } else {
        expositionModule.clearExposition();
        await this.redrawExposition();
      }

      this.loadingExposition = false;
    } else if (this.expositionGeoJson !== null) {
      this.expositionGeoJson.removeFrom(this.map.mapObject);
      this.expositionGeoJson = null;
    }
  }

  /**
   * Redraw exposition on the map.
   */
  public async redrawExposition() {
    return new Promise<void>((resolve) => {
      const rawGeojson = expositionModule.exposition;
      const oldExpositionGeoJson = this.expositionGeoJson;

      this.expositionGeoJson = new L.GeoJSON(rawGeojson as any, {
        style(feature) {
          return {
            color: feature?.properties.stroke ?? 'rgba(0, 0, 0, 0)',
            weight: feature?.properties.stroke ? 4 : 0,
          };
        },
        onEachFeature(feature, layer) {
          const popup = new L.Popup({}).setContent(feature.properties.popup);

          layer.bindPopup(popup);
        },
      });
      if (oldExpositionGeoJson !== null) {
        oldExpositionGeoJson.removeFrom(this.map.mapObject);
      }

      this.expositionGeoJson.addTo(this.map.mapObject);

      resolve();
    });
  }

  /**
   * On min value change.
   */
  public onMinValueChange() {
    this.update(this.currentBounds);
  }

  /**
   * Get exposition active.
   */
  public get coverageActive() {
    return coverageModule.coverageActive;
  }
}
