/**
 * Test si une variable est défini et différente de null
 *
 * @returns {boolean}
 */
export default function isset () {
  for (let variable of arguments) {
    if (typeof variable === 'undefined' || variable === null) {
      return false;
    }
  }
  return true;
}
