import Footer from '@/components/Layout/Footer';
import Header from '@/components/Layout/Header';
import { sensorDataModule, sensorModule, sessionModule } from '@/store';
import Template from '@Templates/App.vhtml';
import { Component, Vue } from 'vue-property-decorator';
import Loader from './components/Helpers/Loader';

@Template
@Component({
  components: {
    Header,
    Footer,
    Loader,
  },
})
export default class App extends Vue {
  /**
   * If app loaded.
   *
   * @private
   * @memberof App
   */
  private appLoaded = false;

  /**
   * If Google Maps loaded.
   *
   * @private
   * @memberof App
   */
  private googleMapsLoaded = false;

  /**
   * If show the big loader.
   *
   * @private
   * @memberof App
   */
  private showLoader = false;

  /**
   * Mounted event.
   *
   * @memberof App
   */
  async mounted() {
    this.registerLoaderEvent();
    this.showLoader = true;
    let hasLoaded = false;

    await sessionModule.tryLoadUser(async (user) => {
      const withSensorDatas = this.$router.currentRoute.name === 'detail-sensor';

      if (user === null && this.$router.currentRoute.name !== 'login') {
        await this.$router.replace({
          name: 'login',
        });
      } else if (user !== null && this.$router.currentRoute.name !== 'list-sensor' && this.$router.currentRoute.name !== 'detail-sensor') {
        await this.loadDatas(withSensorDatas);
        await this.$router.replace({
          name: 'list-sensor',
        });
        hasLoaded = true;
      } else if (user !== null) {
        await this.loadDatas(withSensorDatas);
        hasLoaded = true;
      }

      if (hasLoaded) {
        await sensorDataModule.getSensorDataLight(sessionModule);
      }

      if (this.googleMapsLoaded) {
        this.setAppLoaded();
      } else {
        this.createGoogleApiScript(async () => {
          this.googleMapsLoaded = true;
          window.googleMaps = new window.google.maps.places.PlacesService(document.getElementById('googlemaps-list'));

          this.setAppLoaded();
        });
      }
    });
  }

  /**
   * Register application root events.
   *
   * @private
   * @memberof App
   */
  private registerLoaderEvent() {
    this.$root.$on('showLoader', () => {
      this.showLoader = true;
    });

    this.$root.$on('hideLoader', () => {
      this.showLoader = false;
    });

    this.$root.$on('loader', () => {
      this.showLoader = !this.showLoader;
    });
  }

  /**
   * Load require datas.
   *
   * @private
   * @memberof App
   */
  private async loadDatas(withSensorDatas = false) {
    if (withSensorDatas) {
      await Promise.all([
        sessionModule.getUserAccount(),
        sensorModule.getSensors(sessionModule),
      ]);
    } else {
      await Promise.all([
        sessionModule.getUserAccount(),
        sensorModule.getSensors(sessionModule),
      ]);
    }
  }

  /**
   * Set the application fully loaded and hide loader.
   *
   * @private
   * @memberof App
   */
  private setAppLoaded() {
    this.appLoaded = true;
    this.showLoader = false;

    setTimeout(() => {
      this.$root.$emit('centerMapNoPin', sensorModule.sensors.map((s) => s.geolocation));
    }, 500);
  }

  /**
   * Create Google API script.
   *
   * @private
   * @param {() => void} scriptLoadedCallback
   * @memberof App
   */
  private createGoogleApiScript(scriptLoadedCallback: () => void) {
    const elementScript = document.getElementById('googlemaps');

    // Already created.
    if (elementScript === null) {
      const script = document.createElement('script');

      script.async = true;
      script.type = 'text/javascript';
      script.src = `${process.env.VUE_APP_API_GOOGLE_MAPS_URL}?key=${process.env.VUE_APP_API_GOOGLE_MAPS_TOKEN}&libraries=places`;
      script.id = 'googlemaps';
      script.addEventListener('load', scriptLoadedCallback.bind(this));

      document.body.appendChild(script);
    }
  }

  /**
   * If a user is logged in.
   *
   * @readonly
   * @private
   * @memberof App
   */
  private get isLogged() {
    return sessionModule.isUserAlreadyLogged;
  }
}
