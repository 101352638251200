<div class="border mb-6 p-6 mx-6 rounded-md grid grid-flow-row gap-6">
  <label v-if="!loadingCoverage" for="showCoverage" class="rounded-md text-sm flex items-center container-checkbox green">
    <input id="showCoverage" v-model="showCoverage" type="checkbox" />
    <span class="square-checkbox mr-4 ">
      <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
    </span>
    <span class="text-left">
      <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.view_coverage') }}</span>
    </span>
  </label>
  <div v-else class="rounded-md text-sm flex items-center">
    <span class="spinner spinner-light lg:mr-3 relative"><span></span><span></span><span></span><span></span></span>
    <span class="hidden lg:block">{{ $t('templates.components.components.Helpers.DsioPinFilter.loading_coverage') }}</span>
  </div>

  <div class="border-t" v-if="expositionActive || (showCoverage && !displayCoverage)"></div>

  <div v-if="expositionActive">
    <p class="text-sm text-gray-light-text flex items-center font-bold">
      <span class="text-light-blue mr-4">
        <Icon name="exclamation" :width="20" :height="20" />
      </span>
      {{ $t('templates.components.components.Helpers.DsioPinFilter.coverage_exposition_selected') }}
    </p>
  </div>
  <div v-else-if="showCoverage && !displayCoverage">
    <p class="text-sm text-gray-light-text flex items-center font-bold">
      <span class="text-light-blue mr-4">
        <Icon name="exclamation" :width="20" :height="20" />
      </span>
      {{ $t('templates.components.components.Helpers.DsioPinFilter.ask_zoom_coverage') }}
    </p>
  </div>

  <div class="border-t" v-if="showCoverage && displayCoverage && !loadingCoverage"></div>

  <div v-if="showCoverage && displayCoverage && !loadingCoverage">
    <label for="operator_orange" class="pb-6 text-sm text-gray-light-text flex items-center gap-4 container-radio">
      <img src="@Assets/img/operators/logo-orange.png" alt="Orange" class="w-8 h-8">
      <div class="flex items-center gap-3">
        <input id="operator_orange" v-model="operator" type="radio" value="orange" />
        <span class="radio"></span>
        <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter.orange') }}</span>
      </div>
    </label>

    <label for="operator_free" class="pb-6 text-sm text-gray-light-text flex items-center gap-4 container-radio">
      <img src="@Assets/img/operators/logo-free.png" alt="Free" class="w-8 h-8 object-contain">
      <div class="flex items-center gap-3">
        <input id="operator_free" v-model="operator" type="radio" value="free" />
        <span class="radio"></span>
        <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter.free') }}</span>
      </div>
    </label>

    <label for="operator_sfr" class="pb-6 text-sm text-gray-light-text flex items-center gap-4 container-radio">
      <img src="@Assets/img/operators/logo-sfr.png" alt="SFR" class="w-8 h-8">
      <div class="flex items-center gap-3">
        <input id="operator_sfr" v-model="operator" type="radio" value="sfr" />
        <span class="radio"></span>
        <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter.sfr') }}</span>
      </div>
    </label>

    <label for="operator_bouygues" class="text-sm text-gray-light-text flex items-center gap-4 container-radio">
      <img src="@Assets/img/operators/logo-bouygues.png" alt="Bouygues" class="w-8 h-8">
      <div class="flex items-center gap-3">
        <input id="operator_bouygues" v-model="operator" type="radio" value="bouygues" />
        <span class="radio"></span>
        <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter.bouygues') }}</span>
      </div>
    </label>
  </div>

  <div class="border-t" v-if="showCoverage && displayCoverage && !loadingCoverage"></div>

  <ExpositionLegend type="coverage" :legend="$t('templates.components.components.Helpers.ExpositionLegend.label_coverage')" v-if="showCoverage && displayCoverage && !loadingCoverage" />
</div>
