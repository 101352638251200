<template>
  <div v-click-outside="closePopup" class="DateFilter filterWrapper" :class="{ open: popupOpen }">
    <div class="lgrid-noGutter-noWrap title-wrapper">
      <button class="title" :class="{'active': isActive}" @click="openPopup">
        <span v-html="placeholder" />
      </button>
    </div>
    <div class="filter--popup">
      <date-picker
        v-if="popupOpen"
        v-model="date"
        :placeholder="placeholder"
        :mode="mode"
        :options="conf.calendarOptions"
        :forceRange="conf.forceRange"
        @apply="apply"
        @close="closePopup"
      />
    </div>
  </div>
</template>

<script>
  import { isequal } from 'lodash';
  import { isset, isEmpty } from '@Diffusio/js/utils';
  import { parseISO, differenceInDays } from 'date-fns';
  import baseEngines from '@Diffusio/js/components/engines/baseEngines';
  import DatePicker from '@Diffusio/js/components/DatePicker';

  export default {
    name: 'DateFilter',
    components: { DatePicker },
    mixins: [baseEngines],
    props: {
      requestValue: {
        type: Object,
        default: () => {},
      },
      name: {
        type: String,
        default: '',
      },
      conf: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        libelle: null,
        date: null,
        dateFormat: 'yyyy-MM-dd', // Format pour la requête
        displayDateFormat: 'd MMM', // Format pour l'affichage
        mode: this.conf.mode || 'range',
      };
    },
    computed: {
      isActive() {
        // Retourne true si la date est définie
        return !!isset(this.date);
      },
      placeholder() {
        return (
          (this.libelle || this.displayDate || this.name) +
          '<i class="icon-calendar dsio-calendar-check-light" />'
        );
      },
      displayDate() {
        if (this.date !== null) {
          // Date start en mode range ou juste date en mode single
          const start = this.date.start || this.date;
          let formated =
            '<span class="date">' +
            this.I18nDate().format(start, this.displayDateFormat) +
            '</span>';
          // Si on est en mode range, que date end est défini et différent de date start
          if (
            this.mode === 'range' &&
            isset(this.date.end) &&
            differenceInDays(this.date.end, this.date.start) !== 0
          ) {
            formated += '<i class="dsio-arrows-h-light"></i>';
            formated +=
              '<span class="date">' +
              this.I18nDate().format(this.date.end, this.displayDateFormat) +
              '</span>';
          }
          return formated;
        }
        return null;
      },
      currentDate() {
        if (!isEmpty(this.filterData)) {
          let [, start, end] = this.filterData.split('~');
          if (this.mode === 'range') {
            return { start: parseISO(start), end: parseISO(end) };
          }
          return parseISO(start);
        }
        return null;
      },
    },
    watch: {
      filterData(current, old) {
        if (old !== null) {
          if (isEmpty(current)) {
            this.date = null;
          }
        }
      },
    },
    created() {
      // If requestValue is not empty
      if (this.requestValue !== null) {
        this.parseRequestValue(this.requestValue);
      }
    },
    methods: {
      /**
       * Parse les donnée et les applique à l'état courant.
       * @param requestValue
       */
      parseRequestValue(requestValue) {
        const { libelle, start, end } = requestValue;
        this.libelle = libelle;
        // Parse de la date en instance de Date pour le calendrier
        const parsedDate = this.parseRequestDate({ start, end });
        if (this.mode === 'range' && isset(end)) {
          this.date = parsedDate;
        } else {
          this.date = parsedDate.start;
        }
        this.updateFilter([...this.formatDateFilter(parsedDate)]);
      },
      parseRequestDate({ start, end }) {
        start = parseISO(start);
        if (this.mode === 'range' && isset(end)) {
          return { start, end: parseISO(end) };
        }
        return { start };
      },
      formatDateFilter(date) {
        let dataFilter = [];

        // Si une date est défini on met à jour le filtre
        if (isset(date)) {
          // Si on est en mode range
          if (this.mode === 'range') {
            dataFilter = [
              this.I18nDate().format(date.start, this.dateFormat),
              this.I18nDate().format(date.end, this.dateFormat),
            ];
          } else {
            dataFilter = [this.I18nDate().format(date, this.dateFormat)];
          }
        }
        return dataFilter;
      },
      updateFilter(data) {
        if (!isEmpty(data)) {
          /**
           * Ajoute le libelle aux début des données
           * Si libelle est null on prend un string vide
           */
          data.unshift(this.libelle || '');
          this.filterData = data.join('~');
          this.updateContext([{ id: this.filterData, name: this.displayDate }]);
        } else {
          this.filterData = '';
          this.updateContext('');
        }
      },
      /**
       * Fermeture de la popup
       */
      closePopup() {
        // Si le changement n'a pas été appliquer on restaure l'état du composant
        if (!isequal(this.currentDate, this.date)) {
          this.date = this.currentDate;
        }
        this.popupOpen = false;
      },
      /**
       * Applique les données du filtres
       */
      apply() {
        this.updateFilter(this.formatDateFilter(this.date));
        this.closePopup();
      },
    },
  };
</script>
