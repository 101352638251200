<template>
  <!-- list-container -->
  <div>
    <div
      ref="container"
      :class="{
        'grid-cols-1': isMapActive,
        'grid-cols-1 md:grid-cols-2 lg:grid-cols-3': !isMapActive,
      }"
      class="grid gap-2"
      v-if="!isEmpty"
    >
      <a
        class="no-hover"
        :href="detailLink(item)"
        v-for="(item, id) in list"
        :key="id"
        @click="(e) => showPopup(e, item)"
      >
        <div
          class="border bg-white rounded-md grid grid-cols-4 h-32 transition-shadow duration-300 hover:shadow-md cursor-pointer dsio-item-list overflow-hidden"
          @mouseenter="highlightItem(id)"
          @mouseleave="highlightItem(null)"
        >
          <div class="col-span-1 relative">
            <img
              :src="getImage(item.picture_url)"
              @error="onImgError"
              class="rounded-l-md block w-full h-32 object-cover"
            />
            <img
              src="@Assets/img/map/pin-sensor-light.svg"
              class="absolute top-1/2 left-1/2 w-10 h-12 z-10 object-contain transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
          <div class="col-span-3 flex flex-col justify-center px-8 relative">
            <p class="font-semibold">Site #{{ item.name }}</p>
            <p class="text-sm">
              {{ item.address.location }}
              <br />
              {{ item.address.zip }} {{ item.address.city }} ({{
                item.address.country
              }})
            </p>
            <p
              v-if="item.status === 'MAINTENANCE'"
              class="text-sm text-gray-light-text pt-2 opacity-50"
            >
              {{ $t('diffusio.components.DsioList.maintenance') }}
            </p>
            <p
              v-if="item.status === 'OFFLINE'"
              class="text-sm text-gray-light-text pt-2 opacity-50"
            >
              {{ $t('diffusio.components.DsioList.offline') }}
            </p>
            <p
              v-else-if="item.current_value !== null"
              class="text-sm text-gray-light-text opacity-50"
              :class="{ 'pt-2': item.status === 'ONLINE' }"
            >
              {{ $t('diffusio.components.DsioList.last_measure') }}
              <strong class="font-bold">
                {{ item.current_value }} {{ item.unit }}
              </strong>
            </p>
            <Icon
              name="chevron-right"
              :width="6"
              :height="12"
              class="absolute right-6 top-1/2 transform -translate-y-1/2 text-light-green opacity-0 transition-opacity duration-300 dsio-item-list__chevron"
            />
          </div>
        </div>
      </a>
    </div>
    <div v-else class="flex flex-col mb-20 md:mb-0">
      <div
        class="rounded-md lg:mx-8 bg-border-gray-light border border-dashed py-8 text-center"
      >
        <p>{{ $t('diffusio.components.DsioList.no_sensor') }}</p>
        <p>{{ $t('diffusio.components.DsioList.no_sensor_in_zone') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Helpers/Icon';
  import imgDefaultSensor from '@Assets/img/default_sensor.jpg';

  export default {
    name: 'DsioList',
    components: { Icon },
    props: {
      list: {
        type: [Object, Array],
        default: () => {},
      },
      loading: {
        type: Boolean,
        default: true,
      },
      isMapActive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        classItem: null,
        isEmpty: false,
      };
    },
    watch: {
      list(list) {
        // toggle empy class
        if (Object.keys(list).length <= 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }
      },
    },
    mounted() {
      // const container = this.$refs['container'];
      // if (container.children.length > 0) {
      //   this.classItem = [...container.firstElementChild.classList, 'list-item'];//toujours avoir list-item
      // }
    },
    methods: {
      getImage(images) {
        if (images && images.length > 0 && images[0].length > 0) {
          return images[0];
        }

        return imgDefaultSensor;
      },
      detailLink(item) {
        const route = this.$router.resolve({
          name: 'detail-sensor',
          params: { id: item.id },
        });

        return route.href;
      },
      highlightItem(id) {
        if (id !== 'empty') {
          this.dispatch('highlightItem', id);
        }
      },
      showPopup(e, item) {
        if (!e.ctrlKey) {
          e.preventDefault();

          this.$root.$emit('popup-select-sensor', item.id);
        }
      },
      openDetail(oiId, ev) {
        if (oiId.substring(0, 3) !== 'TFO') {
          return;
        }

        let prms = {};
        let popupOpen = this.app.params.detailPopup;
        let href = null;

        // récupération du lien dans le dom
        // el avec class dsio-detail-button
        const $link = document
          .getElementsByClassName('list-item-' + oiId)[0]
          .getElementsByClassName('dsio-detail-button')[0];

        // vérification du lien
        if (typeof $link !== 'undefined') {
          href = $link.getAttribute('href');

          // ouverture hors popup pour les liens http
          if (href.indexOf('http') === 0) {
            popupOpen = false;
          }
        }

        // empecher le clic car gestion manuelle
        ev.preventDefault();

        if (popupOpen) {
          this.commit('UPDATE_DETAIL', { oiId, prms });
        } else if (href) {
          $link.getAttribute('target') === '_blank'
            ? window.open(href)
            : (window.location.href = href);
        }
      },
      onImgError(e) {
        e.target.src = imgDefaultSensor;
      },
    },
  };
</script>
