import { Antenna, SiteAntenna } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import Template from '@Templates/components/Page/DetailSensor/EnvironnementTabsAccordion.vhtml';
import { uniq } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  VsaContent, VsaHeading, VsaIcon, VsaItem, VsaList,
} from 'vue-simple-accordion';

@Template
@Component({
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
    Icon,
  },
})
export default class EnvironnementTabsAccordion extends Vue {
  @Prop({
    required: true,
  })
  private antenna: SiteAntenna;

  /**
   * Options for the accordion.
   *
   * @readonly
   * @private
   * @memberof EnvironnementTabsAccordion
   */
  private get accordionTags() {
    return {
      heading__content: 'div',
    };
  }

  /**
   * Compute list of technos.
   *
   * @private
   * @param {Antenna} antenna
   * @returns {string}
   * @memberof EnvironnementTabsAccordion
   */
  private getListTechnosAntenna(antenna: Antenna): string {
    return uniq(antenna.technos).reverse().join(' / ');
  }
}
