<div class="grid grid-flow-row gap-2 text-sm">
  <p>{{ legend }}</p>
  <div :style="{'background-size': '400% 400%'}" class="h-2 rounded-full w-full relative bg-gradient-to-r from-light-blue to-light-green animate-gradient overflow-hidden">
    <div :style="{'background': gradient}" class="absolute h-full w-full"></div>
  </div>
  <div class="flex justify-between">
    <div v-for="milestone in milestonesLabels" class="flex flex-col justify-center items-center gap-1 text-xs" :key="milestone">
      <Icon name="chevron-up" :width="10" :height="10" />
      <span>{{ milestone }}</span>
    </div>
  </div>
</div>
