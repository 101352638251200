var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('l-map',{ref:"DsioMap",staticClass:"rounded-md lg:rounded-none",attrs:{"bounds":_vm.boundsValue,"options":_vm.mapOptions},on:{"update:bounds":_vm.debouncedBounds,"ready":_vm.mapReady}},[_c('l-tile-layer',{attrs:{"url":_vm.tileLayer.url}}),_vm._l((_vm.layers),function(layer,key){return _c('l-geo-json',{key:key,attrs:{"geojson":layer.geoJson,"options":layer.options,"optionsStyle":layer.style}})}),(_vm.tileLayer.attribution && _vm.tileLayer.attribution !== '')?_c('l-control-attribution',{attrs:{"position":"bottomleft","prefix":_vm.tileLayer.attribution}}):_vm._e(),(_vm.conf.useClusters)?_c('v-marker-cluster',{attrs:{"options":_vm.conf.markerClusterOptions}},_vm._l((_vm.mapItems),function(item,key){return _c('l-marker',{key:key,attrs:{"lat-lng":item.marker,"icon":_vm.conf.usePriceMarkers || _vm.conf.useDivMarkers
            ? _vm.getDivIcon(item)
            : _vm.getIcon(key, item)},on:{"click":function () {
            _vm.showDetailSensor(key);
          }}})}),1):_vm._e(),(!_vm.conf.useClusters)?_vm._l((_vm.mapItems),function(item,key){return _c('l-marker',{key:key,attrs:{"lat-lng":item.marker,"icon":_vm.conf.usePriceMarkers || _vm.conf.useDivMarkers
            ? _vm.getDivIcon(item)
            : _vm.getIcon(key, item)}})}):_vm._e(),(_vm.itemHighlighted && _vm.markerHighlited)?_c('l-marker',{attrs:{"lat-lng":_vm.markerHighlited,"options":{ zIndexOffset: 1000 },"icon":_vm.conf.usePriceMarkers || _vm.conf.useDivMarkers
          ? _vm.getDivIconHover(_vm.mapItems[_vm.itemHighlighted])
          : _vm.getIconHover(_vm.itemHighlighted, _vm.mapItems[_vm.itemHighlighted])}}):_vm._e(),(_vm.userPosition)?_c('l-marker',{attrs:{"lat-lng":_vm.userPosition,"icon":_vm.iconUser,"options":{ interactive: false }}}):_vm._e(),(!_vm.isMobileScreen)?_c('l-control-zoom',{attrs:{"options":Object.assign({}, _vm.conf.zoomControlOption)}}):_vm._e()],2),_c('div',{staticClass:"map-popup-outside-wrapper",class:{ open: _vm.isSmallScreen && _vm.mapPopupContent }},[_c('div',{staticClass:"map-popup-outside",domProps:{"innerHTML":_vm._s(_vm.mapPopupContent)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }