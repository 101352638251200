import { Account } from '@/api/SensorModels';
import { ALL_LOCALES, setLocale } from '@/helpers/MiscHelpers';
import { sessionModule } from '@/store';
import Template from '@Templates/components/SideMenu.vhtml';
import { Component, Vue } from 'vue-property-decorator';
import Dropdown from '../Helpers/Dropdown';
import Icon from '../Helpers/Icon';

@Template
@Component({
  components: {
    Icon,
    Dropdown,
  },
})
export default class SideMenu extends Vue {
  /**
   * User account.
   *
   * @readonly
   * @private
   * @memberof Header
   */
  private get userAccount(): Account | null {
    return sessionModule.userAccount;
  }

  /**
   * Get available locales.
   *
   * @readonly
   * @private
   * @memberof SideMenu
   */
  private get availableLocales() {
    return ALL_LOCALES;
  }

  /**
   * Sets the locale.
   * @param locale the locale
   */
  private setLocale(locale: string) {
    this.$i18n.locale = locale;
    setLocale(locale);

    this.$root.$emit('showLoader');
  }
}
