<div>
  <ul class="hidden lg:flex">
    <li class="flex items-center">
      <Dropdown icon="bell" class="px-3">
        <li>
          <p class="px-5 py-3 text-sm text-gray-light-text text-center">
            {{ $t('templates.components.SideMenu.alarm_configuration') }}
          </p>
        </li>
        <li>
          <hr class="h-px bg-light-gray w-full">
        </li>
        <li v-if="userAccount" class="py-3">
          <p class="px-5 text-sm mb-1 text-gray-light-text text-center">
            {{ $t('templates.components.SideMenu.high_threshold') }} {{ userAccount.alarm_max }}% {{ $t('templates.components.SideMenu.of_avg_value') }}
          </p>
          <p class="px-5 text-sm text-gray-light-text text-center">
            {{ $t('templates.components.SideMenu.lower_threshold') }} {{ userAccount.alarm_min }}% {{ $t('templates.components.SideMenu.of_min_value') }}
          </p>
        </li>
      </Dropdown>
    </li>

    <li class="flex items-center">
      <Dropdown icon="user" class="px-3">
        <li v-if="userAccount !== null">
          <p class="px-5 py-3 text-sm italic text-gray-light-text text-center">
            {{ userAccount.contact_name }} - {{ userAccount.name }}
          </p>
        </li>
        <li>
          <hr class="h-px bg-light-gray w-full">
        </li>
        <li>
          <router-link :to="{ name: 'logout' }" class="px-5 py-3 flex justify-center">
            <span class="mr-2">
              <Icon name="logout" />
            </span>
            <span class="text-sm">{{ $t('templates.components.SideMenu.logout') }}</span>
          </router-link>
        </li>
      </Dropdown>
    </li>

    <li class="flex items-center">
      <Dropdown icon="globe-lang" :chevron="true">
        <li class="px-5 py-3 flex justify-center" v-for="locale in availableLocales" :key="locale.slug">
          <button :class="{'text-light-blue': $i18n.locale.includes(locale.slug)}" type="button" @click="setLocale(locale.slug)">
            {{ locale.name }}
          </button>
        </li>
      </Dropdown>
    </li>
  </ul>

  <ul class="flex lg:hidden">
    <li>
      <Dropdown icon="globe-lang" :chevron="true">
        <li class="px-5 py-3 flex justify-center" v-for="locale in availableLocales" :key="locale.slug">
          <button :class="{'text-light-blue': $i18n.locale.includes(locale.slug)}" type="button" @click="setLocale(locale.slug)">
            {{ locale.name }}
          </button>
        </li>
      </Dropdown>
    </li>

    <li class="flex items-center">
      <Dropdown icon="bars" :chevron="false">
        <li v-if="userAccount !== null">
          <p class="px-5 py-3 text-sm italic text-gray-light-text text-center">
            {{ userAccount.contact_name }} - {{ userAccount.name }}
          </p>
        </li>
        <li>
          <hr class="h-px bg-light-gray w-full">
        </li>
        <li>
          <router-link :to="{ name: 'list-sensor' }" class="px-5 py-3 flex">
            <span class="text-sm">{{ $t('templates.components.SideMenu.sensor_list') }}</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'global-report' }" class="px-5 py-3 flex">
            <span class="text-sm">{{ $t('templates.components.SideMenu.analysis') }}</span>
          </router-link>
        </li>
        <li>
          <hr class="h-px bg-light-gray w-full">
        </li>
        <li>
          <a href="#" class="px-5 py-3 flex">
            <span class="mr-2">
              <Icon name="bell" />
            </span>
            <span class="text-sm">{{ $t('templates.components.SideMenu.notifications') }}</span>
          </a>
        </li>
        <li>
          <router-link :to="{ name: 'logout' }" class="px-5 py-3 flex">
            <span class="mr-2">
              <Icon name="logout" />
            </span>
            <span class="text-sm">{{ $t('templates.components.SideMenu.logout') }}</span>
          </router-link>
        </li>
      </Dropdown>
    </li>
  </ul>
</div>
