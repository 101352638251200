import { capitalize, isEmpty, mergeDeep } from '@Diffusio/js/utils';
import filterStore from './filter';
import Request from './request';

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state() {
    return {
      instanceId: '',
      listFilters: {},
      app: {
        id: 'id1',
        params: {
          rootUrl: '',
          requestUrl: '',
          defaultViewMod: null,
          viewMods: {},
          pageQty: 0,
          lang: 'fr',
          // List config
          listConfig: null,
          listConfigFile: null,
          // Sort config
          sortConfig: null,
          sortConfigFile: null,
          sortPrefix: null,
          defaultSortBy: null,
          // Filter config
          filterConfig: null,
          filterConfigFile: null,
          oiID: null,
        },
        state: {
          savedMapBounds: null,
          currentPage: 1,
          viewMod: null,
          nbPages: 0,
          sortBy: null,
          total: 0,
          // oiId: null,
          detail: {
            oiId: null,
            prms: {},
          },
        },
      },
      moreFilters: false,
      itemHighlighted: '',
      userPosition: null,
    };
  },
  getters: {
    ready(state) {
      // If all filters is true
      return Object.values(state.listFilters).indexOf(false) === -1;
    },
    app(state) {
      const app = { ...state.app };

      let appParams = {};

      for (let params in app.params) {
        if (app.params.hasOwnProperty(params)) {
          let value = app.params[params];

          if (params === 'rootUrl' || !isEmpty(value, true)) {
            appParams[params] = value;
          }
        }
      }
      app.params = appParams;

      return app;
    },
    /**
     * Récupère les données d'état filtré pour les requête ajax
     *
     * @param state
     * @param getters
     * @returns {Object}
     */
    requestParams(state, getters) {
      // Filtre les données d'état pour les requête ajax
      let requestState = {};
      // NE PAS METTRE viewMod car SEO
      ['currentPage', 'sortBy'].forEach((key) => {
        let defaultValue = state.app.params[key] || state.app.params['default' + capitalize(key)];
        let value = state.app.state[key] || null;
        if (value !== null && value !== defaultValue) {
          requestState[key] = value;
        }
      });
      // Retourne les paramètres de la requête
      return { ...requestState, ...getters['Request/params'] };
    },
  },
  mutations: {
    SET(state, { instanceId, app }) {
      state.instanceId = instanceId;
      state.app = mergeDeep(state.app, app);
    },
    UPDATE(state, app) {
      state.app = mergeDeep(state.app, app);
    },
    UPDATE_USER_POSITION(state, data) {
      state.userPosition = data;
    },
    ADD_FILTER(state, name) {
      let filters = { ...state.listFilters };
      filters[name] = false;
      state.listFilters = filters;
    },
    REMOVE_FILTER(state, name) {
      let filters = { ...state.listFilters };
      delete filters[name];
      state.listFilters = filters;
    },
    FILTER_READY(state, name) {
      let filters = { ...state.listFilters };
      filters[name] = true;
      state.listFilters = filters;
    },
    HIGHLIGHT_ITEM(state, id) {
      state.itemHighlighted = id;
    },
    UPDATE_SORT(state, id) {
      state.app.state.sortBy = id;
    },
    UPDATE_MOD(state, name) {
      state.app.state.viewMod = name;
    },
    UPDATE_CURRENT_PAGE(state, number) {
      state.app.state.currentPage = number;
    },
    UPDATE_MORE_FILTERS(state, enabled) {
      state.moreFilters = enabled;
    },
    UPDATE_DETAIL(state, data) {
      state.app.state.detail = Object.assign({}, state.app.state.detail, data);
      // state.app.state.oiId = data.oiId;
    },
    UPDATE_SAVED_MAP_BOUNDS(state, data) {
      state.app.state.savedMapBounds = data;
    },
  },
  actions: {
    setInstance({ commit }, { instanceId, app }) {
      commit('SET', { instanceId, app });
    },
    updateSavedMapBounds({ commit }, bounds) {
      commit('UPDATE_SAVED_MAP_BOUNDS', bounds);
    },
    updateInstance({ commit }, app) {
      commit('UPDATE', app);
    },
    addFilter({ commit, state }, id) {
      // Register Store filter
      this.registerModule([state.instanceId, 'Request', id], filterStore);
      commit('ADD_FILTER', id);
    },
    removeFilter({ commit, state }, id) {
      // Register Store filter
      this.unregisterModule([state.instanceId, 'Request', id]);
      commit('REMOVE_FILTER', id);
    },
    filterReady({ commit }, name) {
      commit('FILTER_READY', name);
    },
    highlightItem({ commit }, id) {
      commit('HIGHLIGHT_ITEM', id);
    },
    updateMod({ commit, dispatch }, name) {
      commit('UPDATE_MOD', name);
      commit('UPDATE_CURRENT_PAGE', 1);
      // Si mode list supprime le filtre geo
      if (name === 'list') {
        dispatch('Request/geo/remove', null);
      }
    },
    updateSort({ commit }, id) {
      commit('UPDATE_SORT', id);
      commit('UPDATE_CURRENT_PAGE', 1);
    },
    updateCurrentPage({ commit }, number) {
      commit('UPDATE_CURRENT_PAGE', number);
    },
    resetFilters({ state, dispatch, commit }) {
      for (let filterId in state.listFilters) {
        if (filterId !== 'u') {
          commit('Request/' + filterId + '/UPDATE', '');
          commit('Request/' + filterId + '/UPDATE_CONTEXT', []);
        }
      }
      commit('UPDATE_CURRENT_PAGE', 1);
    },
    updateFilter({ commit, state, getters }, filter) {
      commit('Request/' + filter.filterId + '/UPDATE', filter.value);
      if (getters['ready']) {
        commit('UPDATE_CURRENT_PAGE', 1);
      }
    },
    removeValueFilter({ dispatch, commit }, filter) {
      dispatch('Request/' + filter.filterId + '/remove', filter.value);
      commit('UPDATE_CURRENT_PAGE', 1);
    },
    updateUserPosition({ commit, state, getters }, { userPosition, sort }) {
      commit('UPDATE_USER_POSITION', userPosition || null);
      commit('Request/u/UPDATE', userPosition ? userPosition.join('~') + '~0~me' : '');

      commit('UPDATE_SORT', sort || state.app.params.defaultSortBy);
      commit('UPDATE_CURRENT_PAGE', 1);
    },
  },
  modules: {
    Request,
  },
};
