<div class="container mx-auto px-4 py-6">
  <div class="bg-medium-gray rounded md:h-16 flex flex-col md:flex-row items-center py-4 md:py-0 px-4">
    <div class="flex items-center justify-center bg-white border rounded-full py-2 px-5 text-light-blue">
      <Icon name="calendar" :width="16" :heigh="22" class="mr-3" />
      <v-date-picker v-model="dateMin" :max-date="dateMax" :min-date="minDate" :locale="$i18n.locale">
        <template v-slot="{ inputValue, inputEvents }">
          <p v-on="inputEvents">{{ inputValue }}</p>
        </template>
      </v-date-picker>
      <div class="w-px h-6 bg-border-gray mx-3"></div>
      <v-date-picker v-model="dateMax" :max-date="maxDate" :min-date="dateMin" :locale="$i18n.locale">
        <template v-slot="{ inputValue, inputEvents }">
          <p v-on="inputEvents">{{ inputValue }}</p>
        </template>
      </v-date-picker>
    </div>

    <div class="relative ml-0 md:ml-6 mt-5 md:mt-0">
      <a
        href="#"
        @click.prevent.stop="toggleDropdown"
        :data-active="dropdownOpen.toString()"
        class="flex h-full items-center justify-center relative flex-grow-0"
      >
        <span class="mr-3">{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.tags') }}</span>
        <Icon :width="8" :height="8" name="chevron-down" />
      </a>
      <div
        v-show="dropdownOpen"
        class="bg-white z-10 min-w-max shadow rounded-lg absolute left-0 transform origin-top-left top-full animate-zoom"
        @click.stop
      >
        <ul>
          <li>
            <label
              for="selectAll"
              class="mx-5 my-3 text-sm text-gray-light-text flex items-center container-checkbox"
            >
              <input id="selectAll" v-model="allSelected" type="checkbox"/>
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left font-bold">{{ selectAllLabel }}</span>
            </label>
          </li>
          <li
            v-for="(tag, i) in session.account.tags"
            :key="i"
            class="facetFilterCheckbox"
          >
            <label
              :for="tag.slug"
              class="mx-5 my-3 text-sm text-gray-light-text flex items-center container-checkbox"
            >
              <input :id="tag.slug" v-model="selectedTags" type="checkbox" :value="tag.name" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left">{{ tag.name }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="relative mt-4" style="min-height: 14rem;">
    <transition name="fade">
      <div v-if="globalReportData !== null">
        <div class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-4">
          <router-link title="Voir le capteur" :to="{ name: 'detail-sensor', params: { id: globalReportData.max.site_id } }" target="_blank" class="no-hover border rounded py-6 px-6 flex justify-around transition-colors duration-300 hover:bg-border-gray-light">
            <div class="flex items-center justify-center">
              <Icon name="chart-max" :width="34" :height="42" class="text-light-blue" />
            </div>
            <div class="flex-grow text-center">
              <p class="text-light-blue text-2xl font-semibold">{{ globalReportData.max.max }} V/m</p>
              <p>{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.max') }}</p>
            </div>
            <div class="flex items-center justify-center">
              <Icon name="external-link" :width="15" :height="15" class="text-gray-lighter-text" />
            </div>
          </router-link>

          <div class="no-hover border rounded py-6 px-6 flex justify-around hover:bg-border-gray-light">
            <div class="flex items-center justify-center">
              <Icon name="chart-moy" :width="34" :height="42" class="text-light-blue" />
            </div>
            <div class="flex-grow text-center">
              <p class="text-light-blue text-2xl font-semibold">{{ globalReportData.avg_max }} V/m</p>
              <p>{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.avg_max') }}</p>
            </div>
            <div class="flex items-center justify-center">
            </div>
          </div>

          <div class="no-hover border rounded py-6 px-6 flex justify-around hover:bg-border-gray-light">
            <div class="flex items-center justify-center">
              <Icon name="bell-light" :width="34" :height="42" class="text-light-blue" />
            </div>
            <div class="flex-grow text-center">
              <p class="text-light-blue text-2xl font-semibold">{{ globalReportData.alarms }}</p>
              <p>{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.events') }}</p>
            </div>
            <div class="flex items-center justify-center">
            </div>
          </div>

          <div class="no-hover border rounded py-6 px-6 flex justify-around hover:bg-border-gray-light">
            <div class="flex items-center justify-center">
              <Icon name="pin-underline" :width="34" :height="42" class="text-light-blue" />
            </div>
            <div class="flex-grow text-center">
              <p class="text-light-blue text-2xl font-semibold">{{ globalReportData.atypical_sites_count }}</p>
              <p>{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.atypical_sites') }}</p>
            </div>
            <div class="flex items-center justify-center">
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-6">
          <div class="border p-5 lg:p-10 lg:pr-14 mb-6 rounded">
            <highcharts :options="optionsChart1"></highcharts>
          </div>
          <div class="border p-5 lg:p-10 lg:pr-14 mb-6 rounded">
            <highcharts :options="optionsChart2"></highcharts>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row justify-center items-center">
          <form v-if="globalReportData.report_url.length > 0 && sessionToken !== null" :action="globalReportData.report_url" method="post" target="_blank">
            <input type="hidden" name="token" :value="sessionToken">
            <button type="submit" class="button-light-blue no-hover mb-4 lg:mx-2 lg:mb-0">{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.download_data') }}</button>
          </form>
          <button type="button" @click="generatePDF" class="button-light-blue-full no-hover lg:mx-2">{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.generate_pdf_report') }}</button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="needRefresh || loading" class="absolute inset-0 z-1003 blur bg-black-overlay rounded-md flex items-start p-6 lg:items-center justify-center">
        <div class="bg-white rounded-md py-6 px-12 text-center">
          <div v-if="needRefresh && !loading">
            <p class="text-lg font-semibold mb-6">{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.modified_filters') }}</p>
            <p class="mb-6">{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.filters_are_modified') }}</p>
            <button class="button-orange" @click="loadDatas">
              {{ $t('templates.components.components.Page.GlobalReport.GlobalReport.refresh') }}
            </button>
          </div>
          <div v-else-if="loading">
            <p class="text-lg font-semibold">{{ $t('templates.components.components.Page.GlobalReport.GlobalReport.loading') }}</p>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <VueHtml2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :paginate-elements-by-height="1600"
    :filename="pdfName"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="landscape"
    pdf-content-width="100%"
    @startPagination="$root.$emit('showLoader')"
    @hasDownloaded="$root.$emit('hideLoader')"
    :html-to-pdf-options="htmlToPdfOptions"
    ref="html2Pdf">
      <section slot="pdf-content">
        <GlobalReportPDF :titleChart1="titleChart1PDF" :titleChart2="titleChart2PDF" :tags="selectedTags" :dates="datesDayJS" :now="now" :optionsChart1="optionsChart1" :optionsChart2="optionsChart2"/>
      </section>
  </VueHtml2pdf>
</div>
