<script>
  import DsioReset from '@Diffusio/js/components/DsioReset';
  import DsioMore from '@Diffusio/js/components/DsioMore';
  import DsioCloseFilters from '@Diffusio/js/components/DsioCloseFilters';

  export default {
    props: {
      filters: {
        type: Object,
        default: () => {},
      },
      nbVisibleFilters: {
        type: Number,
        default: 5,
      },
      displayMore: {
        type: Boolean,
        default: false,
      },
      counter: {
        type: String,
        default: '',
      },
    },
    render(createElement) {
      let engines = [];
      let hiddenFilterIds = [];
      let hiddenEngines = [];
      let hiddenEnginesDiv = null;
      let moreButton = null;
      let visibleFilterIndex = 0;
      let validEngineFilters = 0;
      let nbVisibleFilters = this.nbVisibleFilters;

      for (let filterKey in this.filters) {
        let filter = this.filters[filterKey];
        const filterSize =
          typeof filter.conf !== 'undefined' &&
          typeof filter.conf.filterSize !== 'undefined'
            ? filter.conf.filterSize
            : 1;
        if (filter.engine) {
          if (filterSize) {
            validEngineFilters = validEngineFilters + filterSize;
          } else {
            validEngineFilters++;
          }
        }
      }

      // hors mobile
      // gestion de taille du bouton plus de filtres
      if (nbVisibleFilters > 1 && nbVisibleFilters + 1 === validEngineFilters) {
        nbVisibleFilters = validEngineFilters;
      }

      for (let filterKey in this.filters) {
        if (this.filters.hasOwnProperty(filterKey)) {
          const filter = this.filters[filterKey];

          // Si filterSize est définit sinon vaut 1
          const filterSize =
            filter.conf && filter.conf.filterSize ? filter.conf.filterSize : 1;

          // Si le filtre posède un composant engine
          if (filter.engine) {
            // Le mode moreFilter peut être forcé dans la configuration du filtre sinon est défini par le nombre de filtre déjà visible
            let moreFilter =
              (filter.conf && filter.conf.moreFilter) ||
              visibleFilterIndex >= nbVisibleFilters;

            // Try to load filter component
            try {
              // Component exist
              let component = require('@Diffusio/js/components/engines/' +
                filter.component).default;
              let filterClasse =
                filterSize > 1 && filterSize < nbVisibleFilters
                  ? 'lcol-' + filterSize
                  : 'lcol';

              // classe ajouté pour les blocs dans + de filtres
              if (moreFilter) {
                filterClasse += ' morefilter';
              }

              // classe avec id du filtre
              filterClasse += ' filter-' + filter.id;

              const engineElement = createElement(component, {
                // Prend la taille définit si filterSize supérieur à 1
                class: filterClasse,
                props: {
                  ...filter,
                  availableProps: Object.keys(filter),
                  moreFilter,
                },
              });

              // Add created element to the v-dom
              if (moreFilter) {
                hiddenFilterIds.push(filterKey);
                hiddenEngines.push(engineElement);
              } else {
                visibleFilterIndex += filterSize;
                engines.push(engineElement);
              }
            } catch (error) {
              // Component doesn't exist
              console.error(error);
            }
          }
        }
      }

      if (hiddenEngines.length) {
        hiddenEnginesDiv = createElement(
          'div',
          {
            class: ['moreFilterWrapper'],
            style: {
              display: this.displayMore ? 'inherit' : 'none',
            },
          },
          [
            createElement(
              'div',
              {
                class: 'centered-content lgrid',
              },
              [
                ...hiddenEngines,
                createElement(DsioCloseFilters, {
                  class: '',
                  props: {},
                }),
              ]
            ),
          ]
        );

        // Bouton Plus de filtres
        moreButton = createElement(DsioMore, {
          class: 'lcol',
          props: {
            hiddenFilterIds,
            counter: this.counter,
          },
        });
      }

      return createElement('div', [...engines, moreButton, hiddenEnginesDiv]);
    },
  };
</script>
