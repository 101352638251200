import { CoverageOperator } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import { coverageModule, expositionModule, sessionModule } from '@/store';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/PinFilterCoverage.vhtml';
import L from 'leaflet';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { LMap } from 'vue2-leaflet';
import ExpositionLegend from './ExpositionLegend';

@Template
@Component({
  components: { Icon, ExpositionLegend },
})
export default class PinFilterCoverage extends Vue {
  /**
   * The map to add pins.
   */
  @Prop({ type: Object, required: true })
  private map: LMap;

  /**
   * If loading coverage.
   */
  private loadingCoverage = false;

  /**
   * If show coverage.
   */
  private showCoverage = false;

  /**
   * If a request coverage is started.
   */
  private requestCoverageStarted = false;

  /**
   * If display coverage.
   */
  private displayCoverage = false;

  /**
   * Current bounds of the map.
   */
  private currentBounds: L.LatLngBounds;

  /**
   * Coverage geojson drawn on the map.
   */
  private coverageGeoJson: L.GeoJSON | null = null;

  /**
   * The operator.
   */
  private operator: CoverageOperator = 'orange';

  /**
   * On checkbox coverage change.
   */
  @Watch('showCoverage')
  public onShowCoverageChange(v: boolean) {
    this.update(this.currentBounds);
    coverageModule.setCoverageActive(v);

    if (!v) {
      this.operator = 'orange';
    }
  }

  /**
   * On exposition active change.
   * @param v
   */
  @Watch('expositionActive')
  public onExpositionActiveChange(v: boolean) {
    if (v) {
      this.showCoverage = false;
    }
  }

  /**
   * On operator change.
   */
  @Watch('operator')
  public onOperatorChange() {
    this.update(this.currentBounds);
  }

  /**
   * Update Coverage.
   * @param bounds
   */
  public async update(bounds: L.LatLngBounds) {
    this.currentBounds = bounds;

    if (this.showCoverage) {
      this.loadingCoverage = true;
      this.displayCoverage = this.map.mapObject.getZoom() > 12;

      if (this.map.mapObject.getZoom() > 12 && !this.requestCoverageStarted) {
        const zoom = this.map.mapObject.getZoom();
        const nw = bounds.getNorthWest();
        const se = bounds.getSouthEast();

        this.requestCoverageStarted = true;

        const response = await coverageModule.getCoverage({
          session: sessionModule,
          nw: {
            latitude: nw.lat,
            longitude: nw.lng,
          },
          se: {
            latitude: se.lat,
            longitude: se.lng,
          },
          zoom,
          operator: this.operator,
        });

        if (response !== null) {
          await this.redrawCoverage();
        }

        this.requestCoverageStarted = false;
      } else {
        coverageModule.clearCoverage();
        await this.redrawCoverage();
      }

      this.loadingCoverage = false;
    } else if (this.coverageGeoJson !== null) {
      this.coverageGeoJson.removeFrom(this.map.mapObject);
      this.coverageGeoJson = null;
    }
  }

  public async redrawCoverage() {
    return new Promise<void>((resolve) => {
      const rawGeojson = coverageModule.coverage;
      const oldCoverageGeoJson = this.coverageGeoJson;

      this.coverageGeoJson = new L.GeoJSON(rawGeojson as any, {
        style(feature) {
          return {
            color: feature?.properties.stroke ?? 'rgba(0, 0, 0, 0)',
            weight: feature?.properties.stroke ? 4 : 0,
          };
        },
        onEachFeature(feature, layer) {
          const popup = new L.Popup({}).setContent(feature.properties.popup);

          layer.bindPopup(popup);
        },
      });

      if (oldCoverageGeoJson !== null) {
        oldCoverageGeoJson.removeFrom(this.map.mapObject);
      }

      this.coverageGeoJson.addTo(this.map.mapObject);

      resolve();
    });
  }

  /**
   * Get exposition active.
   */
  public get expositionActive() {
    return expositionModule.expositionActive;
  }
}
