import SensorApi from '@/api/SensorApi';
import { GlobalReportData } from '@/api/SensorModels';
import { Dayjs } from 'dayjs';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

@Module
export default class GlobalReportModule extends VuexModule {
  /**
   * Datas for global report.
   *
   * @private
   * @type {(GlobalReportData|null)}
   * @memberof GlobalReportModule
   */
  private data: GlobalReportData|null = null;

  /**
   * Set data.
   *
   * @private
   * @param {(GlobalReportData|null)} data
   * @memberof GlobalReportModule
   */
  @Mutation
  private setData(data: GlobalReportData|null) {
    this.data = data;
  }

  /**
   * Get global report datas from the API.
   *
   * @param {{session: SessionModule; start: Dayjs; end: Dayjs; tags: string[]}} {
   *     session, start, end, tags,
   *   }
   * @returns
   * @memberof GlobalReportModule
   */
  @Action
  public async getGlobalReportData({
    session, start, end, tags,
  }: {session: SessionModule; start: Dayjs; end: Dayjs; tags: string[]}) {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const response = await SensorApi.getInstance().getDataGlobalReport(token, start, end, tags);

    if (response !== null) {
      this.setData(response);
    }

    return null;
  }

  /**
   * Get datas for the global report.
   *
   * @readonly
   * @memberof GlobalReportModule
   */
  public get globalReportData() {
    return this.data;
  }

  /**
   * Get sensor extremums.
   *
   * @readonly
   * @memberof GlobalReportModule
   */
  public get sensorExtremumsById() {
    return (id: string) => this.data?.max_min_avg_per_device[id] || null;
  }
}
