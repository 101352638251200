<template>
  <div class="flex items-center">
    <div
      class="mx-8 h-6 w-px bg-gray-text hidden md:block"
      v-if="Object.keys(contextualFilters).length > 0"
    ></div>
    <div class="flex flex-wrap">
      <template v-for="(filter, filterName) in contextualFilters">
        <button
          v-for="(value, key) in filter"
          :key="filterName+key"
          class="mr-4 md:mb-0 mb-4 border px-3 py-1 flex items-center rounded transition-colors duration-300 hover:text-white hover:bg-light-blue"
          @click="deleteStore(filterName, value.id)"
          title="Supprimer le filtre"
        >
          <span v-html="value.name" class="mr-2 text-sm" />
          <span>
            <Icon name="close" :width="10" :height="10" />
          </span>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Helpers/Icon';

  export default {
    components: { Icon },
    computed: {
      contextualFilters() {
        return this.$store.getters[this.instanceId + '/Request/context'];
      },
    },
    methods: {
      deleteStore(filterId, value) {
        this.dispatch('removeValueFilter', { filterId, value });
      },
    },
  };
</script>
