import DetailSensor from '@/views/Sensor/DetailSensor';
import Template from '@Templates/components/Page/ListSensor/PopupDetailSensor.vhtml';
import { Component, Vue } from 'vue-property-decorator';
import { Prop } from 'vue-slider-component/node_modules/vue-property-decorator';

@Template
@Component({ components: { DetailSensor } })
export default class PopupDetailSensor extends Vue {
  @Prop({ required: true })
  private sensorId!: string | null;
}
