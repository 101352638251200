import isset from '@Diffusio/js/utils/isset';
import { format as fnsFormat, formatDistance as fnsFormatDistance, formatRelative as fnsFormatRelative } from 'date-fns';
import { de, es, fr, nl } from 'date-fns/locale';

const locales = { fr, nl, es, de };

export default class I18nDate {
  constructor(lang) {
    this.lang = lang;
  }

  _setLang(options) {
    // Si différent de anglais et la langue est chargé
    if (this.lang !== 'en' && isset(locales[this.lang])) {
      options.locale = locales[this.lang];
    }
    return options;
  }

  /**
   * Formate la date en utilisant la langue courante
   * @param {Date|Number} date the original date
   * @param {String} formatStr the string of tokens
   * @param {Object} options an object with options.
   * @returns {String}
   */
  format(date, formatStr = 'PP', options = {}) {
    // Ajoute le paramètre de langue
    this._setLang(options);
    return fnsFormat(date, formatStr, options);
  }

  /**
   * Retourne la distance entre deux dates en mots
   * @param {Date|Number} date the date.
   * @param {Date|Number} baseDate the date to compare with.
   * @param {Object} options an object with options.
   * @returns {string}
   */
  formatDistance(date, baseDate, options = {}) {
    // Ajoute le paramètre de langue
    this._setLang(options);
    return fnsFormatDistance(date, baseDate, options);
  }

  /**
   * Retourne la date en mot, relative à la date de basse
   * @param {Date|Number} date the date to format.
   * @param {Date|Number} baseDate the date to compare with.
   * @param {Object} options an object with options.
   * @returns {string}
   */
  formatRelative(date, baseDate, options = {}) {
    // Ajoute le paramètre de langue
    this._setLang(options);
    return fnsFormatRelative(date, baseDate, options);
  }
}
