<div class="border p-6 mx-6 rounded-md grid grid-flow-row gap-6">
  <div class="">
    <label v-if="!loadingAntennas" for="showAntennas" class="rounded-md text-sm flex items-center container-checkbox green">
      <input id="showAntennas" v-model="showAntennas" type="checkbox" />
      <span class="square-checkbox mr-4 ">
        <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
      </span>
      <span class="text-left">
        <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.view_antennas') }}</span>
      </span>
    </label>
    <div v-else class="rounded-md text-sm flex items-center">
      <span class="spinner spinner-light lg:mr-3 relative"><span></span><span></span><span></span><span></span></span>
      <span class="hidden lg:block">{{ $t('templates.components.components.Helpers.DsioPinFilter.loading_antennas') }}</span>
    </div>
  </div>

  <div class="border-t" v-if="!displayAntennas"></div>

  <div v-if="!displayAntennas">
    <p class="text-sm text-gray-light-text flex items-center font-bold">
      <span class="text-light-blue mr-4">
        <Icon name="exclamation" :width="20" :height="20" />
      </span>
      {{ $t('templates.components.components.Helpers.DsioPinFilter.ask_zoom_antennas') }}
    </p>
  </div>

  <div class="border-t" v-if="showDirections && !displayArrows"></div>

  <div v-if="showDirections && !displayArrows">
    <p class="text-sm text-gray-light-text flex items-center font-bold">
      <span class="text-light-blue mr-4">
        <Icon name="exclamation" :width="20" :height="20" />
      </span>
      {{ $t('templates.components.components.Helpers.DsioPinFilter.ask_zoom_see') }}<br>{{ $t('templates.components.components.Helpers.DsioPinFilter.see_antennas_direction') }}
    </p>
  </div>

  <div class="border-t" v-if="showAntennas"></div>

  <div v-if="showAntennas">
    <div>
      <label for="allAntennas" class="pb-6 text-sm text-gray-light-text flex items-center container-checkbox green">
        <input id="allAntennas" v-model="allAntennas" type="checkbox" />
        <span class="square-checkbox mr-4">
          <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
        </span>
        <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter.all_antennas') }}</span>
      </label>

      <div class="w-full h-px bg-border-gray"></div>

      <div class="my-6 ml-6">
        <label for="allOperators" class="my-6 text-sm text-gray-light-text flex items-center container-checkbox green">
          <input id="allOperators" v-model="allOperators" type="checkbox" />
          <span class="square-checkbox mr-4">
            <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
          </span>
          <span class="text-left flex items-center">
            <span class="mr-2">
              <Icon name="mobile" :width="16" :height="16" />
            </span>
            <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.mobile_telephony') }}</span>
          </span>
        </label>

        <div class="my-6 ml-0 lg:ml-10">
          <p class="mt-3 text-xs uppercase">{{ $t('templates.components.components.Helpers.DsioPinFilter.telephone_operators') }}</p>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-0">
            <label for="orange" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="orange" v-model="currentFilters.isOrange" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left flex items-center">
                <span class="mr-2">
                  <Icon name="orange" :width="16" :height="16" />
                </span>
                <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.orange') }}</span>
              </span>
            </label>

            <label for="free" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="free" v-model="currentFilters.isFree" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left flex items-center">
                <span class="mr-2">
                  <Icon name="free" :width="16" :height="16" />
                </span>
                <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.free') }}</span>
              </span>
            </label>

            <label for="bouygues" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="bouygues" v-model="currentFilters.isBouygues" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left flex items-center">
                <span class="mr-2">
                  <Icon name="bouygues" :width="16" :height="16" />
                </span>
                <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.bouygues') }}</span>
              </span>
            </label>

            <label for="sfr" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="sfr" v-model="currentFilters.isSfr" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left flex items-center">
                <span class="mr-2">
                  <Icon name="sfr" :width="16" :height="16" />
                </span>
                <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.sfr') }}</span>
              </span>
            </label>

            <label for="other-operator" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green md:col-span-2">
              <input id="other-operator" v-model="currentFilters.isOtherOperator" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left flex items-center">
                <span class="mr-2">
                  <Icon name="other-operator" :width="16" :height="16" />
                </span>
                <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.other_operators') }}</span>
              </span>
            </label>
          </div>
        </div>

        <div class="my-6 ml-0 lg:ml-10 mt-8">
          <p class="mt-3 text-xs uppercase">Technologies :</p>
          <div class="flex flex-row flex-wrap gap-6">
            <label for="5g" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="5g" v-model="currentFilters.is5g" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter["5g"]') }}</span>
            </label>

            <label for="4g" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="4g" v-model="currentFilters.is4g" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter["4g"]') }}</span>
            </label>

            <label for="3g" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="3g" v-model="currentFilters.is3g" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter["3g"]') }}</span>
            </label>

            <label for="2g" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
              <input id="2g" v-model="currentFilters.is2g" type="checkbox" />
              <span class="square-checkbox mr-4">
                <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
              </span>
              <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter["2g"]') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full h-px bg-border-gray"></div>

    <div class="my-6 ml-6 lg:ml-10">
      <label for="fm" class="my-3 text-sm text-gray-light-text flex items-center container-checkbox green">
        <input id="fm" v-model="currentFilters.isFm" type="checkbox" />
        <span class="square-checkbox mr-4">
          <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
        </span>
        <span class="text-left flex items-center">
          <span class="mr-2">
            <Icon name="fm" :width="16" :height="16" />
          </span>
          <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.fm') }}</span>
        </span>
      </label>

      <label for="tv" class="my-3 text-sm text-gray-light-text flex items-center container-checkbox green">
        <input id="tv" v-model="currentFilters.isTv" type="checkbox" />
        <span class="square-checkbox mr-4">
          <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
        </span>
        <span class="text-left flex items-center">
          <span class="mr-2">
            <Icon name="tv" :width="16" :height="16" />
          </span>
          <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.tv') }}</span>
        </span>
      </label>

      <label for="private" class="my-3 text-sm text-gray-light-text flex items-center container-checkbox green">
        <input id="private" v-model="currentFilters.isPrivate" type="checkbox" />
        <span class="square-checkbox mr-4">
          <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
        </span>
        <span class="text-left flex items-center">
          <span class="mr-2">
            <Icon name="private" :width="16" :height="16" />
          </span>
          <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.private_networks') }}</span>
        </span>
      </label>

      <label for="other" class="my-3 text-sm text-gray-light-text flex items-center container-checkbox green">
        <input id="other" v-model="currentFilters.isOther" type="checkbox" />
        <span class="square-checkbox mr-4">
          <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
        </span>
        <span class="text-left flex items-center">
          <span class="mr-2">
            <Icon name="other" :width="16" :height="16" />
          </span>
          <span>{{ $t('templates.components.components.Helpers.DsioPinFilter.other_stations') }}</span>
        </span>
      </label>
    </div>

    <div class="w-full h-px bg-border-gray"></div>

    <div class="mt-6 ml-6 lg:ml-10">
      <label for="direction" class="my-2 text-sm text-gray-light-text flex items-center container-checkbox green">
        <input id="direction" type="checkbox" v-model="showDirections" />
        <span class="square-checkbox mr-4">
          <Icon name="check" class="square-checkbox-icon" :width="16" :height="16" />
        </span>
        <span class="text-left">{{ $t('templates.components.components.Helpers.DsioPinFilter.antennas_direction') }}</span>
      </label>
    </div>
  </div>
</div>
