import { sessionModule } from '@/store';
import store from '@Diffusio/js/store';
import I18nDate from '@Diffusio/js/utils/I18nDate';
import isset from '@Diffusio/js/utils/isset';
import { isEqual } from 'lodash';

window.onpopstate = ({ state }) => {
  // leh refresh du title
  // defaultTitle definit dans Request.js
  if (history.defaultTitle !== document.title) {
    document.title = history.defaultTitle;
  }

  if (isset(state) && isset(state.appState) && isset(state.request)) {
    const { appState, request } = state;

    store.state.instances.forEach((instance) => {
      const storeState = { ...store.state[instance].app.state };
      const storeRequest = { ...store.getters[instance + '/requestParams'] };
      // Si le state à changer
      if (!isEqual(storeState, appState[instance])) {
        // update
        store.dispatch(instance + '/updateInstance', { state: appState[instance] });
      }
      // Si la request à changer
      if (!isEqual(storeRequest, request[instance])) {
        // update
        // console.log(instance, request[instance]);
      }
    });
  }
};

export default {
  computed: {
    isMultiApp() {
      return this.$store.getters['currentInstanceId'] !== null;
    },
    viewMod() {
      if (sessionModule.userAccount && !sessionModule.userAccount.showMap) {
        return 'list';
      }

      return this.app.state.viewMod || this.app.params.defaultViewMod;
    },
    sortBy() {
      return this.app.state.sortBy || this.app.params.defaultSortBy;
    },
    listActive() {
      return this.viewMod === 'mixte' || this.viewMod === 'list';
    },
    mapActive() {
      return this.viewMod === 'mixte' || this.viewMod === 'map';
    },
    instanceId() {
      return this.$store.getters['currentInstanceId'] || 'id1';
    },
    historyState() {
      return this.$store.getters['historyState'];
    },
    requestUrl() {
      return this.app.params.requestUrl;
    },
    lang() {
      return this.app.params.lang;
    },
    t() {
      return this.$root.instance.staticConf.trads || {};
    },
    storeState() {
      return this.$store.state[this.instanceId];
    },
    app() {
      return this.$store.getters[this.instanceId + '/app'];
    },
    isMobileScreen() {
      return ['mobile', 'tablet'].indexOf(this.$root.breakpoint) > -1;
    },
  },
  methods: {
    /**
     * indique si un filtre existe
     *
     * @param filterName identifiant filtre
     * @returns {listFilters|{}|*}
     */
    filterExists(filterName) {
      return this.storeState.listFilters && this.storeState.listFilters[filterName];
    },
    /**
     * Raccourci pour récupérer un getter Vuex dans le contexte de l'instance
     *
     * @param {String} getter
     * @returns {*}
     */
    getter(getter) {
      return this.$store.getters[this.instanceId + '/' + getter];
    },
    /**
     * Raccourci pour propager une action Vuex dans le contexte de l'instance
     *
     * @param {String} action
     * @param {*} payload
     * @returns {Promise<any>}
     */
    dispatch(action, payload) {
      return this.$store.dispatch(this.instanceId + '/' + action, payload);
    },

    dispatchAll(action, payload) {
      return this.$store.dispatch(action, payload);
    },
    /**
     * Raccourci pour acter une mutation Vuex dans le contexte de l'instance
     *
     * @param {String} mutation
     * @param {*} payload
     */
    commit(mutation, payload) {
      return this.$store.commit(this.instanceId + '/' + mutation, payload);
    },

    setWindowOverflow(hiddenOverflow) {
      let htmlEl = document.getElementsByTagName('html')[0];
      hiddenOverflow ? htmlEl.classList.add('overflow-hidden') : htmlEl.classList.remove('overflow-hidden');
    },

    refreshBasket() {
      window.dispatchEvent(new CustomEvent('forceBasketReload'));
    },

    I18nDate() {
      return new I18nDate(this.lang);
    },
  },
};
