import instanceStore from '@Diffusio/js/store/instance';

export default {
  strict: process.env.NODE_ENV !== 'production',
  state() {
    return {
      multiApp: {
        currentInstanceId: null,
      },
      instances: [],
    };
  },
  getters: {
    currentInstanceId(state) {
      return state.multiApp.currentInstanceId;
    },
    hasInstance(state) {
      return (id) => {
        return state.instances.filter(instance => instance === id).length > 0;
      }
    },
    historyState(state, getters) {
      let appState = {};
      let request = {};
      state.instances.forEach((instance) => {
        appState[instance] = { ...state[instance].app.state };
        request[instance] = { ...getters[instance + '/requestParams'] };
      });
      return {
        appState,
        request,
      };
    },
  },
  mutations: {
    ADD_INSTANCE(state, value) {
      state.instances.push(value);
    },
    SET_CURRENT_INSTANCE(state, instanceId) {
      state.multiApp.currentInstanceId = instanceId;
    },
  },
  actions: {
    updateMod({ state, commit, dispatch }, name) {
      for (let index in state.instances) {
        const instanceId = state.instances[index];
        if (state.multiApp.currentInstanceId !== instanceId) {
          commit(instanceId + '/UPDATE_MOD', name);
        }
      }

      dispatch(state.multiApp.currentInstanceId + '/updateMod', name);
    },
    updateFilter({ state, commit, dispatch }, filter) {
      for (let index in state.instances) {
        const instanceId = state.instances[index];
        if (state.multiApp.currentInstanceId !== instanceId) {
          commit(instanceId + '/UPDATE', filter);
        }
      }
      dispatch(state.multiApp.currentInstanceId + '/updateFilter', filter);
    },
    setCurrentInstance({ commit }, { instanceId }) {
      commit('SET_CURRENT_INSTANCE', instanceId);
    },
    addInstance({ commit, dispatch }, { instanceId, app }) {
      this.registerModule(instanceId, instanceStore);
      commit('ADD_INSTANCE', instanceId);
      dispatch(instanceId + '/setInstance', { instanceId, app });
    },
  },
};
