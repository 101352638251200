import Template from '@Templates/components/Helpers/Loader.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component
export default class Loader extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  private label: string;

  /**
   * Compute the label.
   *
   * @readonly
   * @private
   * @memberof Loader
   */
  private get computedLabel() {
    return this.label.length > 0
      ? this.label
      : this.$t('templates.components.components.Helpers.Loader.loading');
  }
}
