import Icon from '@/components/Helpers/Icon';
import { coverageModule, expositionModule } from '@/store';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/ExpositionLegend.vhtml';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

type ExpositionType = 'exposition' | 'coverage';

@Template
@Component({
  components: { Icon },
})
export default class ExpositionLegend extends Vue {
  @Prop({ type: String, required: true })
  private type: ExpositionType;

  @Prop({ type: String, required: true })
  private legend: string;

  /**
   * The milestones for the legend, adjusted based on the type.
   */
  private get milestones(): (number[] | string[]) {
    if (this.type === 'coverage') {
      return ['Couverture limitée', 'Bonne couverture', 'Très bonne couverture'];
    }

    // Default to numeric milestones for 'exposition'
    return [0, 0.75, 1.5, 2.25, 6];
  }

  /**
   * The gradient for the legend.
   */
  public get gradient() {
    if (this.type === 'exposition') {
      return `linear-gradient(to right,
        ${expositionModule.legend?.start} 0%,
        ${expositionModule.legend?.color1} 25%,
        ${expositionModule.legend?.color2} 50%,
        ${expositionModule.legend?.color3} 75%,
        ${expositionModule.legend?.end} 100%
      )
    `;
    }

    if (this.type === 'coverage') {
      return `linear-gradient(to right,
        ${coverageModule.legend?.start} 0%,
        ${coverageModule.legend?.color1} 25%,
        ${coverageModule.legend?.color2} 50%,
        ${coverageModule.legend?.color3} 75%,
        ${coverageModule.legend?.end} 100%
      )
    `;
    }

    return '';
  }

  /**
   * The labels for the milestones.
   * @returns
   */
  public get milestonesLabels() {
    if (this.type === 'coverage') {
      // For 'coverage', the milestones are already descriptive labels.
      return this.milestones as string[];
    }

    // Process as numbers for 'exposition'
    return (this.milestones as number[]).map((m, i) => {
      const milestone = m.toLocaleString();

      if (i >= this.milestones.length - 1) {
        return `${milestone}+`;
      }

      return milestone;
    });
  }
}
