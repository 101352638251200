import { createInstance } from '@/diffusio/js/DsioParams';
import Template from '@Diffusio/Diffusio.vhtml';
import DiffusioApp from '@Diffusio/js/DiffusioApp.vue';
import { debounce } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Template
@Component({
  components: {
    DiffusioApp,
  },
})
export default class Diffusio extends Vue {
  private windowWidth: number | null = null;
  private scrollInApp: boolean = false;
  private scrollUp: boolean = false;
  private instance: any;

  beforeCreate() {
    this.instance = createInstance(this);
  }

  created() {
    if (!this.$store.getters.hasInstance('id1')) {
      this.$store.dispatch('addInstance', {
        instanceId: 'id1',
        app: this.instance.app,
      });

      // changement de taille pour les breakpoints
      window.addEventListener(
        'resize',
        debounce(() => {
          this.updateBreakpoints(window.innerWidth);
        }, 100)
      );
    }

    this.updateBreakpoints(window.innerWidth);
  }

  updateInContainer(windowTop: number, appEl: HTMLElement) {
    return (
      windowTop + 200 > appEl.offsetTop &&
      windowTop + window.innerHeight <
        appEl.offsetTop + appEl.offsetHeight - 100
    );
  }

  updateBreakpoints(width: number) {
    this.windowWidth = width;
  }
}
