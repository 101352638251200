<template>
  <div v-if="false">
    <div v-click-outside="closeSort" :class="{ 'is-open': isOpen }">
      <button class="title no-button" @click="toggleSort">
        {{ sortPrefix }}
        <br class="hidden-desktop" />
        <strong>{{ currentSort }}</strong>
        <i class="dsio-angle-down" />
      </button>
      <div class="sort-popup">
        <button class="title no-button" @click="toggleSort">
          {{ sortPrefix }}
          <br class="hidden-desktop" />
          <strong>{{ currentSort }}</strong>
          <i class="dsio-angle-up" />
        </button>
        <ul class="sort-list">
          <li v-for="(option, key) in options" :key="key" @click="changeSort(key)">{{ option }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DsioSort',
    props: {
      sorts: {
        type: Object,
        default: () => {},
      },
      sortPrefix: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      currentSort() {
        return this.sorts[this.sortBy] || 'Tris';
      },
      options() {
        let options = {};
        for (let sort in this.sorts) {
          if (sort !== this.sortBy && this.sorts.hasOwnProperty(sort)) {
            options[sort] = this.sorts[sort];
          }
        }
        return options;
      },
    },
    methods: {
      toggleSort() {
        this.isOpen = !this.isOpen;
      },
      closeSort() {
        this.isOpen = false;
      },
      changeSort(id) {
        this.closeSort();
        this.dispatch('updateSort', id);
      },
    },
  };
</script>
