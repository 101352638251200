import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { getLocale } from '@/helpers/MiscHelpers';
import en from './lang/en.json';
import es from './lang/es.json';
import fr from './lang/fr.json';

// Current locale.
const locale = getLocale();

// Translations.
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'fr',
  messages: {
    fr,
    en,
    es,
  },
});

// eslint-disable-next-line import/prefer-default-export
export { i18n };
