<footer class="w-full xl:h-48 flex items-end" :class="{'bg-white from-sky-blue to-white bg-gradient-to-t': background, 'text-white': light}">
  <div class="container mx-auto px-4 md:h-16 flex flex-col md:flex-row justify-between">
    <div class="flex justify-center">
      <p class="flex flex-col md:flex-row justify-between items-center text-xs">
        <span class="my-2 md:my-0">&copy; {{ $t('templates.components.components.Layout.Footer.supervision') }}</span>
        <span class="hidden md:block mx-2">-</span>
        <span class="my-2 md:my-0">
          <a href="https://www.exem.fr/mentions-legales/" target="_blank" class="font-bold">{{ $t('templates.components.components.Layout.Footer.legal') }}</a>
        </span>
        <span class="hidden md:block mx-2">|</span>
        <span class="my-2 md:my-0">
          <a href="https://www.exem.fr/politique-de-confidentialite/" target="_blank" class="font-bold">{{ $t('templates.components.components.Layout.Footer.confidentiality') }}</a>
        </span>
        <span class="hidden md:block mx-2">|</span>
        <span class="my-2 md:my-0">
          <a href="https://www.exem.fr/politique-de-confidentialite/" target="_blank" class="font-bold flex items-center">
            <img src="@Assets/img/rgpd.svg" class="w-5 h-5 mr-1" :alt="$t('templates.components.components.Layout.Footer.gpdr')">
            <span>{{ $t('templates.components.components.Layout.Footer.gpdr') }}</span>
          </a>
        </span>
      </p>
    </div>
    <div class="flex justify-center py-6 md:py-0">
      <p class="flex justify-between items-center text-xs">
        <span class="mr-2">{{ $t('templates.components.components.Layout.Footer.technology') }}</span>
        <a href="https://www.exem.fr/" target="_blank" class="no-hover" :title="$t('templates.components.components.Layout.Footer.to_exem_website')">
          <img v-if="light" src="@Assets/img/exem-light.svg" class="object-contain" alt="Exem" style="width: 101px; height: 25px;">
          <img v-else src="@Assets/img/exem.svg" class="object-contain" alt="Exem" style="width: 101px; height: 25px;">
        </a>
      </p>
    </div>
  </div>
</footer>
