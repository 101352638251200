<template>
  <div class="flex lg:justify-end">
    <div class="grid grid-cols-2 gap-8 w-full lg:w-auto">
      <button
        v-for="(viewModId, key) in viewMods"
        :key="key"
        :class="[viewModId, viewModId == currentMod ? 'active': '']"
        @click="currentMod = viewModId"
        class="text-sm flex items-center justify-center lg:justify-start dsio-button-switch-mode"
      >
        <span class="mr-2">
          <Icon :width="19" :height="16" :name="iconName(viewModId)" />
        </span>
        <span class="whitespace-nowrap">{{ t.viewMods[viewModId] }}</span>
      </button>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Helpers/Icon';

  export default {
    name: 'DsioMods',
    components: { Icon },
    props: {
      viewMods: {
        type: Array,
        default: () => [],
      },
      defaultViewMod: {
        type: String,
        default: 'mixte',
      }
    },
    computed: {
      currentMod: {
        get() {
          return this.viewMod;
        },
        set(name) {
          if (this.isMultiApp) {
            return this.dispatchAll('updateMod', name);
          }
          return this.dispatch('updateMod', name);
        },
      },
    },
    beforeCreated() {
      this.currentMod = this.defaultViewMod;
    },
    methods: {
      iconName(type) {
        if (type === 'mixte') {
          return 'map';
        }

        return type;
      },
    },
  };
</script>
