import {
  FieldValueGraphAttributeValue, HumidityBatteryGraphValues, Site, SiteUpperGraphValues, VoltageAxisGraphValues
} from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import Popover from '@/components/Helpers/Popover';
import ChartHelper from '@/helpers/ChartHelper';
import VueHtml2pdf from '@/libs/vue-html2pdf.vue';
import { sensorDataModule, sessionModule } from '@/store';
import pointSvgBlue from '@Assets/img/pointsGraph/chart-point-blue.png';
import pointSvgGray from '@Assets/img/pointsGraph/chart-point-gray.png';
import pointSvgGreen from '@Assets/img/pointsGraph/chart-point-green.png';
import pointSvgOrange from '@Assets/img/pointsGraph/chart-point-orange.png';
import pointSvgRed from '@Assets/img/pointsGraph/chart-point-red.png';
import Template from '@Templates/components/Page/DetailSensor/MesuresTabs.vhtml';
import dayjs, { Dayjs } from 'dayjs';
import { Chart } from 'highcharts-vue';
import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import ReportSensorPDF from './ReportSensorPDF';

@Template
@Component({
  components: {
    Chart,
    Icon,
    VueHtml2pdf,
    ReportSensorPDF,
    Popover
  },
})
export default class MesuresTabs extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  private sensor: Site;

  @Prop({
    required: true,
    default: false
  })
  private sensorDataFullLoaded!: boolean;

  /**
   * Date start selected in calendar.
   *
   * @private
   * @memberof ListSensor
   */
  private dateStart = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .subtract(1, 'week')
    .toDate();

  /**
   * Date end selected in calendar.
   *
   * @private
   * @memberof ListSensor
   */
  private dateEnd = dayjs()
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(0)
    .toDate();

  /**
   * Chart date start.
   *
   * @private
   * @memberof MesuresTabs
   */
  private minDate = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .subtract(1, 'year')
    .toDate();

  /**
   * Dates DayJS format.
   *
   * @private
   * @memberof MesuresTabs
   */
  private datesDayJS = {
    start: dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(1, 'week'),
    end: dayjs()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0),
  };

  /**
   * Chart date end.
   *
   * @private
   * @memberof MesuresTabs
   */
  private maxDate = dayjs()
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(0)
    .toDate();

  /**
   * Now for date in PDF.
   *
   * @private
   * @memberof MesuresTabs
   */
  private now: Dayjs = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  /**
   * Current session token.
   *
   * @private
   * @type {(string|null)}
   * @memberof MesuresTabs
   */
  private sessionToken: string|null = null;

  /**
   * If need to show the loader.
   *
   * @private
   * @memberof MesuresTabs
   */
  private showLoader = false;

  /**
   * If the sensor is currently in maintenance.
   *
   * @private
   * @type {(Dayjs|null)}
   * @memberof MesuresTabs
   */
  private maintenanceDate: Dayjs|null = null;

  /**
   * If force update the view.
   *
   * @private
   * @memberof MesuresTabs
   */
  private forceUpdate = 0;

  /**
   * If show regulated limit.
   *
   * @private
   * @memberof MesuresTabs
   */
   private showRegulatedLimit = false;

  /**
   * On dates changes.
   *
   * @private
   * @param {Date} value
   * @memberof MesuresTabs
   */
  @Watch('dateStart', { deep: true })
   private onDateStartChanges(value: Date) {
     this.datesDayJS = {
       ...this.datesDayJS,
       start: dayjs(value),
     };

     this.$root.$emit('dateMesureTabsChanged', this.datesDayJS);
   }

  /**
   * On dates changes.
   *
   * @private
   * @param {Date} value
   * @memberof MesuresTabs
   */
  @Watch('dateEnd', { deep: true })
  private onDateEndChanges(value: Date) {
    this.datesDayJS = {
      ...this.datesDayJS,
      end: dayjs(value),
    };

    this.$root.$emit('dateMesureTabsChanged', this.datesDayJS);
  }

  /**
   * When all datas for this sensor is loaded.
   *
   * @private
   * @param {boolean} value
   * @memberof MesuresTabs
   */
  @Watch('sensorDataFullLoaded')
  private onSensorDataFullLoaded(value: boolean) {
    if (value) {
      this.showLoader = false;
      this.forceUpdate += 1;
    }
  }

  /**
   * Mounted event.
   *
   * @memberof MesuresTabs
   */
  public async mounted() {
    if (!this.sensorDataFullLoaded && this.maintenanceDate === null) {
      this.showLoader = true;
    }

    this.minDate = this.sensor.installation_date.clone().toDate();

    if (dayjs(this.minDate).isAfter(dayjs(this.dateStart))) {
      this.dateStart = dayjs(this.minDate).clone().toDate();
    }

    this.sessionToken = await sessionModule.getToken();

    setTimeout(() => {
      this.$root.$emit('dateMesureTabsChanged', this.datesDayJS);
    }, 500);
  }

  /**
   * Generate the PDF.
   *
   * @memberof DetailSensor
   */
  public generatePDF() {
    this.now = dayjs();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.html2Pdf as any).generatePdf();
  }

  /**
   * See only today on the chart.
   *
   * @memberof MesuresTabs
   */
  public setDateToday() {
    this.dateStart = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();

    this.dateEnd = dayjs()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0)
      .toDate();
  }

  /**
   * See last week on the chart.
   *
   * @memberof MesuresTabs
   */
  public setDateWeek() {
    this.dateStart = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(7, 'd')
      .toDate();

    this.dateEnd = dayjs()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0)
      .toDate();
  }

  /**
   * See last month on the chart.
   *
   * @memberof MesuresTabs
   */
  public setDateMonth() {
    this.dateStart = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(30, 'd')
      .toDate();

    this.dateEnd = dayjs()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0)
      .toDate();
  }

  /**
   * See last year on the chart.
   *
   * @memberof MesuresTabs
   */
  public setDateYear() {
    this.dateStart = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(1, 'y')
      .toDate();

    this.dateEnd = dayjs()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0)
      .toDate();
  }

  /**
   * See since installation of sensor on the chart.
   *
   * @memberof MesuresTabs
   */
  public setDateFromStart() {
    this.dateStart = new Date(this.minDate);

    this.dateEnd = dayjs()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0)
      .toDate();
  }

  /**
   * Show/hide regulated limit.
   *
   * @memberof MesuresTabs
   */
  public toggleRegulatedLimit() {
    this.showRegulatedLimit = !this.showRegulatedLimit;
  }

  /**
   * Options for upper_graph graph.
   *
   * @readonly
   * @memberof MesuresTabs
   */
  private get optionsUpperGraph() {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    const chartDefault = ChartHelper.getDefaultSplineChartOptions(
      this.sensorData?.upper_graph.title || '',
      this.sensorData?.upper_graph.ordinate || '',
      this.sensorData?.upper_graph.unit || '',
    );

    this.maintenanceDate = null;

    if (this.sensorData !== null && this.sensorData?.upper_graph !== null) {
      const currentlyInMaintenance = this.sensorData.upper_graph.maintenance_periods
        .filter((elem) => elem.stop === null);

      if (currentlyInMaintenance.length > 0) {
        this.maintenanceDate = currentlyInMaintenance[0].start;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const series: any[] = [{
      data: this.sensorDataUpperGraphMesure?.map((data: SiteUpperGraphValues) => ({
        x: data.date.valueOf(),
        y: data.value,
      })),
    }];

    // eslint-disable-next-line no-unused-expressions
    this.sensorData?.upper_graph.maintenance_periods.forEach((maintenance) => {
      if (maintenance.stop === null) {
        series[0].data.push({
          x: dayjs().valueOf(),
          y: undefined
        });
      }
    });

    if (this.showRegulatedLimit) {
      series.push({
        data: this.sensorDataUpperGraphMesure?.map((data: SiteUpperGraphValues) => ({
          x: data.date.valueOf(),
          y: this.sensorData?.upper_graph.regulated_limit,
        })),
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
        color: '#F07331',
      });
    }

    return {
      ...chartDefault,
      plotOptions: {
        ...chartDefault.plotOptions,
        series: {
          ...chartDefault.plotOptions.series,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        }
      },
      xAxis: {
        ...chartDefault.xAxis,
        plotBands: this.sensorData?.upper_graph.maintenance_periods.map((mp) => ({
          color: 'rgb(254, 241, 235)',
          from: mp.start !== null ? mp.start.valueOf() : dayjs().valueOf(),
          to: mp.stop !== null ? mp.stop.valueOf() : dayjs().valueOf(),
          zIndex: 5,
          label: {
            text: 'PÉRIODE DE MAINTENANCE',
            verticalAlign: 'bottom',
            rotation: -90,
            textAlign: 'left',
            y: -5,
            x: 17,
            align: 'left',
            style: {
              color: '#F07331',
              fontFamily: 'Open Sans, sans-serif',
              fontWeight: 'bold',
              fontStyle: 'normal',
              textTransform: 'uppercase',
            },
          },
        })),
      },
      series,
    };
  }

  /**
   * Options for field value graph.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get optionFieldValueGraph() {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    const chartDefault = ChartHelper.getDefaultSplineChartOptions(
      this.sensorData?.field_value_graph_attribute.title || '',
      this.sensorData?.field_value_graph_attribute.ordinate || '',
      this.sensorData?.field_value_graph_attribute.unit || '',
    );

    return {
      ...chartDefault,
      tooltip: {
        ...chartDefault.tooltip,
        shared: true,
        crosshairs: true,
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontFamily: 'Open Sans, sans-serif',
          color: '#636363',
          fontWeight: 'normal'
        },
        title: {
          style: {
            color: '#636363',
          }
        }
      },
      series: [
        {
          data: this.sensorDataFieldValueAttributeMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.quadratic_3,
          })),
          name: this.sensorData?.field_value_graph_attribute.legend.quadratic_3.label,
          color: this.sensorData?.field_value_graph_attribute.legend.quadratic_3.color,
          marker: {
            symbol: `url(${pointSvgGray})`,
            height: 10,
          },
        },
        {
          data: this.sensorDataFieldValueAttributeMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.quadratic_6,
          })),
          color: this.sensorData?.field_value_graph_attribute?.legend.quadratic_6.color,
          name: this.sensorData?.field_value_graph_attribute.legend.quadratic_6.label,
          marker: {
            symbol: `url(${pointSvgBlue})`,
            height: 10,
          },
        },
      ]
    };
  }

  /**
   * Options for humidity temp graph.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get optionTempHumidityGraph() {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    const chartDefault = ChartHelper.getDefaultSplineChartOptions(
      this.sensorData?.temp_humidity_battery_graph.title || '',
      this.sensorData?.temp_humidity_battery_graph.ordinate || '',
      this.sensorData?.temp_humidity_battery_graph.unit || '',
    );

    return {
      ...chartDefault,
      tooltip: {
        ...chartDefault.tooltip,
        shared: true,
        crosshairs: true,
      },
      yAxis: {
        ...chartDefault.yAxis,
        min: undefined,
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontFamily: 'Open Sans, sans-serif',
          color: '#636363',
          fontWeight: 'normal'
        },
        title: {
          style: {
            color: '#636363',
          }
        }
      },
      series: [
        {
          data: this.sensorDataTempHumidityMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.humidity,
          })),
          name: this.sensorData?.temp_humidity_battery_graph.legend.humidity.label,
          color: this.sensorData?.temp_humidity_battery_graph?.legend.humidity.color,
          marker: {
            symbol: `url(${pointSvgBlue})`,
            height: 10,
          },
        },
        {
          data: this.sensorDataTempHumidityMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.temperature,
          })),
          color: this.sensorData?.temp_humidity_battery_graph?.legend.temperature.color,
          name: this.sensorData?.temp_humidity_battery_graph.legend.temperature.label,
          marker: {
            symbol: `url(${pointSvgRed})`,
            height: 10,
          },
        },
        {
          data: this.sensorDataTempHumidityMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.voltage_battery,
          })),
          color: this.sensorData?.temp_humidity_battery_graph?.legend.voltage_battery.color,
          name: this.sensorData?.temp_humidity_battery_graph.legend.voltage_battery.label,
          marker: {
            symbol: `url(${pointSvgGreen})`,
            height: 10,
          },
        }
      ]
    };
  }

  /**
   * Options for voltage axis graph.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get optionVoltageAxisGraph() {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    const chartDefault = ChartHelper.getDefaultSplineChartOptions(
      this.sensorData?.voltage_axis_graph.title || '',
      this.sensorData?.voltage_axis_graph.ordinate || '',
      this.sensorData?.voltage_axis_graph.unit || '',
    );

    return {
      ...chartDefault,
      tooltip: {
        ...chartDefault.tooltip,
        shared: true,
        crosshairs: true,
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontFamily: 'Open Sans, sans-serif',
          color: '#636363',
          fontWeight: 'normal'
        },
        title: {
          style: {
            color: '#636363',
          }
        }
      },
      yAxis: {
        ...chartDefault.yAxis,
        min: undefined,
      },
      series: [
        {
          data: this.sensorDataVoltageAxisMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.voltage_x,
          })),
          name: this.sensorData?.voltage_axis_graph.legend.voltage_x.label,
          color: this.sensorData?.voltage_axis_graph?.legend.voltage_x.color,
          marker: {
            symbol: `url(${pointSvgOrange})`,
            height: 10,
          },
        },
        {
          data: this.sensorDataVoltageAxisMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.voltage_y,
          })),
          color: this.sensorData?.voltage_axis_graph?.legend.voltage_y.color,
          name: this.sensorData?.voltage_axis_graph.legend.voltage_y.label,
          marker: {
            symbol: `url(${pointSvgBlue})`,
            height: 10,
          },
        },
        {
          data: this.sensorDataVoltageAxisMesure?.map((data) => ({
            x: data.date.valueOf(),
            y: data.voltage_z,
          })),
          color: this.sensorData?.voltage_axis_graph?.legend.voltage_z.color,
          name: this.sensorData?.voltage_axis_graph.legend.voltage_z.label,
          marker: {
            symbol: `url(${pointSvgGreen})`,
            height: 10,
          },
        }
      ]
    };
  }

  /**
   * Options for lower graph.
   *
   * @readonly
   * @memberof MesuresTabs
   */
  private get optionsLowerGraph() {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    return {
      ...ChartHelper.getDefaultBarsChartOptions(
        this.sensorData?.lower_graph.title || '',
         this.sensorData?.lower_graph.ordinate || '',
          this.sensorData?.lower_graph.unit || '',
      ),
      xAxis: {
        categories: this.sensorDataMax?.map((o) => o.label),
      },
      series: [
        {
          name: 'MaxByYear',
          data: this.sensorDataMax?.map((o) => o.value),
        },
      ],
    };
  }

  /**
   * If show field vlaue graph.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get showOptionFieldValueGraph() {
    return sessionModule.userAccount?.can_see_field_level_without_alarm;
  }

  /**
   * If show temp humidity graph.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get showOptionTempHumidityGraph() {
    return sessionModule.userAccount?.can_see_temp_hum_battery;
  }

  /**
   * If show voltage exis graph.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get showOptionVoltageAxisGraph() {
    return sessionModule.userAccount?.can_see_voltage_axis;
  }

  /**
   * Sensor datas mesures of upper graph.
   *
   * @readonly
   * @memberof MesuresTabs
   */
  private get sensorDataUpperGraphMesure(): SiteUpperGraphValues[]|null {
    return sensorDataModule.sensorDataUpperGraphOfDateRange(
      this.sensor.id,
      dayjs(this.dateStart)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      dayjs(this.dateEnd)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(0),
    );
  }

  /**
   * Sensor datas mesures of field_value_graph_attribute.
   *
   * @private
   * @returns {(FieldValueGraphAttributeValue[]|null)}
   * @memberof MesuresTabs
   */
  private get sensorDataFieldValueAttributeMesure(): FieldValueGraphAttributeValue[]|null {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    return sensorDataModule.sensorDataFieldValueAttributeOfDateRange(
      this.sensor.id,
      dayjs(this.dateStart)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      dayjs(this.dateEnd)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(0),
    );
  }

  private get sensorDataTempHumidityMesure(): HumidityBatteryGraphValues[]|null {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    return sensorDataModule.sensorDataTempHumidityOfDateRange(
      this.sensor.id,
      dayjs(this.dateStart)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      dayjs(this.dateEnd)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(0),
    );
  }

  private get sensorDataVoltageAxisMesure(): VoltageAxisGraphValues[]|null {
    // eslint-disable-next-line no-unused-expressions
    this.forceUpdate;

    return sensorDataModule.sensorDataVoltageAxisOfDateRange(
      this.sensor.id,
      dayjs(this.dateStart)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      dayjs(this.dateEnd)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(0),
    );
  }

  /**
   * Get sensor datas.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get sensorData() {
    return sensorDataModule.sensorDataById(this.sensor.id);
  }

  /**
   * Sensor data max graph data.
   *
   * @readonly
   * @memberof MesuresTabs
   */
  private get sensorDataMax() {
    if (this.sensorData !== null) {
      return this.sensorData.lower_graph.values;
    }

    return [];
  }

  /**
   * PDF name.
   *
   * @readonly
   * @memberof MesuresTabs
   */
  public get pdfName() {
    return `export_capteur_${this.sensor.id}_${this.now.format('DD_MM_YYYY')}`;
  }

  /**
   * HTML to PDF options.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get htmlToPdfOptions() {
    return {
      margin: 0,
      filename: this.pdfName,
      image: {
        type: 'jpeg',
        quality: 0.98,
      },
      enableLinks: false,
      html2canvas: {
        scale: 2,
        useCORS: true,
      },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'landscape',
      },
    };
  }

  private get actionDownloadData() {
    if (this.sensorData !== null) {
      return `${this.sensorData.report_url}&start=${dayjs(this.dateStart).unix()}&stop=${dayjs(this.dateEnd).unix()}`;
    }

    return '';
  }
}
