import { Site } from '@/api/SensorModels';

export interface GeolocationResult {
  formatted_address: string;
  name: string;
  geometry: {
    location: {
      lat: () => void;
      lng: () => void;
    };
    viewport: {
      La: {
        g: number;
        i: number;
      };
      Sa: {
        g: number;
        i: number;
      };
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertArrayToObject(array: Array<any>, key: string) {
  const initialValue = {};

  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    initialValue
  );
}

/**
 * Geolocate an address with the Google Maps API.
 *
 * @export
 * @param {string} address
 */
export function geolocateAddress(
  address: string,
  callback: (ok: boolean, datas: GeolocationResult[]) => void
) {
  window.googleMaps.findPlaceFromQuery(
    {
      query: address,
      fields: ['name', 'geometry', 'formatted_address'],
    },
    (results: GeolocationResult[], status: string) => {
      callback(status === 'OK' && results.length > 0, results);
    }
  );

  return null;
}

/**
 * Group by elements of a list by a key.
 *
 * @export
 * @param {Array<any>} list
 * @param {string} key
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy(list: Array<any>, key: string) {
  return list.reduce((rv, x) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const crv = rv as any;

    (crv[x[key]] = crv[x[key]] || []).push(x);

    return crv;
  }, {});
}

/**
 * Sort sensors by cities and by geolocation from north to south.
 *
 * @export
 * @param {Site[]} sensors
 * @returns {Site[]}
 */
export function sortSensors(sensors: Site[]): Site[] {
  const groupedSensors: { [key: string]: Site[] } = {};
  const result: Site[] = [];

  // Group by city.
  sensors.forEach((s) => {
    if (s.address.city in groupedSensors) {
      groupedSensors[s.address.city].push(s);
    } else {
      groupedSensors[s.address.city] = [s];
    }
  });

  // Sort by latitude.
  Object.keys(groupedSensors).forEach((key) => {
    groupedSensors[key] = groupedSensors[key].sort((s1, s2) => {
      if (s1.geolocation.latitude < s2.geolocation.latitude) {
        return 1;
      }
      if (s1.geolocation.latitude > s2.geolocation.latitude) {
        return -1;
      }
      return 0;
    });

    result.push(...groupedSensors[key]);
  });

  return result;
}

/**
 * All locales.
 */
export const ALL_LOCALES = [
  {
    slug: 'fr',
    name: 'Français',
  },
  {
    slug: 'en',
    name: 'English',
  },
  {
    slug: 'es',
    name: 'Español',
  },
];

/**
 * Get current locale for the user.
 * @returns {string}
 */
export function getLocale(): string {
  const locale = localStorage.getItem('locale');
  const localesAvailable = ALL_LOCALES.map(({ slug }) => slug);

  if (locale !== null && localesAvailable.includes(locale)) {
    return locale;
  }

  if (/^fr\b/.test(navigator.language)) {
    return 'fr';
  } if (/^es\b/.test(navigator.language)) {
    return 'es';
  }

  return 'en';
}

/**
 * Sets the preferred locale.
 * @param locale
 */
export function setLocale(locale: string) {
  localStorage.setItem('locale', locale);

  // Need to reload the page to handle full locale change.
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}
