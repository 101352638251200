import SensorApi from '@/api/SensorApi';
import { Geolocation, Site, SiteAntenna } from '@/api/SensorModels';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

/**
 * Default radius for antennas of current sensor.
 */
const DEFAULT_RADIUS_CURRENT_SENSOR = 250;

@Module
export default class AntennasModule extends VuexModule {
  /**
   * List of all antennas.
   *
   * @private
   * @type {SiteAntenna[]}
   * @memberof AntennasModule
   */
  private antennas: SiteAntenna[] = [];

  /**
   * Antennas for current sensor.
   *
   * @private
   * @type {SiteAntenna[]}
   * @memberof AntennasModule
   */
  private antennasCurrentSensor: {
    [sensorId: string]: SiteAntenna[];
  } = {};

  /**
   * Set antennas.
   *
   * @private
   * @param {SiteAntenna[]} antennas
   * @memberof AntennasModule
   */
  @Mutation
  private setAntennas(antennas: SiteAntenna[]) {
    this.antennas = antennas;
  }

  /**
   * Set antennas for current sensor.
   *
   * @private
   * @param {{sensorId: string; antennas: SiteAntenna[]}} { sensorId, antennas }
   * @memberof AntennasModule
   */
  @Mutation
  private setAntennasCurrentSensor({ sensorId, antennas }: {sensorId: string; antennas: SiteAntenna[]}) {
    this.antennasCurrentSensor[sensorId] = antennas;
  }

  /**
   * Get antennas.
   *
   * @param {{session: SessionModule; geo: Geolocation; radius: number}} { session, geo, radius }
   * @returns
   * @memberof AntennasModule
   */
  @Action
  public async getAntennas({ session, geo, radius }: {session: SessionModule; geo: Geolocation; radius: number}) {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const antennas = await SensorApi.getInstance().getAntennas(token, geo, radius);

    if (antennas !== null) {
      this.setAntennas(antennas);
    }

    return antennas;
  }

  /**
   * Get antennas for current sensor.
   *
   * @param {{session: SessionModule; geo: Geolocation}} { session, geo }
   * @returns
   * @memberof AntennasModule
   */
  @Action
  public async getAntennasForCurrentSensor({ session, sensor }: {session: SessionModule; sensor: Site}) {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const antennas = await SensorApi.getInstance().getAntennas(token, sensor.geolocation, DEFAULT_RADIUS_CURRENT_SENSOR);

    if (antennas !== null) {
      this.setAntennasCurrentSensor({
        sensorId: sensor.id,
        antennas,
      });
    }

    return antennas;
  }

  /**
   * Clear the lists of antennas.
   *
   * @memberof AntennasModule
   */
  @Action
  public async clearAntennas() {
    this.setAntennas([]);
  }

  /**
   * Get antennas.
   *
   * @readonly
   * @memberof AntennasModule
   */
  public get allAntennas() {
    return this.antennas;
  }

  /**
   * Antennas for a sensor.
   *
   * @readonly
   * @memberof AntennasModule
   */
  public get antennasSensor() {
    return (id: string) => {
      if (!Object.keys(this.antennasCurrentSensor).includes(id)) {
        return null;
      }

      return this.antennasCurrentSensor[id];
    };
  }
}
