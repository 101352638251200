import { AccountTag } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import Loader from '@/components/Helpers/Loader';
import ChartHelper from '@/helpers/ChartHelper';
import VueHtml2pdf from '@/libs/vue-html2pdf.vue';
import { globalReportModule, sessionModule } from '@/store';
import { i18n } from '@/translations';
import Template from '@Templates/components/Page/GlobalReport/GlobalReport.vhtml';
import dayjs, { Dayjs } from 'dayjs';
import { Chart } from 'highcharts-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import GlobalReportPDF from './GlobalReportPDF';

@Template
@Component({
  components: {
    Icon,
    Chart,
    Loader,
    VueHtml2pdf,
    GlobalReportPDF,
  },
})
export default class GlobalReport extends Vue {
  /**
   * Dates min in calendar.
   *
   * @private
   * @memberof GlobalReport
   */
  private dateMin = dayjs()
    .subtract(1, 'week')
    .toDate();

  /**
   * Date max in calendar.
   *
   * @private
   * @memberof GlobalReport
   */
  private dateMax = dayjs().toDate();

  /**
   * Dates DayJS format.
   *
   * @private
   * @memberof MesuresTabs
   */
  private datesDayJS = {
    start: dayjs().subtract(1, 'week'),
    end: dayjs(),
  };

  /**
   * Date max for calendar.
   *
   * @private
   * @memberof GlobalReport
   */
  private maxDate = dayjs().toDate();

  /**
   * Date min for calendar.
   *
   * @private
   * @memberof GlobalReport
   */
  private minDate = dayjs()
    .subtract(1, 'year')
    .toDate();

  /**
   * Message from the API.
   *
   * @private
   * @type {(string|null)}
   * @memberof GlobalReport
   */
  private message: string | null = null;

  /**
   * If loading.
   *
   * @private
   * @memberof GlobalReport
   */
  private loading = true;

  /**
   * If view need refresh.
   *
   * @private
   * @memberof GlobalReport
   */
  private needRefresh = false;

  /**
   * Tags.
   *
   * @private
   * @type {AccountTag[]}
   * @memberof GlobalReport
   */
  private tags: AccountTag[] = [];

  /**
   * Selected tags
   *
   * @private
   * @type {string[]}
   * @memberof GlobalReport
   */
  private selectedTags: string[] = [];

  /**
   * If all selected tags.
   *
   * @private
   * @type {boolean}
   * @memberof GlobalReport
   */
  private allSelected = true;

  /**
   * If dropdown tags is open.
   *
   * @private
   * @memberof GlobalReport
   */
  private dropdownOpen = false;

  /**
   * Date now for PDF.
   *
   * @private
   * @type {(Dayjs)}
   * @memberof GlobalReport
   */
  private now: Dayjs = dayjs();

  /**
   * Current session token.
   *
   * @private
   * @type {string|null}
   * @memberof GlobalReport
   */
  private sessionToken: string | null;

  /**
   * On date min change.
   *
   * @private
   * @memberof GlobalReport
   */
  @Watch('dateMin', { deep: true })
  private onDateMinChanged(value: Date) {
    this.needRefresh = true;
    this.datesDayJS = {
      ...this.datesDayJS,
      start: dayjs(value),
    };
  }

  /**
   * On date max change.
   *
   * @private
   * @memberof GlobalReport
   */
  @Watch('dateMax', { deep: true })
  private onDateMAxChanged(value: Date) {
    this.needRefresh = true;
    this.datesDayJS = {
      ...this.datesDayJS,
      end: dayjs(value),
    };
  }

  /**
   * On filter tags.
   *
   * @private
   * @memberof GlobalReport
   */
  @Watch('selectedTags')
  private onTagsChanged() {
    this.needRefresh = true;
  }

  /**
   * On check all selected.
   *
   * @private
   * @param {boolean} value
   * @memberof GlobalReport
   */
  @Watch('allSelected')
  private onAllSelected(value: boolean) {
    if (value) {
      this.selectAllTags();
    } else {
      this.unSelectAllTags();
    }
  }

  /**
   * Mounted event.
   *
   * @memberof GlobalReport
   */
  public async mounted() {
    window.addEventListener('click', this.closeDropdown);

    if (this.session.userAccount !== null) {
      this.tags = [...this.session.userAccount.tags];
      this.minDate = this.session.userAccount.creation_date.toDate();
      this.selectedTags = this.tags.map((tag) => tag.name);

      if (dayjs(this.dateMin).isBefore(dayjs(this.minDate))) {
        const date = dayjs(this.minDate).clone();

        this.dateMin = date.clone().toDate();
        this.datesDayJS.start = date;
      }
    }

    this.sessionToken = await sessionModule.getToken();

    if (globalReportModule.globalReportData === null) {
      await this.loadDatas();
    } else {
      this.loading = false;
      this.needRefresh = false;
    }
  }

  /**
   * Before destroy event.
   *
   * @memberof GlobalReport
   */
  public beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  }

  /**
   * Generate the PDF.
   *
   * @memberof DetailSensor
   */
  public generatePDF() {
    this.now = dayjs();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.html2Pdf as any).generatePdf();
  }

  /**
   * Toggle tags dropdown.
   *
   * @private
   * @memberof GlobalReport
   */
  private toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  /**
   * Close dropdown.
   *
   * @param {MouseEvent} e
   * @memberof GlobalReport
   */
  public closeDropdown() {
    this.dropdownOpen = false;
  }

  /**
   * Load datas for all charts.
   *
   * @memberof GlobalReport
   */
  public async loadDatas() {
    this.loading = true;
    this.needRefresh = false;

    await globalReportModule.getGlobalReportData({
      session: sessionModule,
      start: dayjs(this.dateMin)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0),
      end: dayjs(this.dateMax)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(0),
      tags: this.selectedTags.map((tag) => (tag === i18n.t('api.SensorModels.undefined') ? '' : tag)),
    });

    this.loading = false;
  }

  /**
   * Select all tags.
   *
   * @memberof GlobalReport
   */
  public selectAllTags() {
    if (this.session.userAccount) {
      this.selectedTags = this.session.userAccount?.tags.map((tag) => tag.name);
    }
  }

  /**
   * Unselected all tags.
   *
   * @memberof GlobalReport
   */
  public unSelectAllTags() {
    this.selectedTags = [];
  }

  /**
   * Get options chart 1.
   *
   * @readonly
   * @memberof GlobalReport
   */
  get optionsChart1() {
    let categories: string[] = [];
    let data: number[] = [];

    if (this.globalReportData !== null) {
      categories = this.globalReportData.left_graph.values.map((o) => o.label);
      data = this.globalReportData.left_graph.values.map((o) => o.value);
    }

    return {
      ...ChartHelper.getDefaultBarsChartOptions(
        this.globalReportData?.left_graph.title || '',
        this.globalReportData?.left_graph.ordinate || '',
        this.globalReportData?.left_graph.unit || ''
      ),
      xAxis: {
        categories,
      },
      series: [
        {
          name: 'MaxByYear',
          data,
        },
      ],
    };
  }

  /**
   * Get options chart 2.
   *
   * @readonly
   * @memberof GlobalReport
   */
  get optionsChart2() {
    let categories: string[] = [];
    let data: number[] = [];

    if (this.globalReportData !== null) {
      categories = this.globalReportData.right_graph.values.map((o) => o.label);
      data = this.globalReportData.right_graph.values.map((o) => o.count);
    }

    return {
      ...ChartHelper.getDefaultBarsChartOptions(
        this.globalReportData?.right_graph.title || '',
        this.globalReportData?.right_graph.ordinate || '',
        this.globalReportData?.right_graph.unit || ''
      ),
      xAxis: {
        categories,
      },
      series: [
        {
          name: 'DeviceCount',
          data,
        },
      ],
    };
  }

  /**
   * Get current session.
   *
   * @readonly
   * @private
   * @memberof GlobalReport
   */
  private get session() {
    return sessionModule;
  }

  /**
   * Data for charts.
   *
   * @readonly
   * @private
   * @memberof GlobalReport
   */
  private get globalReportData() {
    return globalReportModule.globalReportData;
  }

  /**
   * PDF name.
   *
   * @readonly
   * @memberof MesuresTabs
   */
  public get pdfName() {
    return `export_${this.now.format('DD_MM_YYYY')}`;
  }

  /**
   * HTML to PDF options.
   *
   * @readonly
   * @private
   * @memberof MesuresTabs
   */
  private get htmlToPdfOptions() {
    return {
      margin: 0,
      filename: this.pdfName,
      image: {
        type: 'jpeg',
        quality: 0.98,
      },
      enableLinks: false,
      html2canvas: {
        scale: 2,
        useCORS: true,
      },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'landscape',
      },
    };
  }

  private get titleChart1PDF() {
    if (this.globalReportData !== null) {
      return this.globalReportData.left_graph.title;
    }

    return '';
  }

  private get titleChart2PDF() {
    if (this.globalReportData !== null) {
      return this.globalReportData.right_graph.title;
    }

    return '';
  }

  private get selectAllLabel() {
    if (this.allSelected) {
      return this.$t(
        'templates.components.components.Page.GlobalReport.GlobalReport.unselect_all'
      );
    }

    return this.$t(
      'templates.components.components.Page.GlobalReport.GlobalReport.select_all'
    );
  }
}
