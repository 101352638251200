<header class="w-full h-top-bar fixed top-0 left-0 right-0 z-1005 shadow-md bg-white flex items-center">
  <div class="container mx-auto px-4 h-full flex items-center justify-between gap-6">
    <div class="flex w-full h-full items-center justify-between">
      <div class="flex items-center">
        <img :src="logo" class="h-9 object-contain" :alt="$t('templates.components.components.Layout.Header.exem')">
        <div class="mx-8 h-9 w-px bg-light-gray hidden lg:block" v-if="account && showAccountPicture"></div>
        <img @error="onAccountPictureError" :src="account.picture_url" v-if="account && showAccountPicture" class="hidden lg:block w-32 h-12 object-cover" :alt="account.name">
      </div>
      <Menu class="hidden lg:flex"/>
    </div>
    <div class="flex h-full items-center">
      <SideMenu v-if="isLogged" />
    </div>
  </div>
</header>
