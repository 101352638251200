<template>
  <div class="filterWrapper moreFilters">
    <button class="no-button title" @click="toggle">
      <span class="text-wrapper">
        <span class="text-label">{{ text }}</span>
        <i class="hidden-desktop dsio-sliders-h-light more-filters-icon" />
        <span v-if="nbContextFilters" class="nbChecked">
          <span class="count">{{ nbContextFilters }}</span>
        </span>
        <i
          v-if="!isMobileScreen"
          :class="[isOpen ? 'dsio-angle-up icon-arrow' : 'dsio-angle-down icon-arrow']"
        />

        <span v-if="isOpen && isMobileScreen" class="nb-results" v-html="counter" />
      </span>
      <i v-if="isOpen && isMobileScreen" class="dsio-close close" />
    </button>
  </div>
</template>
<script>
  export default {
    name: 'DsioMore',
    props: {
      hiddenFilterIds: {
        type: Array,
        default: () => [],
      },
      nbMore: {
        type: Number,
        default: 0,
      },
      counter: {
        type: String,
        default: '',
      },
    },
    computed: {
      text() {
        if (['mobile', 'tablet'].indexOf(this.$root.breakpoint) > -1) {
          return this.t.plusDeFiltresMobile || 'Filtres';
        }

        return this.t.plusDeFiltres || 'Plus de filtres';
      },
      nbContextFilters() {
        if (!this.hiddenFilterIds.length) {
          return 0;
        }

        let filters = this.$store.state[this.instanceId].Request;

        let nb = 0;
        for (let index in this.hiddenFilterIds) {
          const filterId = this.hiddenFilterIds[index];
          if (filters[filterId] && filters[filterId].contextValues) {
            nb += filters[filterId].contextValues.length;
          }
        }

        return nb;
      },
      isOpen: {
        get() {
          return this.$store.state[this.instanceId].moreFilters;
        },
        set(val) {
          this.$store.commit(this.instanceId + '/UPDATE_MORE_FILTERS', val);
        },
      },
    },
    methods: {
      toggle() {
        this.isOpen = !this.isOpen;
      },
    },
  };
</script>
