<ul class="flex items-center h-full gap-10">
  <li class="flex items-center h-full">
    <router-link
      tag="a" :to="{ name: 'list-sensor' }"
      class="no-hover flex justify-center h-full items-center menu-item"
      :class="{'active': $route.name === 'list-sensor' || $route.name === 'detail-sensor'}">
      {{ $t('templates.components.Menu.sensor_list') }}
    </router-link>
  </li>
  <li class="flex items-center h-full">
    <router-link
      tag="a"
      :to="{ name: 'global-report' }" class="no-hover flex justify-center h-full items-center menu-item"
      :class="{'active': $route.name === 'global-report'}">
      {{ $t('templates.components.Menu.analysis') }}
    </router-link>
  </li>
</ul>
