<details :open="open" class="border-b" :disabled="disabled">
  <summary class="px-8 py-5 flex items-center justify-between gap-4 transition-colors" @click.prevent="onClick" :class="{'text-light-green': !disabled && open, 'hover:text-light-green': !disabled, 'bg-back-gray cursor-not-allowed': disabled}">
    <div class="flex items-center gap-4">
      <Icon :name="icon" :width="25" :height="25" />
      <span>{{ title }}</span>
    </div>
    <Icon :class="{'rotate-90': open}" class="chevron-animate transform transition-transform duration-300" name="chevron-right" :width="6" :height="12" v-if="!disabled" />
  </summary>
  <div class="mb-6" v-show="!disabled">
    <slot />
  </div>
</details>
