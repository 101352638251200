import GlobalReport from '@/components/Page/GlobalReport/GlobalReport';
import { i18n } from '@/translations';
import DetailSensor from '@/views/Sensor/DetailSensor';
import ListSensor from '@/views/Sensor/ListSensor';
import LoginView from '@/views/User/LoginView';
import LogoutView from '@/views/User/LogoutView';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    name: 'login',
    path: '/login',
    component: LoginView,
    meta: {
      title: 'router.pages.login',
      keepAlive: false,
      includeLayout: false,
      includeFooter: true,
    },
  },
  {
    name: 'logout',
    path: '/logout',
    component: LogoutView,
    meta: {
      title: 'router.pages.logout',
      keepAlive: false,
      includeLayout: false,
      includeFooter: false,
    },
  },
  {
    name: 'list-sensor',
    path: '/sensors',
    component: ListSensor,
    meta: {
      title: 'router.pages.sensor_list',
      keepAlive: true,
      includeLayout: true,
      includeFooter: false,
    },
  },
  {
    name: 'global-report',
    path: '/analysis',
    component: GlobalReport,
    meta: {
      title: 'router.pages.analysis',
      keepAlive: false,
      includeLayout: true,
      includeFooter: true,
    },
  },
  {
    name: 'detail-sensor',
    path: '/sensor/:id',
    component: DetailSensor,
    meta: {
      title: 'router.pages.sensor_detail',
      keepAlive: false,
      includeLayout: true,
      includeFooter: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// Change title of the page
router.beforeEach((to, from, next) => {
  const genericTitle = i18n.t('router.title').toString();

  if (to.meta.title) {
    const translatedTitle = i18n.t(to.meta.title);
    document.title = `${translatedTitle} | ${genericTitle}`;
  } else {
    document.title = genericTitle;
  }

  next();
});

export default router;
