import Template from '@Templates/components/Helpers/Errors.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component
export default class Errors extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  private errors: string[];
}
