<div class="h-full w-screen h-screen lg:overflow-hidden bg-login">
  <div class="flex items-start justify-end p-6 lg:p-20">
    <div class="bg-white grid grid-cols-1 lg:grid-cols-2 w-full xl:w-1/2 rounded-md shadow-lg py-16 px-6 lg:py-0 lg:px-0 bg-login-exem">
      <div class="flex items-center justify-center lg:border-r">
        <img :src="logo" :alt="$t('templates.views.User.LoginView.exem')" class="w-56">
      </div>
      <div class="mt-16 lg:mt-0 px-0 lg:p-16">
        <h1 class="text-center font-bold mb-8">{{ $t('templates.views.User.LoginView.login') }}</h1>
        <Errors :errors="errors" class="mb-10"/>
        <form @submit.prevent="tryLogin">
          <input type="email" name="email" id="email" :placeholder="$t('templates.views.User.LoginView.your_email')" required class="block border rounded bg-light-blue-input px-5 py-3 w-full mb-4 text-text-input placeholder-text-input text-sm" v-model="email">
          <input type="password" name="password" id="password" :placeholder="$t('templates.views.User.LoginView.your_password')" required class="block border bg-light-blue-input rounded px-5 py-3 w-full mb-7 text-text-input placeholder-text-input text-sm" v-model="password">
          <div class="mb-7">
            <label for="remember" class="text-sm text-gray-light-text flex items-center justify-center container-checkbox">
              <input id="remember" type="checkbox" v-model="remember" />
              <span class="square-checkbox mr-4">
                  <Icon name="check" class="square-checkbox-icon" :width="16" :height="16"/>
                </span>
              <span class="text-left">{{ $t('templates.views.User.LoginView.remember_me') }}</span>
            </label>
          </div>
          <div class="flex justify-center">
            <button class="button-light-blue-full" type="submit">{{ $t('templates.views.User.LoginView.action_login') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Footer :background="false" :light="true" class="w-full footer-login"/>
</div>
