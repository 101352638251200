<template>
  <div
    class="filterWrapper textSearch md:items-center flex-col md:flex-row md:col-span-5 hidden md:flex"
  >
    <div class="placeholder mr-3" @click="focus">{{ name }}</div>
    <div class="border-b w-full flex items-center justify-between">
      <input
        ref="textInput"
        v-model.lazy="tempSearch"
        type="search"
        :name="id"
        @focus="focus"
        @blur="blur"
        @submit="submit"
        @keydown="lastSearchValue = null"
        @keyup.enter="submit"
        class="w-full bg-transparent"
        placeholder="Saisissez une adresse"
      />
      <button @click="submit">
        <Icon name="search" :width="20" :height="20" />
      </button>
    </div>
  </div>
</template>

<script>
  import baseEngines from '@Diffusio/js/components/engines/baseEngines';
  import Icon from '@/components/Helpers/Icon';

  export default {
    name: 'TextSearch',
    components: { Icon },
    mixins: [baseEngines],
    props: {
      name: {
        type: String,
        default: '',
      },
      requestValue: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        focused: false,
        lastSearchValue: null,
        tempSearch: '',
      };
    },
    computed: {
      enabledResetButton() {
        if (this.search) {
          return !this.focused || this.search === this.lastSearchValue;
        }
        return false;
      },
      search: {
        get() {
          return this.filterData || '';
        },
        set(value) {
          this.filterData = this.lastSearchValue = value;

          this.updateContext(value ? [{ id: value, name: value }] : '');
        },
      },
    },
    watch: {
      search(val) {
        this.tempSearch = val;
      },
    },
    created() {
      // If requestValue is not empty
      if (this.requestValue !== null) {
        // Restore selection
        this.search = this.requestValue;
      }
    },
    methods: {
      focus() {
        this.focused = true;
        this.$refs.textInput.focus();
      },
      blur() {
        this.focused = false;
      },
      submit() {
        this.search = this.tempSearch;
      },
    },
  };
</script>
