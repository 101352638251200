import { FilterAntennas, SiteAntenna } from '@/api/SensorModels';
import Icon from '@/components/Helpers/Icon';
import { antennasModule, sessionModule } from '@/store';
import getArrow from '@/templates/components/Pin/Arrow';
import getClusterPinAntenna from '@/templates/components/Pin/ClusterPinAntenna';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/PinFilterAntennas.vhtml';
import PinAntenna from '@Templates/components/Pin/PinAntenna.html';
import L, { MarkerClusterGroup } from 'leaflet';
import { clamp, difference, uniq } from 'lodash';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { LMap } from 'vue2-leaflet';

@Template
@Component({
  components: { Icon },
})
export default class PinFilterAntennas extends Vue {
  /**
   * All technos types.
   */
  public readonly TECHNOS = {
    MOBILE: ['2G', '3G', '4G', '5G'],
    TV: ['TV', 'TDF', 'TNT', 'RDF DVB-T', 'DVB-T', 'DVB'],
    FM: ['FM', 'RNT', 'FM - RNT'],
    PRIVATE: ['PMR'],
  };

  /**
   * Technos as array.
   */
  public TECHNOS_ARRAY: string[] = [];

  /**
   * All operators types;
   */
  public readonly OPERATORS = {
    BOUYGUES: 'bouygues',
    SFR: 'sfr',
    ORANGE: 'orange',
    FREE: 'free',
  };

  /**
   * Operators as array.
   */
  public OPERATORS_ARRAY: string[] = [];

  /**
   * The map to add pins.
   */
  @Prop({ type: Object, required: true })
  private map: LMap;

  /**
   * List of all antennas markers.
   */
  private markers: L.Marker[] = [];

  /**
   * Cluster group.
   */
  private groupAntennas: MarkerClusterGroup | null = null;

  /**
   * List of arrows orientations.
   */
  private arrows: L.Marker[] = [];

  /**
   * Operator markers on arrows.
   */
  private arrowsOperatorMarkers: L.Marker[] = [];

  /**
   * All current filters.
   */
  private currentFilters: FilterAntennas = {
    is2g: true,
    is3g: true,
    is4g: true,
    is5g: true,
    isFm: true,
    isTv: true,
    isPrivate: true,
    isOther: true,
    isOrange: true,
    isFree: true,
    isBouygues: true,
    isSfr: true,
    isOtherOperator: true,
  };

  /**
   * If show antennas on the map.
   */
  private showAntennas = false;

  /**
   * If a request antennas is started.
   */
  private requestAntennasStarted = false;

  /**
   * If display antennas.
   */
  private displayAntennas = false;

  /**
   * Checkbox for all antennas.
   */
  private allAntennas = true;

  /**
   * Checkbox for all operators.
   */
  private allOperators = true;

  /**
   * If show directions.
   */
  private showDirections = false;

  /**
   * If display arrows.
   */
  private displayArrows = false;

  /**
   * If loading antennas.
   */
  private loadingAntennas = false;

  /**
   * For checking check all or not operators.
   */
  private operatorUncheck = false;

  /**
   * For checking check all or not signals.
   */
  private signalsUncheck = false;

  /**
   * Current bounds of the map.
   */
  private currentBounds: L.LatLngBounds;

  /**
   * On filter checked.
   *
   * @param {FilterAntennas} value
   * @memberof DsioPinFilter
   */
  @Watch('currentFilters', { deep: true })
  public async onCurrentFiltersChanged(value: FilterAntennas) {
    this.loadingAntennas = true;

    if (
      !value.is2g ||
      !value.is3g ||
      !value.is4g ||
      !value.is5g ||
      !value.isFm ||
      !value.isTv ||
      !value.isPrivate ||
      !value.isOther ||
      !value.isFree ||
      !value.isSfr ||
      !value.isOrange ||
      !value.isBouygues ||
      !value.isOtherOperator
    ) {
      this.signalsUncheck = true;
      this.allAntennas = false;
    } else {
      this.signalsUncheck = false;
      this.allAntennas = true;
    }

    if (
      !value.isFree ||
      !value.isOrange ||
      !value.isBouygues ||
      !value.isSfr ||
      !value.isOtherOperator ||
      !value.is2g ||
      !value.is3g ||
      !value.is4g ||
      !value.is5g
    ) {
      this.operatorUncheck = true;
      this.allOperators = false;
    } else {
      this.operatorUncheck = false;
      this.allOperators = true;
    }

    await this.redrawAntennas();
    this.loadingAntennas = false;
  }

  /**
   * Of checkbox "showAntennas" changes.
   *
   * @memberof DsioPinFilter
   */
  @Watch('showAntennas')
  public onShowAntennasChanged() {
    this.update(this.currentBounds);
  }

  /**
   * On all antennas checked.
   *
   * @param {boolean} value
   * @memberof DsioPinFilter
   */
  @Watch('allAntennas')
  public onAllAntennasChanged(value: boolean) {
    if (value) {
      this.currentFilters = {
        ...this.currentFilters,
        is5g: true,
        is3g: true,
        is4g: true,
        is2g: true,
        isFm: true,
        isTv: true,
        isPrivate: true,
        isOther: true,
        isFree: true,
        isOrange: true,
        isSfr: true,
        isBouygues: true,
        isOtherOperator: true,
      };
    } else if (value === false && !this.signalsUncheck) {
      this.allOperators = false;
      this.currentFilters = {
        ...this.currentFilters,
        is5g: false,
        is3g: false,
        is4g: false,
        is2g: false,
        isFm: false,
        isTv: false,
        isPrivate: false,
        isOther: false,
        isFree: false,
        isOrange: false,
        isSfr: false,
        isBouygues: false,
        isOtherOperator: false,
      };
    }
  }

  /**
   * On all operators checked.
   *
   * @param {boolean} value
   * @memberof DsioPinFilter
   */
  @Watch('allOperators')
  public onAllOperatorsChanged(value: boolean) {
    if (value === true) {
      this.currentFilters = {
        ...this.currentFilters,
        is5g: true,
        is3g: true,
        is4g: true,
        is2g: true,
        isOrange: true,
        isFree: true,
        isBouygues: true,
        isSfr: true,
        isOtherOperator: true,
      };
    } else if (value === false && !this.operatorUncheck) {
      this.allAntennas = false;
      this.currentFilters = {
        ...this.currentFilters,
        is5g: false,
        is3g: false,
        is4g: false,
        is2g: false,
        isOrange: false,
        isFree: false,
        isBouygues: false,
        isSfr: false,
        isOtherOperator: false,
      };
    }
  }

  /**
   * On show directions filter changed.
   *
   * @private
   * @memberof DsioPinFilter
   */
  @Watch('showDirections')
  private async onShowDirectionsChanged() {
    this.loadingAntennas = true;
    await this.redrawAntennas();
    this.loadingAntennas = false;
  }

  /**
   * On component mounted.
   */
  mounted() {
    Object.values(this.TECHNOS).forEach((t) => {
      this.TECHNOS_ARRAY = this.TECHNOS_ARRAY.concat(t);
    });

    this.OPERATORS_ARRAY = Object.values(this.OPERATORS);
  }

  /**
   * Update antennas.
   * @param bounds
   */
  public async update(bounds: L.LatLngBounds) {
    this.currentBounds = bounds;

    if (this.showAntennas) {
      this.loadingAntennas = true;

      if (this.map.mapObject.getZoom() > 15 && !this.requestAntennasStarted) {
        const center = bounds.getCenter();
        const radius = Math.round(
          bounds.getCenter().distanceTo(bounds.getNorthEast())
        );
        this.displayAntennas = true;

        this.requestAntennasStarted = true;
        const response = await antennasModule.getAntennas({
          session: sessionModule,
          geo: {
            latitude: center.lat,
            longitude: center.lng,
          },
          radius,
        });

        if (response !== null) {
          await this.redrawAntennas();
        }

        this.requestAntennasStarted = false;
      } else {
        this.displayAntennas = false;
        antennasModule.clearAntennas();
        await this.redrawAntennas();
      }

      this.loadingAntennas = false;
    } else {
      this.markers.forEach((marker) => {
        marker.removeFrom(this.map.mapObject);
      });

      this.arrows.forEach((arrow) => {
        arrow.removeFrom(this.map.mapObject);
      });

      this.arrowsOperatorMarkers.forEach((marker) => {
        marker.removeFrom(this.map.mapObject);
      });

      this.markers = [];
      this.arrows = [];
      this.arrowsOperatorMarkers = [];
    }
  }

  /**
   * Draw all the orientations.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @memberof DsioPinFilter
   */
  private drawOrientationsForAntenna(antenna: SiteAntenna) {
    const origin = antenna.location;
    let hasTechno = false;
    let hasOperator = false;

    if (this.antennaHasSignal(antenna, '2G') && this.currentFilters.is2g) {
      hasTechno = true;
    }

    if (this.antennaHasSignal(antenna, '3G') && this.currentFilters.is3g) {
      hasTechno = true;
    }

    if (this.antennaHasSignal(antenna, '4G') && this.currentFilters.is4g) {
      hasTechno = true;
    }

    if (this.antennaHasSignal(antenna, '5G') && this.currentFilters.is5g) {
      hasTechno = true;
    }

    if (hasTechno) {
      if (this.antennaHasFree(antenna) && this.currentFilters.isFree) {
        hasOperator = true;
      }

      if (this.antennaHasOrange(antenna) && this.currentFilters.isOrange) {
        hasOperator = true;
      }

      if (this.antennaHasSfr(antenna) && this.currentFilters.isSfr) {
        hasOperator = true;
      }

      if (this.antennaHasBouygues(antenna) && this.currentFilters.isBouygues) {
        hasOperator = true;
      }
    }

    if (hasOperator || hasTechno) {
      antenna.antennes.forEach((a) => {
        const markerClasses: string[] = ['arrow-operator'];

        this.OPERATORS_ARRAY.forEach((operator) => {
          if (a.name.toLowerCase().includes(operator)) {
            markerClasses.push(`has-${operator}`);
          }
        });

        a.emetteurs.forEach((emetteur) => {
          emetteur.orientations.forEach((orientation) => {
            const arrowIcon = getArrow(orientation - 90);
            const marker = new L.Marker(
              {
                lat: origin.latitude,
                lng: origin.longitude,
              },
              {
                icon: new L.DivIcon({
                  html: arrowIcon,
                  iconSize: [80, 10],
                  iconAnchor: [0, 10],
                  className: markerClasses.join(' '),
                }),
              }
            );

            this.arrows.push(marker);
            marker.addTo(this.map.mapObject);
          });
        });
      });
    }
  }

  /**
   * Repopulate the map with antennas points.
   *
   * @returns
   * @memberof DsioPinFilter
   */
  public async redrawAntennas() {
    return new Promise<void>((resolve) => {
      this.markers.forEach((marker) => {
        marker.removeFrom(this.map.mapObject);
      });

      this.arrows.forEach((arrow) => {
        arrow.removeFrom(this.map.mapObject);
      });

      this.arrowsOperatorMarkers.forEach((marker) => {
        marker.removeFrom(this.map.mapObject);
      });

      this.arrows = [];
      this.arrowsOperatorMarkers = [];

      // Delete current markers.
      if (this.groupAntennas !== null) {
        this.groupAntennas.removeFrom(this.map.mapObject);
        this.markers = [];
      }

      // Create cluster groups.
      this.groupAntennas = L.markerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 25,
        iconCreateFunction(cluster) {
          return L.divIcon({
            html: getClusterPinAntenna(cluster.getChildCount()),
            iconSize: [20, 24],
            iconAnchor: [10, 10],
          });
        },
      });

      this.antennas.forEach((antenna) => {
        const icons = this.getIconForAntenna(antenna);

        if (
          icons.types.length > 0 ||
          (icons.opetators.length > 0 && icons.signalType.length > 0)
        ) {
          const marker = new L.Marker(
            {
              lat: antenna.location.latitude,
              lng: antenna.location.longitude,
            },
            {
              icon: new L.DivIcon({
                html: PinAntenna,
                className: `no-pointer ${icons.opetators.join(
                  ' '
                )} ${icons.types.join(' ')} ${icons.signalType.join(' ')} has-${
                  icons.elementCount
                }-elements`,
                iconSize: [20 * icons.elementCount, 20],
                iconAnchor: [(20 * icons.elementCount) / 2, 10],
              }),
            }
          );

          this.markers.push(marker);
          marker.addTo(this.groupAntennas as L.LayerGroup);

          if (this.map.mapObject.getZoom() > 16 && this.showDirections) {
            this.displayArrows = true;
            this.drawOrientationsForAntenna(antenna);
          } else {
            this.displayArrows = false;
          }
        }
      });

      this.groupAntennas.addTo(this.map.mapObject);

      resolve();
    });
  }

  /**
   * Get all icons classes for antennas.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private getIconForAntenna(antenna: SiteAntenna) {
    const opetators = [];
    const signalType = [];
    const types = [];
    let has2G = false;
    let has3G = false;
    let has4G = false;
    let has5G = false;
    let elementCount = 0;

    if (this.antennaHasSignal(antenna, '2G') && this.currentFilters.is2g) {
      signalType.push('has-2g');
      has2G = true;
    }

    if (this.antennaHasSignal(antenna, '3G') && this.currentFilters.is3g) {
      signalType.push('has-3g');
      has3G = true;
    }

    if (this.antennaHasSignal(antenna, '4G') && this.currentFilters.is4g) {
      signalType.push('has-4g');
      has4G = true;
    }

    if (this.antennaHasSignal(antenna, '5G') && this.currentFilters.is5g) {
      signalType.push('has-5g');
      has5G = true;
    }

    if (has2G || has3G || has4G || has5G) {
      let hasOperator = false;

      if (this.antennaHasFree(antenna) && this.currentFilters.isFree) {
        opetators.push('has-free');
        opetators.push('has-operator');
        hasOperator = true;
      }

      if (this.antennaHasOrange(antenna) && this.currentFilters.isOrange) {
        opetators.push('has-orange');
        opetators.push('has-operator');
        hasOperator = true;
      }

      if (this.antennaHasSfr(antenna) && this.currentFilters.isSfr) {
        opetators.push('has-sfr');
        opetators.push('has-operator');
        hasOperator = true;
      }

      if (this.antennaHasBouygues(antenna) && this.currentFilters.isBouygues) {
        opetators.push('has-bouygues');
        opetators.push('has-operator');
        hasOperator = true;
      }

      if (
        this.antennaHasOtherOperator(antenna) &&
        this.currentFilters.isOtherOperator
      ) {
        opetators.push('has-other-operator');
        elementCount += 1;
      }

      if (hasOperator) {
        elementCount += 1;
      }
    }

    if (this.antennaIsTV(antenna) && this.currentFilters.isTv) {
      types.push('has-tv');
      elementCount += 1;
    }

    if (this.antennaIsFM(antenna) && this.currentFilters.isFm) {
      types.push('has-fm');
      elementCount += 1;
    }

    if (this.antennaIsPrivate(antenna) && this.currentFilters.isPrivate) {
      types.push('has-private');
      elementCount += 1;
    }

    if (this.antennaIsOther(antenna) && this.currentFilters.isOther) {
      types.push('has-other');
      elementCount += 1;
    }

    return {
      opetators: uniq(opetators),
      types,
      signalType: uniq(signalType),
      elementCount: clamp(elementCount, 1, 6),
    };
  }

  /**
   * Is antenna from Free.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaHasFree(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.name.toLowerCase().includes(this.OPERATORS.FREE)
      ).length > 0
    );
  }

  /**
   * Is antenna from Orange.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaHasOrange(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.name.toLowerCase().includes(this.OPERATORS.ORANGE)
      ).length > 0
    );
  }

  /**
   * Is antenna from SFR.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaHasSfr(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.name.toLowerCase().includes(this.OPERATORS.SFR)
      ).length > 0
    );
  }

  /**
   * Is antenna from Bouygues.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaHasBouygues(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.name.toLowerCase().includes(this.OPERATORS.BOUYGUES)
      ).length > 0
    );
  }

  /**
   * If antenna has other operator.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaHasOtherOperator(antenna: SiteAntenna) {
    const antennaOperators = antenna.antennes
      .filter((antenne) =>
        antenne.technos.some((r) => this.TECHNOS.MOBILE.includes(r))
      )
      .map((antenne) => antenne.name.toLowerCase())
      .map((name) => {
        let returnValue = name;

        this.OPERATORS_ARRAY.forEach((operator) => {
          if (name.includes(operator)) {
            returnValue = operator;
          }
        });

        return returnValue;
      })
      .filter((name) => name !== null);

    return difference(antennaOperators, this.OPERATORS_ARRAY).length > 0;
  }

  /**
   * If antenna is mobile.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaIsMobile(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.technos.some((r) => this.TECHNOS.MOBILE.includes(r))
      ).length > 0
    );
  }

  /**
   * Is antenna TV.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaIsTV(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.technos.some((r) => this.TECHNOS.TV.includes(r))
      ).length > 0
    );
  }

  /**
   * Is antenna Radio.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaIsFM(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.technos.some((r) => this.TECHNOS.FM.includes(r))
      ).length > 0
    );
  }

  /**
   * Is antenna private.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaIsPrivate(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter((antenne) =>
        antenne.technos.some((r) => this.TECHNOS.PRIVATE.includes(r))
      ).length > 0
    );
  }

  /**
   * Is antenna other.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaIsOther(antenna: SiteAntenna) {
    return (
      antenna.antennes.filter(
        (antenne) => difference(antenne.technos, this.TECHNOS_ARRAY).length > 0
      ).length > 0
    );
  }

  /**
   * If antenna has signal.
   *
   * @private
   * @param {SiteAntenna} antenna
   * @param {string} signal
   * @returns
   * @memberof DsioPinFilter
   */
  private antennaHasSignal(antenna: SiteAntenna, signal: string) {
    const technos = [signal];

    return (
      antenna.antennes.filter((antenne) =>
        antenne.technos.some((r) => technos.includes(r))
      ).length > 0
    );
  }

  /**
   * Get all antennas.
   *
   * @readonly
   * @private
   * @memberof DsioPinFilter
   */
  private get antennas() {
    return antennasModule.allAntennas;
  }
}
