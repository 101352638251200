export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {
      requestValue: '',
      contextValues: [],
    };
  },
  mutations: {
    UPDATE (state, value) {
      state.requestValue = value;
    },
    UPDATE_CONTEXT (state, value) {
      state.contextValues = value;
    },
  },
  actions: {
    /**
     * Supprime une valeur du filtre
     *
     * @param commit Envoie la modification
     * @param state Etat du filtre
     * @param value Valeur à supprimer
     */
    remove ({ commit, state }, value) {
      // Supprime value dans contextValues
      let newContext = state.contextValues.filter(activeValue => activeValue.id !== value);
      // Reconstruit requestValue
      let newRequest = newContext.map(value => value.id).join('~');
      commit('UPDATE', newRequest);
      commit('UPDATE_CONTEXT', newContext);
    },
  },
};
