import SensorApi from '@/api/SensorApi';
import { Coverage, CoverageOperator, Geolocation } from '@/api/SensorModels';
import { Module, Mutation, VuexModule } from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

@Module
export default class CoverageModule extends VuexModule {
  /**
   * The coverage.
   */
  private m_coverage: Coverage | null = null;

  /**
   * Is coverage active.
   */
  private isCoverageActive = false;

  /**
   * Set coverage.
   * @param coverage
   */
  @Mutation
  public setCoverage(coverage: Coverage) {
    this.m_coverage = coverage;
  }

  /**
   * Set coverage active.
   * @param active
   */
  @Mutation
  public setCoverageActive(active: boolean) {
    this.isCoverageActive = active;
  }

  /**
   * Get coverage
   * @param param
   * @returns
   */
  public async getCoverage({
    nw,
    operator,
    se,
    session,
    zoom,
  }: {
    nw: Geolocation;
    se: Geolocation;
    zoom: number;
    operator: CoverageOperator;
    session: SessionModule;
  }): Promise<Coverage | null> {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const coverage = await SensorApi.getInstance().getCoverage(
      token,
      nw,
      se,
      operator,
      zoom
    );

    if (coverage !== null) {
      this.setCoverage(coverage);
    }

    return coverage;
  }

  /**
   * Clear coverage.
   */
  @Mutation
  public clearCoverage() {
    this.m_coverage = null;
  }

  /**
   * Get coverage.
   * @returns
   */
  public get coverage() {
    return this.m_coverage ? this.m_coverage[1] : null;
  }

  /**
   * Get legend.
   * @returns
   */
  public get legend() {
    return this.m_coverage ? this.m_coverage[0] : null;
  }

  /**
   * Get coverage active.
   */
  public get coverageActive() {
    return this.isCoverageActive;
  }
}
