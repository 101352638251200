import { sessionModule } from '@/store';
import Template from '@Templates/components/Layout/Header.vhtml';
import { Component, Vue } from 'vue-property-decorator';
import Menu from './Menu';
import SideMenu from './SideMenu';

@Template
@Component({
  components: {
    SideMenu, Menu
  },
})
export default class Header extends Vue {
  private showAccountPicture = true;

  public mounted() {
    if (this.account && this.account?.picture_url) {
      this.showAccountPicture = true;
    }
  }

  public onAccountPictureError() {
    this.showAccountPicture = false;
  }

  /**
   * If user is logged.
   *
   * @readonly
   * @private
   * @memberof Header
   */
  private get isLogged() {
    return sessionModule.isUserAlreadyLogged;
  }

  /**
   * Get current user account.
   *
   * @private
   * @returns
   * @memberof Header
   */
  private get account() {
    return sessionModule.userAccount;
  }

  /**
   * Get the logo URL.
   *
   * @readonly
   * @private
   * @type {string}
   * @memberof Header
   */
  private get logo(): string {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@Assets/img/logo/logo_${this.$i18n.locale}.png`);
  }
}
