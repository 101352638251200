<template>
  <nav ref="pagination" v-html="html" />
</template>
<script>
  export default {
    name: 'DsioMods',
    props: {
      html: {
        type: String,
        default: '',
      },
    },
    computed: {
      pageNumber: {
        get() {
          return this.app.state.currentPage;
        },
        set(pageNumber) {
          this.dispatch('updateCurrentPage', pageNumber);
        },
      },
    },
    mounted() {
      this.$refs.pagination.addEventListener('click', (e) => {
        e.preventDefault();
        let target = e.target || e.srcElement;

        if (target.nodeName !== 'A') {
          target = target.parentNode || target.parentElement;
        }

        if (typeof target !== 'undefined') {
          const pageNumber = target
            ? parseInt(target.getAttribute('data-page'))
            : 1;
          if (pageNumber) {
            this.pageNumber = pageNumber;
          }
        }
      });
    },
  };
</script>
