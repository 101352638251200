import getClusterPinSensor from '@/templates/components/Pin/ClusterPinSensor';
import pinSensor from '@Assets/img/map/pin-sensor.svg';
import L from 'leaflet';
import Vue from 'vue';

export function createInstance(vueInstance: Vue) {
  return {
    app: {
      id: 'id1',
      params: {
        id: 'id1',
        paginationType: 'more',
        requestUrl: '',
        defaultViewMod: 'mixte',
        viewMods: ['list', 'mixte'],
        pageQty: 24,
        currentPage: 1,
        lang: 'fr',
        listConfig: [],
        listConfigFile: 'HOT-hotels',
        sortConfig: [],
        sortConfigFile: 'HOT-sort',
        defaultSortBy: 'defaut',
        filterConfig: [],
        filterConfigFile: 'HOT-filter',
        userPosition: false,
        oiId: false,
        detailPopup: false,
        basket: false,
        gf: false,
        noindexIfNoResults: false,
        ff: false,
        analyticsId: null,
        detailShareButtons: [
          'email',
          'facebook',
          'twitter',
          'pinterest',
          'google',
        ],
        rootUrl: '/diffusio-3',
        displayEmpty: true,
        autoFitBounds: false,
      },
      state: {
        currentPage: 1,
        viewMod: 'mixte',
        nbPages: 4,
        sortBy: 'defaut',
        total: 85,
      },
    },
    sorts: {
      defaut: 'Aléatoire',
    },
    filters: {
      geo: {
        name: vueInstance.$t('diffusio.DsioParams.geoloc'),
        id: 'geo',
        requestValue: [],
        component: 'MapFilter',
        engine: false,
        conf: {
          center: null,
          localisation: null,
          bounds: [
            {
              lat: '51.268318',
              lng: '9.8678344',
            },
            {
              lat: '41.2632185',
              lng: '-5.4534286',
            },
          ],
          autoFitBounds: false,
          keepModChangeMapState: false,
          useClusters: true,
          usePriceMarkers: false,
          useDivMarkers: false,
          markerPopupOptions: {
            autoPan: false,
            maxWidth: 500,
            offset: [0, -30],
          },
          markerClusterOptions: {
            showCoverageOnHover: false,
            maxClusterRadius: 30,
            iconCreateFunction(cluster: L.MarkerCluster) {
              return L.divIcon({
                html: getClusterPinSensor(cluster.getChildCount()),
                iconSize: [40, 48],
                iconAnchor: [20, 45],
              });
            },
          },
          markerIcon: {
            iconUrl: pinSensor,
            iconSize: [40, 48],
            iconAnchor: [20, 48],
          },
          markerIconHover: {
            iconSize: [40, 48],
            iconAnchor: [20, 48],
          },
          divIcon: {
            iconSize: [40, 48],
            iconAnchor: [20, 48],
          },
          divIconEmpty: {
            iconSize: [40, 48],
            iconAnchor: [20, 48],
          },
          priceIcon: {
            iconSize: [40, 48],
            iconAnchor: [20, 48],
          },
          priceIconEmpty: {
            iconSize: [40, 48],
            iconAnchor: [20, 48],
          },
          tileLayerControlOptions: [],
          tileLayerControlOptionsSm: {
            position: 'topleft',
          },
          tileLayers: [
            {
              id: 'plan_fr',
              name: 'Plan FR',
              url:  'https://api.mapbox.com/styles/v1/si-exem/ckpqnd5x923sh17t97u75y2vi/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2ktZXhlbSIsImEiOiJja29jbjg4OXowamU0Mm9uMXNoMnk1dTE5In0.kCmGh170wkrrCZuxJ1IK5g',
              attribution:
                '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data © <a href="http://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            },
            {
              id: 'plan_en',
              name: 'Plan EN',
              url: 'https://api.mapbox.com/styles/v1/si-exem/cldlibt2m001b01og9nyacj47/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2ktZXhlbSIsImEiOiJja29jbjg4OXowamU0Mm9uMXNoMnk1dTE5In0.kCmGh170wkrrCZuxJ1IK5g',
              attribution:
                '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data © <a href="http://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            },
            {
              id: 'plan_es',
              name: 'Plan ES',
              url: 'https://api.mapbox.com/styles/v1/si-exem/cldlif0jo001g01tdayfiagea/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2ktZXhlbSIsImEiOiJja29jbjg4OXowamU0Mm9uMXNoMnk1dTE5In0.kCmGh170wkrrCZuxJ1IK5g',
              attribution:
                '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data © <a href="http://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            },
          ],
          zoomControlOption: {
            position: 'bottomright',
          },
          mapOptions: [],
          layers: [],
        },
        mapItems: {},
      },
      etiquette: {
        name: vueInstance.$t('diffusio.DsioParams.tags'),
        id: 'etiquette',
        requestValue: null,
        component: 'FacetFilterCheckbox',
        engine: true,
        conf: {
          counter: true,
          displayEmpty: false,
        },
        relation: 'and',
        facets: {},
      },
      rayonnement: {
        name: vueInstance.$t('diffusio.DsioParams.exposition_level'),
        id: 'rayonnement',
        requestValue: null,
        component: 'RangeFilter',
        engine: true,
        conf: {
          min: 0,
          max: 6,
          interval: 0.1,
          formatter: '{value} V/m',
          value: [0, 6],
        },
      },
    },
    sortedIds: [],
    eventObject: [],
    staticConf: {
      trads: {
        viewMods: {
          list: vueInstance.$t('diffusio.DsioParams.list_view'),
          mixte: vueInstance.$t('diffusio.DsioParams.map_view'),
        },
        sortPrefix: 'Tri : ',
        plusDeFiltres: 'Plus de filtres',
        autourDeMoi: 'Autour <br>de moi',
        close: 'Fermer',
        partager: 'Partager',
        calendar: {
          apply: 'Appliquer',
          cancel: 'Réinitialiser',
        },
        share: {
          plus: 'Plus',
          moins: 'Moins',
        },
        userInfoModal: {
          cancel: 'Annuler',
          apply: 'Continuer',
          msg:
            "Afin d'afficher l'offre la plus proche de vous, nous allons vous demander votre géolocalisation.",
          title: 'À savoir...',
        },
      },
      detail: {
        shareButtons: [],
        mapConf: null,
      },
    },
    views: {
      list: {},
      pagination: '',
      counter: '',
    },
  };
}
