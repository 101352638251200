<template>
  <!-- dsio-app -->
  <div ref="appContainer" class="relative">
    <!-- app-container -->
    <div>
      <header>
        <!-- dsio-header--top -->
        <transition name="fade">
          <div
            class="lg:h-top-bar bg-medium-gray fixed lg:static z-1005 top-0 left-0 right-0 bottom-0 flex flex-col lg:flex-row justify-between"
            v-if="showFilters"
          >
            <div class="container mx-auto px-4 py-6">
              <div class="grid h-full grid-flow-row lg:grid-cols-2 gap-16">
                <dsio-engine
                  class="grid h-full grid-rows-2 grid-cols-1 lg:grid-cols-10 gap-12 lg:grid-rows-1"
                  :filters="filters"
                  :displayMore="moreFilters"
                  :counter="counter"
                  :nbVisibleFilters="
                    isMobileScreen && moreFilters ? 0 : nbVisibleFilters
                  "
                />
                <div class="flex justify-between gap-16">
                  <DsioCity v-if="showMods" class="flex-grow" />
                  <dsio-mods
                    v-if="showMods && filters.geo"
                    :viewMods="app.params.viewMods"
                    :defaultViewMod="defaultViewMod"
                    class="hidden lg:flex"
                  />
                </div>
              </div>
            </div>
            <div class="lg:hidden grid grid-cols-2 gap-2">
              <button class="button-light-blue mx-4 my-6" @click="closeFilters">
                {{ $t('diffusio.DiffusioApp.close') }}
              </button>
              <button
                class="button-light-blue-full mx-4 my-6"
                @click="closeFilters"
              >
                {{ $t('diffusio.DiffusioApp.search') }}
              </button>
            </div>
          </div>
        </transition>
        <!-- dsio-header--bottom -->
        <div
          class="container h-top-bar mx-auto flex lg:hidden flex-col px-4 py-6"
        >
          <div class="flex lg:flex-row flex-col lg:items-center">
            <div class="mb-2 flex justify-between items-center">
              <dsio-mods
                v-if="showMods && filters.geo"
                :viewMods="app.params.viewMods"
                :defaultViewMod="defaultViewMod"
                class="flex lg:hidden"
              />
              <button
                class="button-light-blue block lg:hidden"
                @click="showAllFilters"
              >
                {{ $t('diffusio.DiffusioApp.filter') }}
              </button>
            </div>
            <dsio-context-filters class="mt-8 lg:mt-0" />
          </div>
        </div>
      </header>
      <!-- app-body -->
      <div ref="appBody" class="grid grid-cols-1 xl:grid-cols-9 lg:grid-cols-8">
        <!-- list-wrapper -->
        <div
          v-show="listActive"
          :class="{
            loading,
            loadingMore,
            'hidden lg:block xl:col-span-3 lg:col-span-4 lg:h-map': mapActive,
            'lg:col-span-9 h-map': !mapActive,
          }"
        >
          <ModeSwitcher v-if="showModeSwitcher" ref="modeSwitcher">
            <Mode title="Supervision">
              <div class="px-4 lg:px-8 py-6">
                <div v-html="counter" class="mb-6 hidden lg:block"></div>
                <dsio-list
                  :list="list"
                  :loading="loading"
                  :isMapActive="mapActive"
                />
                <dsio-pagination v-if="pagination" :html="pagination" />
              </div>
            </Mode>

            <Mode title="City Manager">
              <DsioPinFilter ref="pinFilter" :map="map" />
            </Mode>
          </ModeSwitcher>

          <div v-else class="px-4 py-6 container mx-auto">
            <div v-html="counter" class="mb-6 hidden lg:block"></div>
            <dsio-list
              :list="list"
              :loading="loading"
              :isMapActive="mapActive"
            />
            <dsio-pagination v-if="pagination" :html="pagination" />
          </div>
        </div>
        <div
          v-if="filters.geo"
          v-show="mapActive"
          class="xl:col-span-6 lg:col-span-4 px-4 lg:px-0"
        >
          <div class="sticky dsio-map-height top-0 pb-4 lg:pb-0">
            <dsio-map
              ref="map"
              class="h-full"
              v-if="mapActive"
              :id="'geo'"
              :mapItems="mapItems"
              :bounds="
                mapConf.keepModChangeMapState && app.state.savedMapBounds
                  ? app.state.savedMapBounds
                  : bounds
              "
              :conf="mapConf"
              @update-bounds="onUpdateBounds"
              @map-ready="onMapReady"
              @popupOpen="mapPopupOpen = true"
              @popupClose="mapPopupOpen = false"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- dsio-detail -->
    <div v-if="app.params.detailPopup" v-show="oiId">
      <dsio-detail
        :oiId="oiId"
        :sortedIds="sortedIds"
        :conf="staticConf.detail"
      />
    </div>

    <transition name="fade">
      <PopupDetailSensor
        :sensorId="selectedSensor"
        v-if="selectedSensor !== null"
      />
    </transition>
  </div>
</template>

<script>
  import DsioPinFilter from '@/components/Page/ListSensor/DsioPinFilter/DsioPinFilter';
  import Mode from '@/components/Page/ListSensor/ModeSwitcher/Mode';
  import ModeSwitcher from '@/components/Page/ListSensor/ModeSwitcher/ModeSwitcher';
  import { sessionModule } from '@/store';
  import BaseScript from '@Diffusio/js/mixins/AppComponentBase';
  import PopupDetailSensor from '../../components/Page/ListSensor/PopupDetailSensor';
  import DsioCity from './components/DsioCity.vue';

  export default {
    mixins: [BaseScript],
    components: {
      DsioCity,
      PopupDetailSensor,
      ModeSwitcher,
      Mode,
      DsioPinFilter,
    },
    watch: {
      viewMod: function(val) {
        if (val === 'list') {
          this.$refs.modeSwitcher.selectMode(0);
        }
      },
    },
    computed: {
      showMods() {
        if (sessionModule.userAccount) {
          return sessionModule.userAccount.showMap;
        }

        return true;
      },
      showModeSwitcher() {
        if (sessionModule.userAccount) {
          return sessionModule.userAccount.showMap;
        }

        return true;
      },
    },
    data() {
      return {
        showFilters: false,
        selectedSensor: null,
        map: {},
      };
    },
    mounted() {
      this.$refs.appContainer.classList.add(
        this.viewMod || this.defaultViewMod
      );
      // window.addEventListener('resize', this.onResize, { passive: true });
      this.onResize();

      this.$root.$on('popup-select-sensor', (id) => {
        this.selectedSensor = id;

        if (id !== null) {
          const route = this.$router.resolve({
            name: 'detail-sensor',
            params: { id },
          });

          window.history.pushState({}, route.name, route.href);
        } else {
          const route = this.$router.resolve({ name: 'list-sensor' });

          window.history.pushState({}, route.name, route.href);
        }
      });

      window.addEventListener('popstate', this.onPopState);
    },
    beforeDestroy() {
      // window.removeEventListener('resize', this.onResize);
      window.removeEventListener('popstate', this.onPopState);
    },
    methods: {
      onMapReady() {
        this.map = this.$refs.map.$refs.DsioMap;
      },
      onUpdateBounds(bounds) {
        this.$refs.pinFilter.update(bounds);
      },
      showAllFilters() {
        this.showFilters = true;
      },
      closeFilters() {
        this.showFilters = false;
      },
      onPopState() {
        const href = window.location.pathname;
        const route = this.$router.resolve({ name: 'list-sensor' });

        if (href === route.href) {
          this.selectedSensor = null;
        }
      },
      onResize() {
        if (document.body.getBoundingClientRect().width > 768) {
          this.showFilters = true;
        } else {
          this.showFilters = false;
        }
      },
    },
  };
</script>
