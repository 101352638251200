<div>
  <div class="px-6 py-5 border-b text-sm bg-back-gray">{{ $t('templates.components.components.Helpers.DsioPinFilter.unfold') }}</div>

  <DsioPinFilterItem :open="isSensorAccordionOpen" :title="$t('templates.components.components.Helpers.DsioPinFilter.antennas')" icon="tower-cell" @open-accordion="openAccordion('sensor')">
    <PinFilterAntennas :map="map" ref="pinFilterAntennas" />
  </DsioPinFilterItem>

  <DsioPinFilterItem :disabled="isBuildingsDisabled" :open="isBuildingsAccordionOpen" :title="$t('templates.components.components.Helpers.DsioPinFilter.buildings')" icon="building" @open-accordion="openAccordion('buildings')">
    <PinFilterBuildings :map="map" ref="pinFilterBuildings" />
  </DsioPinFilterItem>

  <DsioPinFilterItem :disabled="isExpositionDisabled" :open="isExpositionAccordionOpen" :title="$t('templates.components.components.Helpers.DsioPinFilter.exposition')" icon="wifi-exclamation" @open-accordion="openAccordion('exposition')">
    <PinFilterExposition :map="map" ref="pinFilterExposition" />
  </DsioPinFilterItem>

  <DsioPinFilterItem :disabled="isCoverageDisabled" :open="isCoverageAccordionOpen" :title="$t('templates.components.components.Helpers.DsioPinFilter.coverage')" icon="chart-network" @open-accordion="openAccordion('coverage')">
    <PinFilterCoverage :map="map" ref="pinFilterCoverage" />
  </DsioPinFilterItem>
</div>
