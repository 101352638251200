import { sessionModule } from '@/store';
import Template from '@Templates/components/Page/ListSensor/DsioPinFilter/DsioPinFilter.vhtml';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { LMap } from 'vue2-leaflet';
import DsioPinFilterItem from './DsioPinFilterItem';
import PinFilterAntennas from './PinFilterAntennas';
import PinFilterBuildings from './PinFilterBuildings';
import PinFilterCoverage from './PinFilterCoverage';
import PinFilterExposition from './PinFilterExposition';

type OpenedAccordion = 'sensor' | 'buildings' | 'exposition' | 'coverage';

@Template
@Component({
  components: {
    DsioPinFilterItem,
    PinFilterAntennas,
    PinFilterBuildings,
    PinFilterExposition,
    PinFilterCoverage,
  },
})
export default class DsioPinFilter extends Vue {
  /**
   * The map to add pins.
   */
  @Prop({ type: Object, required: true })
  private map: LMap;

  /**
   * Which accordion is currently opened.
   */
  private openedAccordion: OpenedAccordion | null = null;

  /**
   * Open an accordion.
   * @param accordion
   */
  public openAccordion(accordion: OpenedAccordion): void {
    if (this.openedAccordion === accordion) {
      this.openedAccordion = null;
    } else {
      this.openedAccordion = accordion;
    }
  }

  /**
   * Fetch queryAntennas for new data.
   * @param bounds
   */
  public update(bounds: L.LatLngBounds) {
    const pinFilterAntennas = this.$refs.pinFilterAntennas as PinFilterAntennas;
    const pinFilterBuildings = this.$refs
      .pinFilterBuildings as PinFilterBuildings;
    const pinFilterExposition = this.$refs
      .pinFilterExposition as PinFilterExposition;
    const pinFilterCoverage = this.$refs.pinFilterCoverage as PinFilterCoverage;

    Promise.all([
      pinFilterAntennas.update(bounds),
      pinFilterBuildings.update(bounds),
      pinFilterExposition.update(bounds),
      pinFilterCoverage.update(bounds),
    ]);
  }

  public get isSensorAccordionOpen(): boolean {
    return this.openedAccordion === 'sensor';
  }

  public get isBuildingsAccordionOpen(): boolean {
    return this.openedAccordion === 'buildings';
  }

  public get isExpositionAccordionOpen(): boolean {
    return this.openedAccordion === 'exposition';
  }

  public get isCoverageAccordionOpen(): boolean {
    return this.openedAccordion === 'coverage';
  }

  public get isBuildingsDisabled() {
    return !sessionModule.userAccount?.can_see_buildings;
  }

  public get isExpositionDisabled() {
    return !sessionModule.userAccount?.can_see_exposition;
  }

  public get isCoverageDisabled() {
    return !sessionModule.userAccount?.can_see_coverage;
  }
}
