import dayjs, { Dayjs } from 'dayjs';
import { FeatureCollection, GeoJSON, LineString } from 'geojson';
import { trim } from 'lodash';

export interface RawAccount {
  alarm_max: number;
  id: string;
  subscription_end_date: number | null;
  contact_email: string;
  name: string;
  alarm_min: number;
  contact_name: string;
  tags: string[];
  picture_url: string;
  creation_date: number;
  can_see_temp_hum_battery: boolean;
  can_see_voltage_axis: boolean;
  can_see_field_level_without_alarm: boolean;
  can_see_buildings: boolean;
  can_see_coverage: boolean;
  can_see_exposition: boolean;
  showMap: boolean;
  initial_position: {
    latitude: number;
    longitude: number;
  };
}

export interface AccountTag {
  name: string;
  slug: string;
}

export interface Account {
  alarm_max: number;
  id: string;
  subscription_end_date: Dayjs | null;
  contact_email: string;
  name: string;
  alarm_min: number;
  contact_name: string;
  tags: AccountTag[];
  picture_url: string;
  creation_date: Dayjs;
  can_see_temp_hum_battery: boolean;
  can_see_voltage_axis: boolean;
  can_see_field_level_without_alarm: boolean;
  can_see_buildings: boolean;
  can_see_coverage: boolean;
  can_see_exposition: boolean;
  showMap: boolean;
  initial_position: {
    latitude: number;
    longitude: number;
  };
}

type SiteStatus = 'ONLINE' | 'MAINTENANCE' | 'OFFLINE';

export interface Site {
  address: {
    location: string;
    zip: string;
    city: string;
    country: string;
  };
  status: SiteStatus;
  current_value: number | null;
  geolocation: Geolocation;
  id: string;
  installation_date: Dayjs;
  lastCommDate: Dayjs;
  picture_url: string[];
  public_news: PublicNew[];
  unit: string;
  name: string;
  tags: AccountTag[];
  mesure_labo: {
    niveau_global_exposition: number;
    measure_date: number;
    protocole_a: Protocole;
    protocole_b: Protocole;
    resuts: ProtocoleResultat[];
  };
}

export interface RawSite {
  address: string;
  status: SiteStatus;
  current_value: number | null;
  geolocation: Geolocation;
  id: string;
  installation_date: number;
  lastCommDate: number;
  picture_url: string[];
  public_news: RawPublicNew[];
  unit: string;
  name: string;
  tags: string[];
  mesure_labo: {
    niveau_global_exposition: number;
    measure_date: number;
    protocole_a: Protocole;
    protocole_b: Protocole;
    resuts: ProtocoleResultat[];
  };
}

export interface Protocole {
  title: string;
  body: string;
}

export interface ProtocoleResultat {
  bandwidth: string;
  bandwidth_sub: string;
  limite: string;
  mesure: string;
  service: string;
  subname: string;
}

export interface Geolocation {
  latitude: number;
  longitude: number;
}

export interface RawPublicNew {
  creation_date: number;
  description: string;
  title: string;
}

export interface PublicNew {
  creation_date: Dayjs;
  description: string;
  title: string;
}

export interface RawSiteData {
  raw_datas: RawSiteRawDataMesure[];
}

export interface RawSiteRawDataMesure {
  id: string;
  upper_graph: RawSiteUpperGraph;
  lower_graph: SiteLowerGraph;
  temp_humidity_battery_graph: RawHumidityBatteryGraph;
  voltage_axis_graph: RawVoltageAxisGraph;
  field_value_graph_attribute: RawFieldValueGraphAttribute;
  report_url: string;
  max_min_avg: {
    max: number;
    min: number;
    avg: number;
  };
}

export interface RawSiteUpperGraph {
  title: string;
  ordinate: string;
  unit: string;
  values: RawSiteUpperGraphValues[];
  regulated_limit: number;
  site_explanation_title: string;
  site_explanation_text: string;
  maintenance_periods: { start: number; stop: number }[];
}

export interface RawSiteUpperGraphValues {
  date: number;
  value: number;
}

export interface SiteData {
  raw_datas: SiteRawDataMesure[];
}

export interface SiteRawDataMesure {
  id: string;
  is_full: boolean;
  upper_graph: SiteUpperGraph;
  lower_graph: SiteLowerGraph;
  temp_humidity_battery_graph: HumidityBatteryGraph;
  voltage_axis_graph: VoltageAxisGraph;
  field_value_graph_attribute: FieldValueGraphAttribute;
  report_url: string;
  max_min_avg: {
    max: number;
    min: number;
    avg: number;
  };
}

export interface SiteLowerGraph {
  title: string;
  ordinate: string;
  unit: string;
  values: SiteLowerGraphValues[];
}

export interface SiteLowerGraphValues {
  label: string;
  value: number;
}

export interface SiteUpperGraph {
  title: string;
  ordinate: string;
  unit: string;
  values: SiteUpperGraphValues[];
  regulated_limit: number;
  site_explanation_title: string;
  site_explanation_text: string;
  maintenance_periods: { start: Dayjs | null; stop: Dayjs | null }[];
}

export interface SiteUpperGraphValues {
  date: Dayjs;
  value: number;
}

export interface RawHumidityBatteryGraph {
  values: RawHumidityBatteryGraphValues[];
  ordinate: string;
  title: string;
  unit: string;
  legend: HumidityBatteryLegends;
}

export interface HumidityBatteryGraph {
  values: HumidityBatteryGraphValues[];
  ordinate: string;
  title: string;
  unit: string;
  legend: HumidityBatteryLegends;
}

export interface HumidityBatteryLegends {
  temperature: HumidityBatteryLegend;
  humidity: HumidityBatteryLegend;
  voltage_battery: HumidityBatteryLegend;
}

export interface HumidityBatteryLegend {
  label: string;
  color: string;
}

export interface RawHumidityBatteryGraphValues {
  date: number;
  temperature: number;
  humidity: number;
  voltage_battery: number;
}

export interface HumidityBatteryGraphValues {
  date: Dayjs;
  temperature: number;
  humidity: number;
  voltage_battery: number;
}

export interface RawVoltageAxisGraph {
  values: RawVoltageAxisGraphValues[];
  ordinate: string;
  title: string;
  unit: string;
  legend: VoltageAxisGraphLegends;
}

export interface VoltageAxisGraph {
  values: VoltageAxisGraphValues[];
  ordinate: string;
  title: string;
  unit: string;
  legend: VoltageAxisGraphLegends;
}

export interface VoltageAxisGraphLegends {
  voltage_x: VoltageAxisGraphLegend;
  voltage_y: VoltageAxisGraphLegend;
  voltage_z: VoltageAxisGraphLegend;
}

export interface VoltageAxisGraphLegend {
  label: string;
  color: string;
}

export interface RawVoltageAxisGraphValues {
  date: number;
  voltage_x: number;
  voltage_y: number;
  voltage_z: number;
}

export interface VoltageAxisGraphValues {
  date: Dayjs;
  voltage_x: number;
  voltage_y: number;
  voltage_z: number;
}

export interface RawFieldValueGraphAttribute {
  values: RawFieldValueGraphAttributeValue[];
  ordinate: string;
  title: string;
  unit: string;
  legend: FieldValueGraphLegends;
}

export interface RawFieldValueGraphAttributeValue {
  date: number;
  quadratic_6: number;
  quadratic_3: number;
}

export interface FieldValueGraphAttribute {
  values: FieldValueGraphAttributeValue[];
  ordinate: string;
  title: string;
  unit: string;
  legend: FieldValueGraphLegends;
}

export interface FieldValueGraphAttributeValue {
  date: Dayjs;
  quadratic_6: number;
  quadratic_3: number;
}

export interface FieldValueGraphLegends {
  quadratic_3: FieldValueGraphLegend;
  quadratic_6: FieldValueGraphLegend;
}

export interface FieldValueGraphLegend {
  label: string;
  color: string;
}

export interface FilterDiffusio {
  geo: {
    ne: Geolocation;
    sw: Geolocation;
  };
  intensity: {
    min: number;
    max: number;
  };
  tags: string[];
}

export interface FilterAntennas {
  is5g: boolean;
  is4g: boolean;
  is3g: boolean;
  is2g: boolean;
  isFm: boolean;
  isTv: boolean;
  isPrivate: boolean;
  isOther: boolean;
  isOrange: boolean;
  isFree: boolean;
  isBouygues: boolean;
  isSfr: boolean;
  isOtherOperator: boolean;
}

export interface RawSiteAntenna {
  id: string;
  nature: string;
  location: string;
  hauteur: number;
  proprietaire: string;
  lieu: string;
  adresse1: string;
  adresse2: string;
  adresse3: string;
  codePostal: string;
  commune: string;
  distance: number;
  antennes: RawAntenna[];
}

export interface RawAntenna {
  technos: string[];
  name: string;
  emetteurs: RawTransmitter[];
}

export interface RawTransmitter {
  system: string;
  hauteur: number;
  dateImplantation: number | null;
  dateModification: number | null;
  orientations: number[];
  techno: string;
}

export interface SiteAntenna {
  id: string;
  nature: string;
  location: Geolocation;
  hauteur: number;
  proprietaire: string;
  lieu: string;
  adresse1: string;
  adresse2: string;
  adresse3: string;
  codePostal: string;
  commune: string;
  distance: number;
  antennes: Antenna[];
}

export interface Antenna {
  technos: string[];
  name: string;
  emetteurs: Transmitter[];
}

export interface Transmitter {
  system: string;
  hauteur: number;
  dateImplantation: Dayjs | null;
  dateModification: Dayjs | null;
  orientations: number[];
  techno: string;
}

export interface BuildingDefault {
  id: string;
  description: string;
  building_type: string;
  height: number;
  is_public: boolean;
}

export interface BuildingPoint extends BuildingDefault {
  type: 'point';
  point: number[];
}

export interface BuildingGeoJson extends BuildingDefault {
  type: 'geojson';
  geojson: GeoJSON;
  name: string;
}

export type Building = BuildingGeoJson | BuildingPoint;

export interface GlobalReportData {
  max: Max;
  left_graph: GlobalReportLeftGraph;
  right_graph: GlobalReportRightGraph;
  avg_max: number;
  alarms: number;
  atypical_sites_count: number;
  report_url: string;
  max_min_avg_per_device: {
    [id: string]: {
      max: number;
      min: number;
      avg: number;
    };
  };
}

export interface GlobalReportLeftGraph {
  title: string;
  ordinate: string;
  unit: string;
  values: GlobalReportLeftGraphValues[];
}

export interface GlobalReportLeftGraphValues {
  value: number;
  label: string;
}

export interface GlobalReportRightGraph {
  title: string;
  ordinate: string;
  unit: string;
  values: GlobalReportRightGraphValues[];
}

export interface GlobalReportRightGraphValues {
  label: string;
  count: number;
  devices: Device[];
}

export interface Device {
  device: string;
  value: number;
}

export interface Max {
  max: number;
  date: number;
  site_id: string;
}

export interface Exposition {
  0: {
    start: string;
    color1: string;
    color2: string;
    color3: string;
    end: string;
  };
  1: FeatureCollection<LineString>;
}

export type CoverageOperator = 'orange' | 'free' | 'bouygues' | 'sfr';

export interface Coverage {
  0: {
    start: string;
    color1: string;
    color2: string;
    color3: string;
    end: string;
  };
  1: FeatureCollection<LineString>;
}

export const transformRawAccountToAccount = (
  rawAccount: RawAccount,
  undefinedTagName: string
): Account => ({
  ...rawAccount,
  subscription_end_date:
    rawAccount.subscription_end_date !== null
      ? dayjs.unix(rawAccount.subscription_end_date)
      : null,
  creation_date: dayjs.unix(rawAccount.creation_date),
  tags: rawAccount.tags.map((tag) => ({
    name: tag !== '' ? tag : undefinedTagName,
    slug: tag !== '' ? tag : undefinedTagName,
  })),
});

export const transformRawSiteToSite = (
  rawSite: RawSite,
  undefinedTagName: string
): Site => ({
  ...rawSite,
  address: {
    location: rawSite.address.split(',')[0].trim(),
    zip: rawSite.address
      .split(',')[1]
      .trim()
      .split(' ')[0]
      .trim(),
    city: rawSite.address
      .split(',')[1]
      .trim()
      .split(' ')[1],
    country: rawSite.address.split(',')[2].trim(),
  },
  lastCommDate: dayjs.unix(rawSite.lastCommDate),
  installation_date: dayjs.unix(rawSite.installation_date),
  tags: rawSite.tags.map((tag) => {
    if (tag === '') {
      return { name: undefinedTagName, slug: undefinedTagName };
    }

    return { name: tag, slug: tag };
  }),
  public_news: rawSite.public_news
    .map(
      (publicNews: RawPublicNew): PublicNew => ({
        ...publicNews,
        creation_date: dayjs.unix(publicNews.creation_date),
      })
    )
    .sort((n1, n2) => {
      if (n1.creation_date.isBefore(n2.creation_date)) {
        return -1;
      }
      if (n1.creation_date.isAfter(n2.creation_date)) {
        return 1;
      }

      return 0;
    }),
});

export const transformRawSiteDataToSiteData = (
  rawSiteData: RawSiteData
): SiteData => ({
  raw_datas: rawSiteData.raw_datas.map((rawDatas) => ({
    ...rawDatas,
    is_full: false,
    upper_graph: {
      ...rawDatas.upper_graph,
      maintenance_periods: rawDatas.upper_graph.maintenance_periods.map(
        (mp) => ({
          start: mp.start !== null ? dayjs.unix(mp.start) : null,
          stop: mp.stop !== null ? dayjs.unix(mp.stop) : null,
        })
      ),
      values: rawDatas.upper_graph.values.map((value) => ({
        date: dayjs.unix(value.date),
        value: value.value,
      })),
    },
    temp_humidity_battery_graph: {
      ...rawDatas.temp_humidity_battery_graph,
      values: rawDatas.temp_humidity_battery_graph.values.map((v) => ({
        ...v,
        date: dayjs.unix(v.date),
      })),
    },
    voltage_axis_graph: {
      ...rawDatas.voltage_axis_graph,
      values: rawDatas.voltage_axis_graph.values.map((v) => ({
        ...v,
        date: dayjs.unix(v.date),
      })),
    },
    field_value_graph_attribute: {
      ...rawDatas.field_value_graph_attribute,
      values: rawDatas.field_value_graph_attribute.values.map((v) => ({
        ...v,
        date: dayjs.unix(v.date),
      })),
    },
  })),
});

export const transformRawSiteAntennaToSiteAntenna = (
  rawSiteAntenna: RawSiteAntenna
): SiteAntenna => ({
  ...rawSiteAntenna,
  location: ((): Geolocation => {
    const values = rawSiteAntenna.location.split(',').map(trim);

    return {
      latitude: parseFloat(values[0]),
      longitude: parseFloat(values[1]),
    };
  })(),
  antennes: rawSiteAntenna.antennes.map((antenne) => ({
    ...antenne,
    emetteurs: antenne.emetteurs.map((emetteur) => ({
      ...emetteur,
      dateImplantation:
        emetteur.dateImplantation !== null
          ? dayjs.unix(emetteur.dateImplantation)
          : null,
      dateModification:
        emetteur.dateModification !== null
          ? dayjs.unix(emetteur.dateModification)
          : null,
    })),
  })),
});
