<template>
  <div v-click-outside="closePopup" class="DateFilter filterWrapper" :class="{ open: popupOpen }">
    <div class="lgrid-noGutter-noWrap title-wrapper">
      <!--dates-->
      <div>
        <button class="title" :class="{'active': isActive}" @click="openPopup">
          <span v-html="placeholder" />
        </button>
      </div>
      <!--personnes-->
      <div class="person-count--wrapper">
        <button class="title person-count" :class="{'active': isActive}" @click="openPersons">
          <span>
            <i class="dsio-user-alt-solid" />
            {{ nbPersons }}
          </span>
        </button>
      </div>
    </div>
    <div class="filter--popup">
      <date-picker
        v-if="popupOpen"
        v-model="date"
        :placeholder="placeholder"
        :mode="mode"
        :options="conf.calendarOptions"
        :forceRange="conf.forceRange"
        @apply="apply"
        @close="closePopup"
      >
        <template v-slot:header>
          <div>
            <div class="person-count title">
              <i class="dsio-user-alt-solid" />
              <input
                ref="persons"
                v-model="nbPersons"
                width="50"
                class="person-count-input"
                type="number"
                :min="personsMin"
                :max="personsMax"
              />
            </div>
          </div>
        </template>
      </date-picker>
    </div>
  </div>
</template>

<script>
  import DateFilter from '@Diffusio/js/components/engines/DateFilter';
  import { isset, isEmpty } from '@Diffusio/js/utils';

  export default {
    name: 'DispoFilter',
    extends: DateFilter,
    data() {
      return {
        nbPersons: this.conf.persons || this.conf.personsMin || 1,
        personsMin: this.conf.personsMin || 1,
        personsMax: this.conf.personsMax || 100,
      };
    },
    watch: {
      nbPersons(value) {
        if (value > this.personsMax) {
          this.nbPersons = this.personsMax;
        } else if (value < this.personsMin) {
          this.nbPersons = this.personsMin;
        }
      },
    },
    methods: {
      parseRequestValue(requestValue) {
        const { libelle, start, end, nbPersons } = requestValue;
        this.libelle = libelle;
        this.nbPersons = nbPersons;
        // Parse de la date en instance de Date pour le calendrier
        const parsedDate = this.parseRequestDate({ start, end });
        if (this.mode === 'range' && isset(end)) {
          this.date = parsedDate;
        } else {
          this.date = parsedDate.start;
        }
        this.updateFilter([...this.formatDateFilter(parsedDate), nbPersons]);
      },
      openPersons() {
        this.openPopup();
        this.$nextTick(() => {
          this.$refs.persons.focus();
        });
      },
      /**
       * Applique les données du filtres
       */
      apply() {
        let dataFilter = this.formatDateFilter(this.date);
        if (!isEmpty(dataFilter)) {
          dataFilter.push(this.nbPersons);
        }
        this.updateFilter(dataFilter);
        this.closePopup();
      },
    },
  };
</script>
