<div class="flex flex-col h-full">
  <ul class="px-4 py-6 lg:px-8 lg:py-6 bg-white border-b border-t grid grid-flow-col gap-6">
    <li v-for="(mode, index) in modes" :key="mode.id" class="">
      <button type="button" @click="selectMode(index)" class="w-full button-light-blue" :class="{'button-light-blue-full': index === selectedIndex}">
        {{ mode.title }}
      </button>
    </li>
  </ul>
  <div class="overflow-y-auto flex-grow">
    <slot></slot>
  </div>
</div>
