import Template from '@Templates/components/Layout/Footer.vhtml';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Template
@Component
export default class Footer extends Vue {
  @Prop({
    required: true,
    type: Boolean,
  })
  private readonly background: boolean;

  @Prop({
    required: true,
    type: Boolean,
  })
  private readonly light: boolean;
}
