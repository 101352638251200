/**
 * class Tracking
 * gerer l'envoi des évenements tracking
 */
class Tracking {
  constructor () {
    this.system = this.getTrackingSystem();
    this.pageOpenedOnce = false;
  }

  /**
   * retourne le system de tracking
   * ex analytics, tag manager
   */
  getTrackingSystem () {
    if (typeof gtag !== 'undefined') {
      return 'gtag';
    } else if (typeof dataLayer !== 'undefined') {
      return 'gtm';
    } else if (typeof ga !== 'undefined') {
      return 'ga';
    } else if (typeof _gaq !== 'undefined') {
      return 'gaq';
    }

    return null;
  }

  sendPage (trackingObject) {
    // En raison de l'async de tarte au citron, on reverifie
    // 1 seule fois à l'ouverture detail
    if (!this.pageOpenedOnce && !this.system) {
      this.system = this.getTrackingSystem();
      this.pageOpenedOnce = true;
    }

    if (this.system) {
      switch (this.system) {
        case 'ga': // analytics universal
          // ga('send', 'pageview', window.location.href);
          ga('send', 'pageview', window.location.pathname);
          break;
        case 'gtag': // analytics universal 2019
          const analyticsId = trackingObject.analyticsId;
          delete trackingObject.analyticsId;

          if (analyticsId) {
            gtag('config', analyticsId, {
              'page_path': trackingObject.url || window.location.pathname,
              'page_title': trackingObject.title || document.title,
            });
          } else {
            console.error('Renseigner le l\'identifiant analytics');
          }
          break;
        case 'gtm':
          dataLayer.push({
            'event': 'pageview',
            'url': window.location.href,
            'url_path': window.location.pathname,
            'title': document.title,
            ...trackingObject,
          });
          break;
      }
    }
  }

  /**
   * envoi le tracking au system
   * @param opts
   */
  send (opts) {
    if (this.system) {
      switch (this.system) {
        case 'ga':
          // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
          ga(
            'send',
            'event',
            opts.category,
            opts.action,
            opts.label,
            opts.value,
          );
          break;
        case 'gtag':
          // @see https://developers.google.com/tag-manager/devguide#multipush
          gtag('event', opts.action, {
            'event_category': opts.category,
            'event_label': opts.label,
            'value': opts.value,
          });
          break;
        case 'gtm':
          // @see https://developers.google.com/tag-manager/devguide#multipush
          dataLayer.push({
            'event': opts.category,
            'action': opts.action,
            'label': opts.label,
            'value': opts.value,
          });
          break;
      }
    }
  }
}

export default new Tracking();
