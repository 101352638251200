module.exports = {
  purge: {
    content: [
      './public/**/*.html',
      './src/**/*.vue',
      './src/**/*.vhtml',
      './src/**/*.html',
    ],
    layers: ['base', 'components', 'utilities'],
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontFamily: {
      sans: ['Open Sans', 'sans-serif'],
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      DEFAULT: theme('colors.border-gray', 'currentColor'),
    }),
    extend: {
      colors: {
        'black-overlay': 'rgba(0, 0, 0, .5)',
        'light-blue-overlay': 'rgba(97, 211, 237, .75)',
        'light-blue-input': '#F2FBFD',
        'light-blue': '#61D3ED',
        'sky-blue': '#CEF0F7',
        'back-gray': '#F5F7F6',
        'btn-gray': '#EBEDEC',
        'btn-gray-hover': '#DEE3E1',
        orange: '#F07331',
        'light-green': '#76DEA0',
        'light-gray': '#E5E5E5',
        'border-gray': '#EDEDED',
        'border-gray-light': 'rgba(237, 237, 237, .16)',
        'medium-gray': '#F7F7F7',
        'gray-text': '#5F7176',
        'gray-light-text': '#848484',
        'gray-lighter-text': '#BEBEBE',
        'table-even': '#FAFAFA',
        'text-input': '#58595B',
        'gray-dark': '#E8EDEB',
        'gray-darker': '#DEE3E1',
        'gray-very-darker': '#D5DBD9',
        'scrollbar-thumb': '#757677',
      },
      spacing: {
        'top-bar': '5rem',
        map: 'calc(100vh - 10rem)',
        'map-filters': 'calc(100vh - 15rem)',
        'w-print-page': '29.7cm',
        'h-print-page': '21cm',
        38: '9.5rem',
        54: '13.9rem',
      },
      zIndex: {
        1004: 1004,
        1005: 1005,
        1006: 1006,
      },
      keyframes: {
        zoom: {
          '0%': {
            transform: 'scale(0)',
          },
          '100%': {
            transform: 'scale(1)',
          },
        },
        opacity: {
          '0%': {
            opacity: '0',
          },
          '100%': {
            opacity: '1',
          },
        },
        bounce: {
          '0%': { transform: 'scale(1,1) translateY(0)' },
          '10%': { transform: 'scale(1.1,.9) translateY(0)' },
          '30% ': { transform: 'scale(.9,1.1) translateY(-50px)' },
          '50%': { transform: 'scale(1.05,.95) translateY(0)' },
          '57%': { transform: 'scale(1,1) translateY(-7px)' },
          '64%': { transform: 'scale(1,1) translateY(0)' },
          '100%': { transform: 'scale(1,1) translateY(0)' },
        },
        spinner: {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
        gradient: {
          '0%': {
            backgroundPosition: '0% 50%',
          },
          '50%': {
            backgroundPosition: '100% 50%',
          },
          '100%': {
            backgroundPosition: '0% 50%',
          },
        },
      },
      animation: {
        zoom: 'zoom 300ms ease-in-out',
        opacity: 'opacity 300ms linear',
        bounce: 'bounce 2s ease infinite',
        spinner: 'spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
        gradient: 'gradient 15s ease infinite',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
};
