<section class="pdf-item text-black w-w-print-page h-h-print-page border py-6 px-8">
  <div class="flex items-center justify-between">
    <div>
      <h1 class="font-semibold">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.report_sensor') }} <span class="text-light-green">#{{ sensor.name }}</span></h1>
    </div>
    <div>
      <img src="@Assets/img/pdf/logo.png" class="h-full object-contain" width="159px" height="39px" :alt="$t('templates.components.components.Page.DetailSensor.ReportSensorPDF.exem')">
      <img :src="account.picture_url" alt="Exem" width="133px" height="40px" onerror="this.style.display = 'none'">
    </div>
  </div>

  <div class="grid grid-cols-6 mt-6 gap-3">
    <div class="col-span-2">
      <div class="flex flex-col">
        <div class="border rounded p-6 grid grid-rows-2 gap-6 h-52 mb-4">
          <div class="flex items-center">
            <div class="mr-8 text-border-gray">
              <img src="@Assets/img/pdf/pin.png" width="30px" height="42px">
            </div>
            <div>
              <p class="font-semibold">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.localisation') }}</p>
              <p>
                {{ formatSensorAddress(sensor.address.location) }}<br>
                {{ sensor.address.zip }} {{ sensor.address.city }} ({{ sensor.address.country }})
              </p>
            </div>
          </div>
          <div class="flex items-center">
            <div class="mr-8 text-border-gray">
              <img src="@Assets/img/pdf/calendar.png" width="30px" height="42px">
            </div>
            <div>
              <p class="font-semibold">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.installation_date') }}</p>
              <p>{{ sensor.installation_date.format('DD/MM/YYYY') }}</p>
            </div>
          </div>
        </div>
        <div class="h-52">
          <img :src="getImage(sensor.picture_url)" @error="onImgError" class="w-full object-cover rounded h-52" alt="">
        </div>
      </div>
    </div>

    <div class="col-span-4">
      <div class="border px-5 py-3 rounded mb-3">
        <h2 class="font-light text-center mb-4">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.period_start') }} {{ dates.start.format('DD/MM/YYYY') }} {{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.period_end') }} {{ dates.end.format('DD/MM/YYYY') }}</h2>
        <highcharts :options="optUpperGraph" class="h-60"></highcharts>
        <div class="flex items-center justify-evenly mt-1" v-if="extremums !== null">
          <div class="flex items-center justify-center">
            <p class="mr-3 text-xs">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.avg_value') }}</p>
            <p class="text-light-blue text-xl">{{ extremums.avg.toFixed(2) }} V/m</p>
          </div>
          <div class="flex items-center justify-center">
            <p class="mr-3 text-xs">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.min_value') }}</p>
            <p class="text-light-blue text-xl">{{ extremums.min }} V/m</p>
          </div>
          <div class="flex items-center justify-center">
            <p class="mr-3 text-xs">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.max_value') }}</p>
            <p class="text-light-blue text-xl">{{ extremums.max }} V/m</p>
          </div>
        </div>
      </div>
      <div class="border px-5 py-3 rounded">
        <h2 class="font-light text-center mb-4">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.max_site') }}</h2>
        <highcharts :options="optLowerGraph" class="h-60"></highcharts>
      </div>
    </div>
  </div>

  <div v-if="now !== null" class="mt-2">
    <p class="text-xs text-right italic text-gray-lighter-text">{{ $t('templates.components.components.Page.DetailSensor.ReportSensorPDF.report_date') }} {{ now.format('DD/MM/YYYY HH:mm') }}</p>
  </div>
</section>
