<div>
  <p class="mb-8">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs["installation_1"]') }} {{ measureDate.format('DD/MM/YYYY HH:mm') }} {{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs["installation_2"]') }} <a class="text-light-blue font-bold" href="https://www.cartoradio.fr">www.cartoradio.fr</a></p>
  <p class="mb-8">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.detailled_results') }}.</p>
  <div v-if="sensor.mesure_labo.protocole_a">
    <h4 class="font-bold mb-3">{{ sensor.mesure_labo.protocole_a.title }}</h4>
    <p class="mb-8">{{ sensor.mesure_labo.protocole_a.body }}</p>
  </div>
  <div class="text-center flex flex-col justify-center items-center">
    <div class="rounded-lg bg-medium-gray w-auto">
      <p class="font-semibold px-8 py-4 text-lg">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.global_exposition_level') }} {{ sensor.mesure_labo.niveau_global_exposition }} {{ sensor.unit }}</p>
    </div>
    <p class="mt-2 mb-8 text-xs">
      {{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.reminder') }}
    </p>
  </div>
  <div v-if="sensor.mesure_labo.protocole_b">
    <h4 class="font-bold mb-3">{{ sensor.mesure_labo.protocole_b.title }}</h4>
    <p class="mb-2">{{ sensor.mesure_labo.protocole_b.body }}</p>
    <p class="px-4"><span class="text-light-blue font-extrabold text-xl">- </span>{{ sensor.mesure_labo.protocole_b.dash1 }}</p>
    <p class="px-4"><span class="text-light-blue font-extrabold text-xl">- </span>{{ sensor.mesure_labo.protocole_b.dash2 }}</p>
    <p class="mt-2">{{ sensor.mesure_labo.protocole_b.footer }}</p>
  </div>
  <div class="mt-16">
    <h4 class="font-bold mb-6">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.distribution_by_service') }}</h4>
    <highcharts :options="optionsChart"></highcharts>
  </div>
  <div class="mt-16">
    <h4 class="font-bold mb-6">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.table_service') }}</h4>
  </div>
  <div v-if="sensor.mesure_labo.resuts && sensor.mesure_labo.resuts.length > 0" class="overflow-x-auto">
    <table class="w-full table-striped">
      <thead class="border-b">
        <tr>
          <th class="font-semibold text-left p-4">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.service') }}</th>
          <th class="font-semibold text-center p-4">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.frequency_band') }} <span class="font-normal">(Mhz)</span></th>
          <th class="font-semibold text-right p-4">{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.measure') }}</th>
          <th class="font-semibold text-right p-4">
            <span>{{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.limit_value') }}</span>
            <br>
            <span class="font-normal">({{ $t('templates.components.components.Page.DetailSensor.MesuresLaboTabs.reference_level') }})</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in sensor.mesure_labo.resuts" :key="result.id">
          <td class="text-left p-4">
            <span>{{ result.service }}</span>
            <span v-if="result.subname && result.subname.length > 0" class="text-xs"><br>{{ result.subname }}</span>
          </td>
          <td class="text-center p-4">
            <span>{{ result.bandwidth }}</span>
            <span class="text-xs" v-if="result.bandwidth_sub && result.bandwidth_sub.length > 0"><br>{{ result.bandwidth_sub }}</span>
          </td>
          <td class="text-right p-4">{{ result.mesure }} {{ result.mesure ? result.unit : '-' }}</td>
          <td class="text-right p-4">{{ result.limite }} {{ result.unit }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
