import SensorApi from '@/api/SensorApi';
import { FilterDiffusio, Site } from '@/api/SensorModels';
import { intersectionWith, isEqual, max } from 'lodash';
import {
  Action, Module, Mutation, VuexModule
} from 'vuex-class-modules';
import SessionModule from './Session/SessionModule';

@Module
export default class SensorModule extends VuexModule {
  /**
   * List of sensors.
   *
   * @private
   * @type {Site[]}
   * @memberof SensorModule
   */
  private sites: Site[] = [];

  @Mutation
  private setSensors(sensors: Site[]) {
    this.sites = sensors;
  }

  /**
   * Get all sensors for the current user.
   *
   * @param {SessionModule} session
   * @returns {(Promise<Site[]|null>)}
   * @memberof SensorModule
   */
  @Action
  public async getSensors(session: SessionModule): Promise<Site[]|null> {
    const token = await session.getToken();

    if (token === null) {
      return null;
    }

    const sensors = await SensorApi.getInstance().getSensors(token);

    if (sensors !== null) {
      this.setSensors(sensors);
    }

    return sensors;
  }

  /**
   * Clear the list of sensors.
   *
   * @memberof SensorModule
   */
  @Action
  public async clearSensors() {
    this.setSensors([]);
  }

  /**
   * Get all sensors.
   *
   * @readonly
   * @memberof SensorModule
   */
  public get sensors() {
    return this.sites;
  }

  /**
   * Get a sensor by its ID.
   *
   * @readonly
   * @memberof SensorModule
   */
  public get sensorById(): (id: string) => Site|null {
    return (id: string) => {
      const filtered = this.sites.filter((value) => value.id === id);

      if (filtered.length <= 0) {
        return null;
      }

      return filtered[0];
    };
  }

  /**
   * Get filtered sensor for list view.
   *
   * @readonly
   * @memberof SensorModule
   */
  public get filteredSensor() {
    return (filters: FilterDiffusio) => this.sites.filter((site: Site) => {
      const intensity = site.current_value === null || (site.current_value >= filters.intensity.min && site.current_value <= filters.intensity.max);
      const sw = site.geolocation.latitude >= filters.geo.sw.latitude && site.geolocation.longitude >= filters.geo.sw.longitude;
      const ne = site.geolocation.latitude <= filters.geo.ne.latitude && site.geolocation.longitude <= filters.geo.ne.longitude;

      let tagsDiff = true;

      if (filters.tags.length > 0) {
        tagsDiff = intersectionWith(site.tags.map((tag) => tag.slug), filters.tags, isEqual).length > 0;
      }

      return intensity && sw && ne && tagsDiff;
    });
  }

  /**
   * Get sensors by tags.
   */
  public get filteredSensorsByTags() {
    return (tags: string[]) => this.sites.filter((site) => {
      if (site.tags.length <= 0) {
        return false;
      }

      return intersectionWith(site.tags.map((tag) => tag.slug), tags, isEqual).length > 0;
    });
  }

  /**
   * Max of current values intensity.
   *
   * @readonly
   * @memberof SensorModule
   */
  public get maxIntensitySensors(): number {
    return max(
      this.sites.map((site) => site.current_value),
    ) || 6;
  }
}
