import {
  antennasModule, sensorDataModule, sensorModule, sessionModule,
} from '@/store';
import Template from '@Templates/views/User/LogoutView.vhtml';
import { Component, Vue } from 'vue-property-decorator';

@Template
@Component
export default class LogoutView extends Vue {
  /**
   * Component mounted.
   *
   * @memberof LogoutView
   */
  private async mounted() {
    try {
      await Promise.all([
        sensorModule.clearSensors(),
        sensorDataModule.clearSensorData(),
        antennasModule.clearAntennas(),
      ]);

      await sessionModule.logout();

      // Redirect to login
      window.location.href = this.$router.resolve({ name: 'login' }).href;
    } catch (e) {
      console.error(e);
    }
  }
}
