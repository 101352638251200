<template>
  <!-- list-container -->
  <div class="dsio-detail-wrapper" :class="{'isOpen': oiId, loading, loadingEnd }">
    <div class="closeOverlay" @click="close" />
    <div class="dsio-detail-content">
      <div class="dsio-detail-header">
        <div class="dsio-detail-header-content">
          <div class="progress-bar" />
          <div class="share">
            <ul v-if="conf.shareButtons !== false" class="lgrid-middle-noBottom-noGutter">
              <li class="hidden-small">
                <p>{{ t.partager || 'Partager' }} :</p>
              </li>
              <li v-if="urlMail">
                <a target="_blank" :href="urlMail">
                  <i class="dsio-envelope-o" />
                  <span class="hidden-phone">E-mail</span>
                </a>
              </li>
              <li v-if="urlFacebook">
                <a target="_blank" :href="urlFacebook">
                  <i class="dsio-facebook" />
                  <span class="hidden-phone">Facebook</span>
                </a>
              </li>
              <li v-if="urlTwitter">
                <a target="_blank" :href="urlTwitter">
                  <i class="dsio-twitter" />
                  <span class="hidden-phone">Twitter</span>
                </a>
              </li>
              <li v-if="(urlGoogle || urlPinterest) && !moreShare">
                <button class="no-button" @click="moreShare = !moreShare">
                  <span class="hidden-phone">{{ t.share.plus }}</span>
                  <i class="dsio-angle-right-light" />
                </button>
              </li>
              <li v-if="urlGoogle && moreShare">
                <a target="_blank" :href="urlGoogle">
                  <i class="dsio-google-plus-g" />
                  <span class="hidden-phone">Google +</span>
                </a>
              </li>
              <li v-if="urlPinterest && moreShare">
                <a target="_blank" :href="urlPinterest">
                  <i class="dsio-pinterest-p" />
                  <span class="hidden-phone">Pinterest</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="lgrid-noGutter-middle-noWrap">
            <ul class="pagination-mini">
              <li>
                <button
                  :disabled="!pagination.prev"
                  class="no-button"
                  @click="open(pagination.prev)"
                >
                  <i class="dsio-angle-left-light" />
                </button>
              </li>
              <li>
                <p>
                  <strong>{{ currentIndex + 1 }}</strong>
                  /{{ sortedIds.length }}
                </p>
              </li>
              <li>
                <button
                  :disabled="pagination.next ? false : true"
                  class="no-button"
                  @click="open(pagination.next)"
                >
                  <i class="dsio-angle-right-light" />
                </button>
              </li>
            </ul>
            <button class="close button" @click="close">
              <i class="dsio-close" />
              <span class="hidden-small">{{ t.close || '' }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="dsio-detail-body">
        <div v-html="htmlDetail" />
      </div>
    </div>
  </div>
</template>

<script>
  import Axios from 'axios';
  import Events from '@Diffusio/js/classes/Events';

  export default {
    name: 'DsioDetail',
    props: {
      href: {
        type: String,
        default: null,
      },
      sortedIds: {
        type: Array,
        default: () => [],
      },
      conf: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        shareButtons: {
          email:
            !this.conf.shareButtons ||
            this.conf.shareButtons.indexOf('email') !== -1,
          facebook:
            !this.conf.shareButtons ||
            this.conf.shareButtons.indexOf('facebook') !== -1,
          pinterest:
            !this.conf.shareButtons ||
            this.conf.shareButtons.indexOf('pinterest') !== -1,
          twitter:
            !this.conf.shareButtons ||
            this.conf.shareButtons.indexOf('twitter') !== -1,
          google:
            !this.conf.shareButtons ||
            this.conf.shareButtons.indexOf('google') !== -1,
        },
        moreShare: false,
        htmlDetail: null,
        loading: true,
        loadingEnd: false,
        title: '',
        share: {
          title: null,
          description: null,
          url: null,
          img: null,
        },
      };
    },
    computed: {
      prms() {
        return this.app.state.detail.prms;
      },
      oiId: {
        get() {
          return this.app.state.detail.oiId;
        },
        set(oiId) {
          this.commit('UPDATE_DETAIL', { oiId, prms: {} });
        },
      },
      urlMail() {
        return this.shareButtons.email && this.share.title && this.share.url
          ? 'mailto:?subject=' +
              this.share.title +
              '&body=' +
              (this.share.description || '') +
              '\n ' +
              this.share.url
          : null;
      },
      urlFacebook() {
        return this.shareButtons.facebook && this.share.url
          ? 'https://www.facebook.com/sharer/sharer.php?u=' + this.share.url
          : null;
      },
      urlTwitter() {
        return this.shareButtons.twitter && this.share.url && this.share.title
          ? 'http://twitter.com/intent/tweet/?url=' +
              this.share.url +
              '&text=' +
              this.share.title
          : null;
      },
      urlGoogle() {
        return this.shareButtons.google && this.share.url
          ? 'https://plus.google.com/share?url=' + this.share.url
          : null;
      },
      urlPinterest() {
        return this.shareButtons.pinterest &&
          this.share.url &&
          this.share.img &&
          this.share.title
          ? 'https://pinterest.com/pin/create/button/?url=' +
              this.share.url +
              '&media=' +
              this.share.img +
              '&description=' +
              this.share.title
          : null;
      },
      currentIndex() {
        /*
            for (let id in this.sortedIds) {
              if (this.sortedIds[id].oiId === this.oiId) {
                return parseInt(id);
              }
            } */
        // Chercher l'index de oiId dans sortedIds
        return this.sortedIds.findIndex(({ oiId }) => oiId === this.oiId);
      },
      pagination() {
        const pos = this.currentIndex;
        const prev = this.sortedIds[pos - 1]
          ? this.sortedIds[pos - 1].oiId
          : null;
        const next = this.sortedIds[pos + 1]
          ? this.sortedIds[pos + 1].oiId
          : null;
        return {
          prev,
          next,
        };
      },
    },
    watch: {
      oiId(oiId, previousId) {
        this.setWindowOverflow(!!oiId);

        if (!oiId) {
          return;
        }

        this.setUrl(this.sortedIds[this.currentIndex], previousId);

        this.moreShare =
          this.conf.shareButtons && this.conf.shareButtons.length < 4;
        this.htmlDetail = '';
        this.loading = true;
        this.loadingEnd = false;
        Axios.get(this.requestUrl + '/detail.ajx.php', {
          params: { oiId, lang: this.app.params.lang },
        })
          .then(({ data }) => {
            this.title = data.title;
            this.htmlDetail = data.html;
            this.share = Object.assign({}, this.share, data.share);
            const prms = this.prms ? JSON.parse(JSON.stringify(this.prms)) : {}; // params additionels ex hash du lien cliqué
            let trackingObject = data.trackingObject;
            // Envoi l'événement global + tracking
            this.$nextTick(() => {
              Events.detailOpen(
                { rootUrl: this.app.params.rootUrl, oiId, prms, ...this.share },
                { ...trackingObject, analyticsId: this.app.params.analyticsId }
              );

              // PANIER
              this.refreshBasket();
            });

            setTimeout(() => {
              this.loading = false;
              this.loadingEnd = true;
            }, 500);

            return undefined;
          })
          .catch(console.error.bind(console));
      },
    },
    methods: {
      setUrl({ url, oiId, title }, previousId) {
        // TODO Eviter l'appel inutile
        // Si l'url change vraiment
        if (url !== location.pathname) {
          if (!previousId) {
            this.$Request.pushState(this.historyState, title, url);
            // history.pushState({ detail: oiId, instanceId: this.instanceId }, title, url);
          } else {
            this.$Request.replaceState(this.historyState, title, url);
            // history.replaceState({ detail: oiId, instanceId: this.instanceId }, title, url);
          }
        }
      },
      open(oiId) {
        this.oiId = oiId;
      },
      close() {
        history.back();
        Events.detailClose({ oiId: this.oiId });
        this.oiId = null;
      },
    },
  };
</script>
